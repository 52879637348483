import { createRequestTypes } from "../../../helpers/constants";
import { SIGN_IN } from "../../signIn/actions";

// main process for create / confirm site from inside wp admin:
export const CS_CONFIRM_AND_CREATE_SITE_PROCESS = createRequestTypes("CS_CONFIRM_AND_CREATE_SITE_PROCESS");
export const CHECK_DOMAIN_CREATE = createRequestTypes("CHECK_DOMAIN_CREATE");

export const csCreateAndConfirmSiteProcess = {
    request: request => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.REQUEST, request }),
    success: () => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.SUCCESS }),
    error:   error => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.FAILURE, error }),
};

export const setInstallationCheckState = {
    request: request => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.REQUEST, request }),
    success: () => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.SUCCESS }),
    error:   error => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.FAILURE, error }),
};

export const setDomainOwnerCode = {
    request: request => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.REQUEST, request }),
    success: () => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.SUCCESS }),
    error:   error => ({ type: CS_CONFIRM_AND_CREATE_SITE_PROCESS.FAILURE, error }),
};

export const checkOnDomainCreate = {
    request: request => ({ type: CHECK_DOMAIN_CREATE.REQUEST, request }),
    success: () => ({ type: CHECK_DOMAIN_CREATE.SUCCESS }),
    error:   error => ({ type: CHECK_DOMAIN_CREATE.FAILURE, error }),
};

