// @flow
import React from "react";
import asyncComponent from "helpers/asyncComponent";
const WpAuthorize = asyncComponent(() => import("./wpAuthorize"));
const ChooseDomains = asyncComponent(() => import("./chooseDomains"));
import ProtectedRoute from "router/protectedRoute";
import { Layout } from "common/components";

export default [
    <ProtectedRoute exact path="/add-domains" layout={Layout} component={WpAuthorize} key="WpAuthorize" />,
    <ProtectedRoute exact path="/add-domains/choose-domains" layout={Layout} component={ChooseDomains} key="ChooseDomains" />,
];
