// @flow
import React from "react";
import { useSelector } from 'react-redux'
import Form from "./Form/Form";
import { reduxForm } from 'redux-form'
import "../../../assets/css/Innerglobal.css";
import SocialButtons from './social-button';
import {w3logger} from 'logger'
import { isInsideWpAdmin } from '../../../helpers/installationFlowHelper'
import { useEffect } from 'react';
import * as startupActions from "actions/startup"
import { useDispatch } from 'react-redux';

function ReactSignupComponent(props) {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    w3logger('signupnew', state)
    w3logger('signup props', props)
    const onClickSocialBtn = () => {
        let URL = process.env.REACT_APP_OAUTH_REDIRECT_URL;
		window.localStorage.setItem('signInFromGoogle',true)
        if (props.fromWp && isInsideWpAdmin()) {
            window.location.href = `${URL}#/wp-socialSignIn?doLogin=google&redirect_url=${window.location.href.replace(
                "#/sign-up",
                ""
            )}&fromWp=true&redirectURL=${props.redirectURL}`;
        }
    };
    useEffect(()=>{
        dispatch(startupActions.loadUserAndDomainsData.request(null))
    },[])
    return (
        <div className={"outer-container selected-plan-form"}>
            < React.Fragment >
                <SocialButtons targetPlan={""} redirectURL={props.redirectURL} fromWp={props.fromWp} onClickBtn={() => onClickSocialBtn()} />

                {/* <SocialButtons targetPlan={""} sites={props.user} onClickBtn={()=>onClickSocialBtn()} /> */}
                <Form {...props} />
            </React.Fragment>
        </div>
    );

}
export default reduxForm({
    // a unique name for the form
    form: 'signup',
    enableReinitialize: true,
    destroyOnUnmount: false
})(ReactSignupComponent)

