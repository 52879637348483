//@flow

import React from "react";
import { Heading, ButtonPrimary } from "../../../common/components";
import { LockOpenedIcon, EyeCrossedIcon } from "../../../assets/icons";

import "../../../assets/scss/global.scss";
import styles from "./seeMore.module.scss";
import { withTranslation } from "react-i18next";

import type { SeeMoreTypes } from "./SeeMoreTypes";

const SeeMore = (props: SeeMoreTypes) => {
    const { handleGetPremium,hasActivatedSite, isAuthenticated, t } = props;
    return (
        <div className={`${styles["see-more-wrapper"]}  d-flex flex-column align-items-center justify-content-center `}>
            <Heading
                align="center"
                icon={<EyeCrossedIcon />}
                title={t("Want to see more?")}
                subtitle={t("This content is only available for Premium users.")} />
            <ButtonPrimary
                id="get-premium-btn"
                withIcon={hasActivatedSite==false ? '' : <LockOpenedIcon />}
                buttonSize="medium"
                buttonText={t("Get Premium")}
                action={() => handleGetPremium(isAuthenticated)}
                // hasActivatedSite={hasActivatedSite}
            />
        </div>
    );
};

export default withTranslation()(SeeMore);
