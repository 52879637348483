import * as actions from "../actions/topNotifications";

const initialState = {
    notifications: []
};

/***
 * Reducer for top UI messages (i.e. Braintree UI notifications)
 * @param state
 * @param action
 * @returns {({} & {notifications: []} & {notifications: *})|{notifications: []}}
 */
const topNotifications = ( state = initialState, action ) => {
    let newArr = [];
    let newRecord = null;
    let recordId = "";
    let foundIndex = -1;

    switch (action.type) {
        case actions.ADD_TOP_NOTIFICATION:
            newArr = [];
            newRecord = action.data;

            if ( state.notifications && state.notifications.length > 0 ) {
                newArr = state.notifications;
                foundIndex = -1;
                for (let i = 0; i < newArr.length; i++) {
                    if ( newArr[ i ].id === newRecord.id ) {
                        foundIndex = i;
                    }
                }
                if ( foundIndex >= 0 ) {
                    //existing..
                    newArr[ foundIndex ] = newRecord;
                } else {
                    // append..
                    newArr.push( newRecord );
                }
            } else {
                newArr = [ newRecord ];
            }
            return Object.assign( {}, state, {
                notifications: newArr
            } );

        case actions.REMOVE_TOP_NOTIFICATION:
            ({recordId} = action);
            if ( state.notifications && state.notifications.length > 0 ) {
                newArr = state.notifications;
                foundIndex = -1;
                for (let i = 0; i < newArr.length; i++) {
                    if ( newArr[ i ].id === recordId ) {
                        foundIndex = i;
                        break;
                    }
                }
                if ( foundIndex >= 0 ) {
                    newArr.splice( foundIndex, 1 );

                    let newState =  Object.assign( {}, state, {
                        notifications: newArr
                    } );

                    return newState;

                } else {
                    return state;
                }
            } else {
                return state;
            }

        default:
            return state;
    }
};

export default {
    topNotifications
};
