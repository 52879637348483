import { createRequestTypes } from "helpers/constants";
// CENTRAL SITE:

// MAIN ACTION:
export const INSTALLATION_CENTRAL_INSTALL_DOMAIN = createRequestTypes("INSTALLATION_CENTRAL_INSTALL_DOMAIN");
export const centralSiteInstallDomain = {
    request: request => ({ type: INSTALLATION_CENTRAL_INSTALL_DOMAIN.REQUEST, request }),
    success: response => ({ type: INSTALLATION_CENTRAL_INSTALL_DOMAIN.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_CENTRAL_INSTALL_DOMAIN.FAILURE, error })
};

// RESET ACTION:
export const INSTALLATION_CENTRAL_RESET_INSTALLATION = "INSTALLATION_CENTRAL_RESET_INSTALLATION";
export const resetInstallationProcess = () => {return {type: INSTALLATION_CENTRAL_RESET_INSTALLATION, data: {} }};

// STEPS:

// SET TARGET DOMAIN:
export const INSTALLATION_CENTRAL_ENTERED_DOMAIN_URL = "INSTALLATION_CENTRAL_ENTERED_DOMAIN_URL";
export const enteredDomainUrl = targetDomainData => {return {type: INSTALLATION_CENTRAL_ENTERED_DOMAIN_URL, data: targetDomainData }};


// SET CHOSEN PRICING PLAN:
export const INSTALLATION_CENTRAL_PLAN_CHOSEN = "INSTALLATION_CENTRAL_PLAN_CHOSEN";
export const planChosen = planData => {return {type: INSTALLATION_CENTRAL_PLAN_CHOSEN, data: planData }};


// SET PAYMENT INFO:
export const INSTALLATION_CENTRAL_PAYMENT_COMPLETED = createRequestTypes("INSTALLATION_CENTRAL_PAYMENT_COMPLETED");

export const paymentCompleted = {
    request: request => ({ type: INSTALLATION_CENTRAL_PAYMENT_COMPLETED.REQUEST, request }),
    success: response => ({ type: INSTALLATION_CENTRAL_PAYMENT_COMPLETED.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_CENTRAL_PAYMENT_COMPLETED.FAILURE, error })
};


// CREATE SITE ENTRY IN CLOUD:
export const INSTALLATION_CENTRAL_SITE_CREATED = createRequestTypes("INSTALLATION_CENTRAL_SITE_CREATED");

export const siteCreated = {
    request: request => ({ type: INSTALLATION_CENTRAL_SITE_CREATED.REQUEST, request }),
    success: response => ({ type: INSTALLATION_CENTRAL_SITE_CREATED.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_CENTRAL_SITE_CREATED.FAILURE, error })
};



