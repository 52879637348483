import * as actions from "../actions";
import { w3logger } from 'logger';

const initialState = {
    loading: false,
    message: null,
    data: {},
    updateRobotsMessage: null,
    updateRobotsLoader: false,
    flushPermalinksMessage: null,
    flushPermalinksLoader: false,
    categories: [],
    taxonomies: [],
    postTypes: [],
    prettyPermalinkUsed: false,
    s3SmUrl: '',
    siteCreated:false,
    autoUpdateBannerInteraction:0,
    yoastSMEnabled: 0,
    AIOSeoSMEnabled: 0,
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Settings Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const settings = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_SETTINGS.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error.message,
            });

        case actions.GET_SETTINGS.SUCCESS: {
            const categoriesChecked = {};
            const postTypesChecked ={};
            const taxonomiesChecked = {};
            w3logger("OK!!!!! action.response: ", action.response);

            const { data, categories, taxonomies, postTypes, sitemapCounters, prettyPermalinkUsed, s3SmUrl, siteCreated, banner_interaction, yoastSMEnabled, AIOSeoSMEnabled } = action.response;
            const { sm_b_exclude_cats, sm_in_customtypes, sm_in_tax } = data;

            w3logger("OK!!!!! sm_b_exclude: ", sm_b_exclude_cats);
            w3logger("OK!!!!! categories: ", categories);

            sm_b_exclude_cats && sm_b_exclude_cats.length > 0 && sm_b_exclude_cats.forEach(item => {
                categories.forEach(elem => {
                    if ( elem.term_id === item ) {
                        categoriesChecked[`ex_cat_${item}`] = true;
                    }
                });
            });

            w3logger("OK!!!!! sm_in_customtypes: ", sm_in_customtypes);
            w3logger("OK!!!!! postTypes: ", postTypes);

            sm_in_customtypes && sm_in_customtypes.length > 0 && sm_in_customtypes.forEach(item => {
                postTypes.forEach(elem => {
                    if ( elem.name === item ) {
                        postTypesChecked[`posttype_${item}`] = true;
                    }
                });
            });

            sm_in_tax && sm_in_tax.length > 0 && sm_in_tax.forEach(item => {
                taxonomies.forEach(elem => {
                    if ( elem.name === item ) {
                        taxonomiesChecked[`taxonomies_${item}`] = true;
                    }
                });
            });

            w3logger("OK!!!!! data.sm_b_exclude: ", data);

            data.sm_b_exclude = ( data.sm_b_exclude && data.sm_b_exclude.length > 0) ? data.sm_b_exclude.toString() : '';

            const newData = {
                ...data,
                ...categoriesChecked,
                ...taxonomiesChecked,
                ...postTypesChecked
            };

            return Object.assign({}, state, {
                loading: false,
                message: "Settings successfully loaded!",
                data: newData,
                categories: categories,
                taxonomies: taxonomies,
                postTypes: postTypes,
                sitemapCounters: sitemapCounters,
                prettyPermalinkUsed: prettyPermalinkUsed,
                s3SmUrl: s3SmUrl,
                siteCreated:siteCreated,
                autoUpdateBannerInteraction:banner_interaction,
                yoastSMEnabled:yoastSMEnabled,
                AIOSeoSMEnabled: AIOSeoSMEnabled,
            });

        }

        case actions.GET_SETTINGS.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
                data: {},
            });

        case actions.GET_SETTINGS.INIT_DEFAULT_VALUE:
            return Object.assign({}, state, {
                loading: false,
                message: "",
                data: action.data,
            });

        case actions.UPDATE_ROBOTS.REQUEST:
            return Object.assign({}, state, {
                updateRobotsMessage: "",
                updateRobotsLoader: true,
            });

        case actions.UPDATE_ROBOTS.SUCCESS:
            return Object.assign({}, state, {
                updateRobotsMessage: action.response.message,
                updateRobotsLoader: false,
            });

        case actions.UPDATE_ROBOTS.FAILURE:
            return Object.assign({}, state, {
                updateRobotsMessage: action.error.message,
                updateRobotsLoader: false,
            });

        case actions.FLUSH_PERMALINKS.REQUEST:
            return Object.assign({}, state, {
                flushPermalinksMessage: "",
                flushPermalinksLoader: true,
            });

        case actions.FLUSH_PERMALINKS.SUCCESS:
            return Object.assign({}, state, {
                flushPermalinksMessage: action.response.message,
                flushPermalinksLoader: false,
            });

        case actions.FLUSH_PERMALINKS.FAILURE:
            return Object.assign({}, state, {
                flushPermalinksMessage: action.error.message,
                flushPermalinksLoader: false,
            });

        default:
            return state;
    }
};

export default settings;
