// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./helpers/i18n.js";
import * as actions from "actions/appSettings";
import Router from "router";
import * as signInActions from '../src/actions/user'
import * as startupActions from "actions/startup";
import * as createSiteAction from "components/signIn/actions"
import * as userActions from "actions/user"
import * as DashboardActions from "components/dashboard/actions";
import * as settingsAction from 'components/settings/actions';
import * as paymentActions from "components/paymentBraintree/actions"
import { BING_TOKENS } from "constants/index";
import { LoaderElement } from "common/components";
import { GoogleApiHelper, BingApiHelper, Storage } from "helpers/index";
import { API, Hub, Auth } from "aws-amplify/lib/index";
import { put, call } from "@redux-saga/core/effects";
import { isInsideWpAdmin, getParameterByName, submitPostRequestToCsAfterFederatedSignin, isFederatedSigninForAppInProgress, isFederatedSigninForCsInProgress, isRedirectUponSignInReserved, getFederatedSignInForCsData, getFederatedSignInForAppData, removeReservedRedirect } from "./helpers/installationFlowHelper";
import { w3logger } from "./logger";
import { wpApiCall } from "api/apiClient";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from "react-loading-skeleton";
import dotenv from 'dotenv';
dotenv.config();

type AppPropsType = {
    setPlatform: (platform: boolean) => void,
    onRefershToken: ({ type: string, token: Object }) => void
}
const {
    REACT_APP_PREMIUM_PLAN_ID
} = process.env;
class App extends PureComponent<AppPropsType> {

    state = {
        federatedSignInDataLoading: true,
        harRedirectUrl: false
    };
    componentWillReceiveProps(nextProp) {
        if (nextProp.activeSite && nextProp.activeSite.type != this.props?.activeSite?.type && nextProp.user.userData !== null && nextProp.user.userData?.attributes !== undefined) {

            let userEmail = nextProp.user.userData.attributes.email !== undefined ? nextProp.user.userData.attributes.email : '';
            let userName = nextProp.user.userData.attributes.name !== undefined ? nextProp.user.userData.attributes.name : '';
            // this.props.loadSubscriptionData({activeSite:nextProp.activeSite,userEmail,userName,plugin:isInsideWpAdmin()});

        }
        w3logger("domain>>>>Prop", nextProp)
    }

    async componentWillMount() {
        this.props.fetchPlans();
        if (isInsideWpAdmin()) {
            const { getCognitoToken } = this.props;
            let data = { domain: `${window.location.host}` }
            await getCognitoToken(data);
            w3logger("activeSiteDomainWill", this.props)

            setTimeout(() => {
                this.setState({ federatedSignInDataLoading: false })
            }, 2000);
            document.getElementById('wpcontent').style.paddingLeft = 0
        }
        
        var plugin_version = document.createElement("input")
        plugin_version.name = "plugin_version"
        plugin_version.id = "plugin_version"
        plugin_version.type = "hidden"

        var wordpress_version = document.createElement("input")
        wordpress_version.name = "wp_version"
        wordpress_version.id = "wp_version"
        wordpress_version.type = "hidden"

        var email = document.createElement("input")
        email.name = "user_email"
        email.id = "user_email"
        email.type = "hidden"

        document.body.appendChild(plugin_version)
        document.body.appendChild(wordpress_version)
        document.body.appendChild(email)

    }

    async componentDidMount() {
        this.setState({federatedSignInDataLoading:false});
        const redirectURL = getParameterByName('redirectURL')!==null ? getParameterByName('redirectURL') : localStorage.getItem('redirectURL')
        const signInFromWP = getParameterByName('signInFromWP')!==null ? getParameterByName('signInFromWP') : localStorage.getItem('signInFromWP')

        const handler = getParameterByName('handler');
        const code = getParameterByName('code');
        const state = getParameterByName('state');
        const isBing = getParameterByName('isBing');
        const errorCode = getParameterByName('errorCode')
        if (errorCode === '403' || errorCode === 403) {
            window.localStorage.setItem('siteAlreadyExists',true)
        }
        if((signInFromWP!==true || signInFromWP===undefined) && !!handler && !!code && handler == "bing"){
            window.location.href = `${state}/#/bing?code=${code}&state=${state}&isBing=true`;
            alert('bing')
        }

        if((signInFromWP!==true || signInFromWP===undefined || signInFromWP===null) && code && !handler && !isBing){
            let urlState = getParameterByName('state');
            if(urlState && urlState!= null){
                window.location.href = `${urlState}/?code=${code}&handler=google&state=${urlState}`;
            }
        }
        w3logger('redirecturlandsigninfromwp',redirectURL,signInFromWP)
        GoogleApiHelper.oAuthClient.on('tokens',tokens => {
            if(tokens) {
                this.setGoogleToken(tokens)
                GoogleApiHelper.storeToken(tokens);
            }
        });
        const initTokens = getParameterByName('tokens');
        if(initTokens){
            await GoogleApiHelper.storeToken(JSON.parse(initTokens));
            await this.setGoogleToken(JSON.parse(initTokens));
            let urlState = getParameterByName('state');
            if(urlState && urlState!= null){
                window.location.href = `${urlState}`;
            }
        }
        const { dataset: { platform } } = document.getElementById("root") || {};
        if (platform) {
            this.props.setPlatform(true);
        }


        const { startLoadUserData } = this.props;
        startLoadUserData();
    
        Hub.listen("auth", async ({ payload: { event, data } }) => {
            w3logger(event,data,'hubs')
            w3logger('inside if')
            
            switch (event) {
                case 'signIn':
                  this.setState({federatedSignInDataLoading:true});
                  setTimeout(() => {
                    this.setState({federatedSignInDataLoading:false})
                }, 4000);
                    if(signInFromWP){
                        (async()=>{
                            

                            const targetUrl = redirectURL + "&after_action=login"  ;
                            const curSession = (await Auth.currentSession());
                            const authUser = (await Auth.currentAuthenticatedUser());
                            const refreshToken = curSession.getRefreshToken().token;
                            const { attributes = null } = authUser || {};
                            const { name = "default" } = attributes || {};
                            const domain = redirectURL.split('//')[1].split('/')[0]  
                            const accessToken = curSession.getAccessToken().jwtToken;
                            const idToken = curSession.getIdToken().jwtToken;
                            let formdata = { "idToken": idToken, "accessToken": accessToken, "refreshToken": refreshToken, "domain": domain  }
                            await this.props.setCognitoToken(formdata)
                            setTimeout(async() => {
                                const siteExists = window.localStorage.getItem('siteExists')
                                if(siteExists == 'true') {
                                    submitPostRequestToCsAfterFederatedSignin(targetUrl, name, refreshToken, 'site exists.'); 
                                } else {
                                    submitPostRequestToCsAfterFederatedSignin(targetUrl, name, refreshToken, formdata);
                                }
                                await localStorage.removeItem('signInFromWP')
                                return true 
                            }, 3000);
                        })()
                    }
                    startLoadUserData()
                    removeReservedRedirect();
                    if(isInsideWpAdmin()==false){
                        setTimeout(() => {
                            this.setState({harRedirectUrl:false})
                        }, 4000);
                    }
                    return true;


                case "signIn_failure":
                    this.setState({ federatedSignInDataLoading: false });
                    return true;

            }

        })
    }

    async setGoogleToken(token: Object) {
        w3logger('setGoogleToken>>>')
        try {
            const config = {
                body: { ...token },
                response: true,
            };
            if(window.localStorage.getItem('getToken') !==true){
                const response = await API.put("auctollo", "/site/tokens", config);
                await GoogleApiHelper.storeToken(token);
                window.localStorage.setItem('getToken',true)
                w3logger('setGoogleToken>>>', response)
            }
            // window.location.reload()
        } catch (e) {
            w3logger('e', e)
        }
    }

    render() {
        if (this.props.plans.plansData) {
            let prem = this.props.plans.plansData.plans.find(plan => plan.id == REACT_APP_PREMIUM_PLAN_ID);
            if(prem){
                localStorage.setItem('planPrice', prem.price);
            }
        }
        w3logger("redirectURL>>>>", this.state.harRedirectUrl, this.state.federatedSignInDataLoading)
        if (this.state.federatedSignInDataLoading === true || this.state.harRedirectUrl == true) {
            return <div style={{
                margin: " 0 auto",
                maxWidth: "1050px", paddingTop: "30px", textAlign: 'center'
            }}></div>;
        } else {
            return <Router />;
        }
    }
}

const mapStateToProps = state => {
    return {
        activeSite: state.user.activeSite,
        user: state.user,
        plans: state.plans,
        sigin: state.signIn,
        cognitoToken:state.dashboard.cognitoToken
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPlatform: platform => {
            dispatch(actions.setPlatform(platform));
        },
        onRefershToken: data => {
            dispatch(DashboardActions.setRefreshToken.request(data));
        },
        setCognitoToken: data => {
            dispatch(DashboardActions.setCognitoToken.request(data));
        },
        getCognitoToken: data => {
            dispatch(DashboardActions.getCognitoToken.request(data));
        },
        startLoadUserData: () => {
            dispatch(startupActions.loadUserAndDomainsData.request(null));
        },
        loadSubscriptionData: data => {
            dispatch(settingsAction.displaySubscriptionInfo.request(data));
        },
        fetchPlans: () => {
            dispatch(paymentActions.fetchPlans.request());
        },
        changeActiveDomain: data =>{
            dispatch(userActions.setActiveDomain(data))
        },
        createSite: data =>{
            w3logger('create site',data)
            dispatch(createSiteAction.createSite.request(data))
        },
        setUserData: data => {
            dispatch(userActions.setUserData.success(data))
        },
        setIsAuthenticated: data => {
            dispatch(userActions.setIsauthenticated(data))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
