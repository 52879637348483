import React from "react";
import { connect } from "react-redux";
import {ButtonSignUp} from "../../../../common/components";
import "../../../../assets/scss/global.scss";
import styles from "../../SignUp.module.scss";
import { GoogleIcon, GitHubIcon, MicrosoftIcon } from "../../../../assets/icons";
import Auth from '@aws-amplify/auth'
import { isInsideWpAdmin, setInstallationOrigin } from "../../../../helpers/installationFlowHelper";
import * as actions from "../../redux/actions";
import * as signInActions from '../../../signIn/actions'
import {useDispatch} from 'react-redux'
import {googleURL} from "helpers/constants";
import { useTranslation } from "react-i18next";
import {w3logger} from 'logger'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const SocialButtons = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { buttonsConfig, activeSite, targetPlan, sites, domainExist } = props;
    const {REACT_APP_OAUTH_REDIRECT_URL} = process.env
    w3logger("The active site::");
    w3logger(activeSite);

    const checkUserDomain = () =>{
        // if(isInsideWpAdmin()){
        //     startFederatedSignInFromWp();
        // }else{
        //     // props.checkDomainExist({domain:"staging-site-111.auctollo.com"})
        //     startFederatedSignInOutsideOfWp();
        // }
        if(isInsideWpAdmin()){
            if(( /sign-up/.test( window.location.href ))) {
                props.checkDomainExist({domain: activeSite.domain,actionSuccess:()=>startFederatedSignInFromWp(), actionFailure:()=>domainExistFailure()})
            }else{
                startFederatedSignInFromWp();
            }
        }else{
            // alert("asdf")
            startFederatedSignInOutsideOfWp();
        }
    }

    const domainExistFailure = () =>{
        alert(t("Domain is already associated, Please Login!"));
        props.history.push("/sign-in");
    }

    const startFederatedSignInOutsideOfWp = () =>{
        //Auth.federatedSignIn({provider: "google"});
        let {redirectURL, fromWp} = props
        w3logger('localstorage',redirectURL,fromWp)
        window.location.href = googleURL
        // if( redirectURL){
        //     w3logger('local')
        //     let url = googleURL.replace(REACT_APP_OAUTH_REDIRECT_URL,REACT_APP_OAUTH_REDIRECT_URL+'&redirectURL='+redirectURL+'&signInFromWP='+fromWp)
        //     window.location.href = url
        //     // alert('success');
        //     w3logger(url)
        //     // dispatch(signInActions.socialSignIn.success({redirectURL,fromWp}))
        // } else {
        //     window.location.href = googleURL
        //     w3logger('not local')
        // }
    };

    const startFederatedSignInFromWp = () => {
        alert('inside')
        if(!activeSite){
            alert(t("No active site! Exiting"));
            return false;
        }

        let action = "login";
        if(targetPlan){
            if(["community", "premium", "enterprise"].indexOf(targetPlan) <0){
                alert(t("Invalid product plan provided."));
                return false;
            } else {
                action = "plan-" + targetPlan;
            }
        }

        action = encodeURIComponent(action);

        // alert("action = " + action);

        const {domain ="", protocol=""} = activeSite || {};

        if(!domain || !protocol){
            alert(t("Some site info is currently not available. Cannot continue with federated sign in"));
            return false;
        }

        //const encodedDomain = encodeURIComponent( "localhost:8071" );
        const encodedDomain = encodeURIComponent( domain );

        // todo, add nonce here:

        const redirectUrl = encodeURIComponent(protocol + "://" + domain +"/wp-admin/admin.php?page=google-sitemap-generator%2Fsitemap.php&gxs_action=federated-login");

        window.location.href = process.env.REACT_APP_CENTRAL_SITE_URL + "/#/installation/app/federated-login-for-cs/" + encodedDomain + "/" + redirectUrl + "/" + action;
    };

    const onClickBtn = () => {
        if(props.fromWp){
            props.onClickBtn()
        }else{
            startFederatedSignInOutsideOfWp();
        }
    }

    const isHostedInsideWp = isInsideWpAdmin();

    return (
        <div className={styles[ "social-btn-group" ]}>
            
            {/* {
            props.loading==true ? <div> <LoaderElement /> </div> : <div></div>
            } */}
{/* 
            {
                !isHostedInsideWp && <ButtonSignUp
                    url={"#"}
                    text={( /signUp/.test( window.location.href )) ? t("Sign up with Google") : t("Sign In with Google")}
                    icon=<GoogleIcon/>
                color={"blue"}
                type={"google"}
                onClickBtn={() => !( /signUp/.test( window.location.href )) ? startFederatedSignInOutsideOfWp() : checkUserDomain()} />
            } */}
        {props.loading === true ?
        <Skeleton height={50}/>
        :
         <ButtonSignUp
         url={"#"}
         text={( /sign-up/.test( window.location.href )) ? t("Sign up with Google") : t("Sign In with Google")}
         icon={<GoogleIcon/>}
         color={"blue"}
         type={"google"}
         loading={props.loading}
         // onClickBtn={() => !( /sign-up/.test( window.location.href )) ? startFederatedSignInOutsideOfWp() : checkUserDomain()} />
         
         onClickBtn={() => onClickBtn()} /> 
        }
         {/* onClickBtn={() => startFederatedSignInOutsideOfWp} />  */}
         
        </div>
    );
};

export default SocialButtons
