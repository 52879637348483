import * as actions from "../components/installation/actions";
import { w3logger } from 'logger'

const initialState = {
    checkState: {
        step: "first",
        stepSucceeded: true
    },
    wpSetOwnerCode: false,
    createdOwnerCode: false
};

const installation = (state = initialState, action) => {
    switch (action.type) {

        // INSTALLATION FLOW, ORIGIN AND STATUS:
        case actions.SET_INSTALLATION_STATUS: {
            return {
                ...state,
                status: action.data
            };
        }
        case actions.SET_INSTALLATION_FLOW: {
            return {
                ...state,
                flow: action.data
            };
        }
        case actions.SET_INSTALLATION_FLOW_DATA: {
            return {
                ...state,
                flowData: action.data
            };
        }
        case actions.SET_INSTALLATION_ORIGIN: {
            return {
                ...state,
                flowOrigin: action.data
            };
        }


        // GET UPGRADE DATA FROM WP:
        case actions.INSTALLATION_GET_UPGRADE_DATA.FAILURE:
            return Object.assign( {}, state, {
                loading: false,
                message: action.error,
            } );

        case actions.INSTALLATION_GET_UPGRADE_DATA.SUCCESS:
            return Object.assign( {}, state, {
                loading: false,
                message: "",
                upgradeData: action.response
            } );
        case actions.INSTALLATION_GET_UPGRADE_DATA.REQUEST:
            return Object.assign( {}, state, {
                loading: true,
                message: "",
            } );

        // CREATE OWNER CODE (APP SIDE):
        case actions.INSTALLATION_CREATED_DOMAIN_OWNER_CODE.FAILURE:
            return Object.assign( {}, state, {
                createdOwnerCode: false,
                loading: false
            } );

        case actions.INSTALLATION_CREATED_DOMAIN_OWNER_CODE.SUCCESS:
            return Object.assign( {}, state, {
                createdOwnerCode: action.response,
                loading: false
            } );

        case actions.INSTALLATION_CREATED_DOMAIN_OWNER_CODE.REQUEST:
            return Object.assign( {}, state, {
                loading: true
            } );

        // SET OWNER CODE (WP SIDE):
        case actions.INSTALLATION_SET_DOMAIN_OWNER_CODE.FAILURE:
            return Object.assign( {}, state, {
                wpSetOwnerCode: false,
                loading: false
            } );

        case actions.INSTALLATION_SET_DOMAIN_OWNER_CODE.SUCCESS:
            return Object.assign( {}, state, {
                wpSetOwnerCode: action.ownerCode,
                loading: false
            } );

        case actions.INSTALLATION_SET_DOMAIN_OWNER_CODE.REQUEST:
            return Object.assign( {}, state, {
                loading: true
            } );

        // INSTALLATION CHECK PROCESS:
        case actions.SET_INSTALLATION_CHECK_STATE: {
            return {
                ...state,
                checkState: action.data
            };
        }

        case actions.INSTALLATION_REFRESH_TOKEN_OBTAINED.FAILURE: {
            return {
                ...state
            };
        }

        case actions.INSTALLATION_REFRESH_TOKEN_OBTAINED.REQUEST: {
            w3logger("OK!!! So I am in reducer!");
            return {
                ...state
            };
        }

        case actions.INSTALLATION_REFRESH_TOKEN_OBTAINED.SUCCESS: {
            return {
                ...state
            };
        }

        default:
            return state;
    }
};

export default {installation};
