//@flow

import React from "react";
import styles from "./checkbox.module.scss";

import type { FormCheckboxType } from "./FormCheckboxTypes.js";

const FormCheckbox = ({ input, meta: { touched, error }, ...rest } : FormCheckboxType ) => {
    let errorBlock = null;
    let borderColor = "";

    if (touched && error) {
        errorBlock = (
            <div className={styles["error-block"]}>
                <span>{ error }</span>
            </div>
        );
        borderColor = styles["input-error"];
    }

    return (
        <>
            <label className={styles.container}>
                <input {...rest} {...input} className={`${borderColor}`} />
                <span className={styles.checkmark}></span>
            </label>
            { errorBlock }
        </>
    );
};

export default FormCheckbox;
