import React from "react";

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#1A717B" viewBox="0 0 14 11" width="13" height="10">
            <path
                fill="#1A717B"
                d="M4.529 10.58L.195 6.248a.667.667 0 010-.942l.943-.943c.26-.26.683-.26.943 0L5 7.28l6.252-6.252c.26-.26.683-.26.943 0l.943.942c.26.26.26.683 0 .943l-7.667 7.667a.667.667 0 01-.942 0z"
            />
        </svg>
    );
};

export default CheckIcon;
