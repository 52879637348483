// @flow
// $FlowFixMe
import moment from "moment";
import React, { useState, useEffect } from "react";
import {
    ButtonPrimary,
    ButtonOutline,
    Heading,
} from "common/components";
import "./styling.scss";
import type { ComponentProps } from "./Types";
import { logWrite, logError } from "../../logger";
import { Auth } from "aws-amplify";
import { isInsideWpAdmin } from "../../helpers/installationFlowHelper";
import { wpApiCall } from "../../api/apiClient";

const { REACT_APP_SIMULATE_LOCAL_WP_ADMIN } = process.env;

const DebugWindowComponent = ( props: ComponentProps ) => {
    const { userData, activeSite, cloudSitemapStatus } = props;
    const debugWindowEnabled = true;
    if ( debugWindowEnabled !== true ) {
        // dont display debug window:
        return null;
    }

    const printCognitoSessionData = async () => {
        try {
            const { jwtToken = null } = ( await Auth.currentSession() ).idToken;

            logWrite( ">>>printCognitoSessionData:" );
            logWrite( await Auth.currentSession() );

            alert( "jwt token:" );
            alert( jwtToken );

        } catch (err) {
            logError( ">>>printCognitoSessionData error:" );
            logError( err );
            alert( "Error on session check: " + err );
        }
    };

    let hostingMode = "";
    if ( isInsideWpAdmin() === true ) {
        hostingMode = "Inside WP-admin";

        if ( REACT_APP_SIMULATE_LOCAL_WP_ADMIN === true ) {
            hostingMode += "(simulated)";
        }
    } else {
        hostingMode = "Standalone React (central site)";
    }

    let btnRemoveWpDbUserData = null;
    if ( isInsideWpAdmin() === true ) {
        btnRemoveWpDbUserData = <button onClick={async () => {
            // let res = await wpApiCall( {
            //     relativeUrl: "removeWpDbUserData",
            //     method: "GET"
            // } );

            // let resJson = JSON.parse( res );
            await Auth.signOut( { global: false } );

            // alert("Delete result= " + resJson.message);
            window.location.reload( true );

        }}>Delete User Data from WP DB</button>;
    }
    if ( isInsideWpAdmin() === false ) {
    return (
        <div className={"sgp--debug-window"}>

            <div className={"sgp--debug-header"}>
                DEBUG INFO
            </div>
            <div className={"sgp--debug-item"}>
                <b>Hosting:</b> {hostingMode}
            </div>
            <div className={"sgp--debug-item"}>
                <b>Active Domain:</b> {activeSite ? activeSite.domain : "None"}
            </div>
            <div className={"sgp--debug-item"}>
                <b>Subs.Type:</b> {activeSite ? activeSite.type : "None"}
            </div>
            <div className={"sgp--debug-item"}>
                <b>userData.attributes.name::</b> {userData && userData.attributes ? userData.attributes.name : "None"}
            </div>
            <div className={"sgp--debug-item"}>
                <b>cloudSitemapStatus.status</b> {cloudSitemapStatus ? cloudSitemapStatus.status : "Empty"}
            </div>
            <div className={"sgp--debug-item"}>
                <button onClick={() => {
                    printCognitoSessionData()
                }}>Get Cognito Live Session Data
                </button>
            </div>

            {btnRemoveWpDbUserData}

        </div>
    );
            }else{
                return null
            }
};

export default DebugWindowComponent;
