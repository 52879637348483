//@flow

import React from "react";
import "./../../../assets/scss/global.scss";
import styles from "./chartLegend.module.scss";

import type { ChartLegendType } from "./ChartLegendType.js";
import { withTranslation } from "react-i18next";

const ChartLegend = (props: ChartLegendType) => {
    const {t} = props;
    return (
        <div className={`${styles["chart-legend"]}  d-flex align-items-center ${styles[props.active && "active"]}`}>
            <span className={styles["legend-dot"]}></span>
            <span className={styles["legend-title"]}>{t(props.title,props.title)}</span>
        </div>
    );
};

export default withTranslation()(ChartLegend);
