import * as actions from "components/installation/actions/centralSite/actions";
import { INSTALLATION_CENTRAL_SITE_CREATED } from "../../actions/centralSite/actions";
import { w3logger } from 'logger';

const initialState = {
    step: "start",
    targetDomainData: null,
    planData: null,
    paymentData: null,
    siteCreatedData: null,
    createdOwnerCode: false,
};

const centralSiteInstallDomain = (state = initialState, action) => {
    switch (action.type) {

        // RESET THE PROCESS:
        case actions.INSTALLATION_CENTRAL_RESET_INSTALLATION: {
            return {
                ...initialState
            };
        }

        // DOMAIN URL INPUT:
        case actions.INSTALLATION_CENTRAL_ENTERED_DOMAIN_URL: {
            return {
                ...state,
                targetDomainData: action.data
            };
        }

        // PRICING PLAN CHOSEN:
        case actions.INSTALLATION_CENTRAL_PLAN_CHOSEN: {
            return {
                ...state,
                planData: action.data
            };
        }

        // SITE CREATED IN CLOUD:
        case actions.INSTALLATION_CENTRAL_SITE_CREATED.FAILURE: {
            return {
                ...state,
                siteCreatedData: null,
                domainCreatedData: action.error.domainData,
                errorMessage:action.error.message
            };
        }

        case actions.INSTALLATION_CENTRAL_SITE_CREATED.SUCCESS: {
            w3logger('installation success',action)
            return {
                ...state,
                siteCreatedData: action.data,
                domainCreatedData: action.response,
                sucessMessage : action.response.message.message,
                errorMessage:null
            };
        }

        // PRICING PAYMENT COMPLETED:
        case actions.INSTALLATION_CENTRAL_PAYMENT_COMPLETED.SUCCESS: {
            return {
                ...state,
                paymentData: action.data
            };
        }

        default:
            return state;
    }
};

export default {centralSiteInstallDomain};
