import React from "react";
import Messages from "UI/messages";
import { TopPanel } from "common/components";
import ServiceWorkerNotifier from "./../serviceWorkerNotifier";

import "../../../assets/css/Innerglobal.css";
const UnauthorizedLayout = ({ children, ...rest }, ) => {
    return (
        <>
            <TopPanel {...rest} />
            <Messages />
            <ServiceWorkerNotifier />
            {children}
        </>
    );
};

export default UnauthorizedLayout;
