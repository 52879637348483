// @flow
import { Storage } from "helpers/index";
import { BING_TOKENS } from "constants/index";
const { REACT_APP_BING_CLIENT_ID, REACT_APP_BING_CLIENT_SECRET, REACT_APP_BING_REDIRECT } = process.env;
import { bingURL } from "helpers/constants";
import { Auth, API } from "aws-amplify";
import http from "../api/index";
import { w3logger, logError  } from 'logger'

/**
 * @author SD
 * @desc Component for work with Bing API
 */
class BingApiHelper {

    /**
     * @author SD
     * @desc Function for setting token to oauth client
     */
    static setTokens = async (code: string) => {
        if(code) {

            // let urlParams = new URLSearchParams(get(options, "params", {}));
            // let newUrl = `${url}${urlParams.toString() ? "?" : ""}${urlParams.toString()}`;
            let ft = null;
            try {
                const response = await API.post("auctollo", "/webmasters/create",{
                    body: { 
                        verificationCode: code,
                        client_id: REACT_APP_BING_CLIENT_ID,
                        client_secret: REACT_APP_BING_CLIENT_SECRET,
                        redirect_uri: REACT_APP_BING_REDIRECT,
                    },
                    response: true,
                }).then(response=>{
                    ft = response.data;
                })
                .catch(err => {
                    w3logger("setBingToken>>>",err);
                    localStorage.removeItem(BING_TOKENS);
                });
                // await GoogleApiHelper.storeToken(token);
                w3logger('setBingToken>>>',response)
            } catch (error) {
                localStorage.removeItem(BING_TOKENS);
                w3logger("window:::",error);
            }

            return ft;
            // Storage.setItem(BING_TOKENS, response);
            // return code
        }
    };

    /**
     * @author SD
     * @desc Function for setting token to oauth client from dynamodb
     */

    static getToken = async () => {
        let ft = null;
        try {
            w3logger('Bing api helper get Token;',await Auth.currentAuthenticatedUser())
            const userDetails = await Auth.currentAuthenticatedUser()
            if(userDetails!==undefined && userDetails){

                const response = await API.get("auctollo", "/webmasters",{
                    response:true
                }).then(response=>{
                    ft = response.data;
                    Storage.setItem(BING_TOKENS, response.data.data.tokens);
                })
                .catch(err => {
                    w3logger("setBingToken>>>",err);
                    localStorage.removeItem(BING_TOKENS);
                });
            }
            // await GoogleApiHelper.storeToken(token);
            w3logger('setBingToken>>>',ft)
            
        } catch (error) {
            localStorage.removeItem(BING_TOKENS);
            w3logger("window:::",error);
        }

        return ft;
    }
    static isAuthorized = async () => {
        const tokens = Storage.getItem(BING_TOKENS);

        if(!tokens){
            return false;
        }
        // w3logger("res:",key,bingURL);
        // webmasters/bing
        let response = "";
        try {
            response = await API.post( 'auctollo', "/webmasters/bing", {
                body: { 
                    site: "http://wp-dev-env-1545.space/",
                    key: tokens.access_token,
                    api: ["GetCrawlStats"]
                },
                response: true,
            }).catch(err => {
                localStorage.removeItem(BING_TOKENS);
                return false;
            });
            if(response){
                return true;
            }else{
                return false;
            }
        } catch (error) {
            localStorage.removeItem(BING_TOKENS);
            return false;
        }
    };

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns URL for authorization
     * @return { String } - Auth URL
     */
    static getAuthUrl = () => this.generateAuthUrl({
        response_type: "code",
        client_id:REACT_APP_BING_CLIENT_ID,
        redirect_uri:REACT_APP_BING_REDIRECT,
        scope:"webmaster.read",
        state: window.location.href
    });

    static generateAuthUrl = ({response_type,client_id,redirect_uri,scope,state}) => {
        return `https://www.bing.com/webmasters/oauth/authorize?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`
    }
    

    /**
     * @author SD
     * @desc Function which returns webmaster instance
     */
    static onLogout = () => {
       localStorage.removeItem(BING_TOKENS);
    };

}

export default BingApiHelper;
