import * as actions from "actions/serviceWorker";

const initialState = {
    serviceWorkerUpdated: false
};

const serviceWorker = (state = initialState, action) => {
    switch (action.type) {

        case actions.UPDATE_SERVICEWORKER: {
            return {
                ...state,
                serviceWorkerUpdated: true
            };
        }

        default:
            return state;
    }
};

export default serviceWorker;
