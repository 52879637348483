import { put, fork, take, call } from 'redux-saga/effects'
import * as actions from '../actions'
import * as signinActions from '../../../signIn/actions'
import { Auth } from "aws-amplify";
import * as userActions from 'actions/user'
import { stopSubmit } from "redux-form";
import { frontendCloudApiCall,wpApiCall } from "api/apiClient";
import { isInsideWpAdmin } from '../../../../helpers/installationFlowHelper'
import {w3logger} from 'logger'

function* signUp(){
    while(true){
        const { request: { email, password, first_name, action, targetPlan, history} } = yield take(actions.SIGN_UP.REQUEST)
        yield put(userActions.setUserData.request())
        let formId = 'signUp'
        w3logger('tamarget plamn',targetPlan)
        yield localStorage.removeItem('targetPlan');
        yield localStorage.setItem('targetPlan',targetPlan);
        try{
            const { user } = yield Auth.signUp({
                username: email,
                password,
                attributes: {
                    name: `${first_name}`
                },
            });
            yield put(userActions.setUserData.success({email,password,first_name}))
            w3logger('ammrify code',action)
            history.push('/verify-code')
        } catch(err){
            w3logger(err)
            yield put(userActions.setUserData.error(err))
            yield put(actions.signUp.error(err.message))
            yield put(stopSubmit(formId, err.message));

        }
    }
}

function* codeConfirmation(){
    while(true){
        const { request:{values,email,first_name,password,domainData,settings},request} = yield take(actions.CONFIRM_USER.REQUEST);
        const formId = 'confirmation';
        try {
            w3logger('signun',domainData)
                yield Auth.confirmSignUp(email, values.confirmationCode);
                const { username, userDataKey, attributes, Session } = yield Auth.signIn(email, password);


            yield put(actions.confirmUser.success());
            yield put(actions.signUp.success())
            yield put(userActions.setIsauthenticated(true))
            yield put(userActions.setUserData.success({ username, userDataKey, attributes, Session }));
            
            if(isInsideWpAdmin()){
                    // const {planName=""} = planData;
                    // yield put(signinActions.createSite.request({domainData}))
                    let targetPlan = window.localStorage.getItem('targetPlan')
                    const planName= 'community'
                    domainData.type = planName
                    let isInsideWP = true
                    const request = {
                        relativeUrl: "sites/createSite",
                        apiStack: "auctollo-sites-api",
                        data: {...domainData, planName,isInsideWP,settings},
                        method: "POST"
                    };
                    w3logger('sonfirmed code',domainData)
                    const result = yield call (frontendCloudApiCall, request);
                    const { status, message, data } = result;
                    w3logger('inside wp admin',status,message,data)
                    if(targetPlan === 'premium'){
                        yield put(userActions.setActiveDomain(domainData))
                    }
                    if(status!==0) {
                        yield put(userActions.setActiveDomain(data.siteCreateResult.siteInfoExistingResult.Item))
                        window.localStorage.setItem('domainData',JSON.stringify(data.siteCreateResult.siteInfoExistingResult.Item))
                    } else throw new Error(data?.Error)
                    
                    w3logger('confirmed target',targetPlan)
                    if(targetPlan){
                        if(targetPlan==='premium'){
                            
                            let location = window.location.href.replace(`${window.location.hash}`,'#/payment-braintree')
                             window.location.href = `${location}`
                            } else {
                                let location = window.location.href.replace(`${window.location.hash}`,'#/dashboard')
                            window.location.href = `${location}`
                        }
                    }
                } else {
                    window.localStorage.setItem('domainData',JSON.stringify(domainData))
                    let location = window.location.href.replace(`${window.location.hash}`,'#/dashboard')
                    window.location.href = `${location}`
                }
                if ( isInsideWpAdmin() === true ) {
                    // store user data in WP DB, if running in WP:
                    // alert("inside wp admin!");
                    let curSession = ( yield Auth.currentSession() );
                    const refreshToken = curSession.getRefreshToken().token;
                    const idToken = curSession.getIdToken().jwtToken;
                    const {payload = null} = curSession.getIdToken();

                    const request = {
                        relativeUrl: "rfTkn",
                        method: "POST",
                        data: {
                            refresh_token: refreshToken,
                            id_token : idToken
                        }
                    };
                    const refreshCallResult = yield call( wpApiCall, request  );

                    // now save token payload...

                    const requestSavePayload = {
                        relativeUrl: "saveTokenPayload",
                        method: "POST",
                        data: {
                            payload
                        }
                    };

                    const saveTokenPayloadResult = yield call(wpApiCall, requestSavePayload );
                    w3logger( ">>>saveTokenPayloadResult:" );
                    w3logger( saveTokenPayloadResult );
                }

            
        } catch (e) {
            yield put(actions.confirmUser.error(e.message));
            yield put(stopSubmit(formId, e.message));
            yield window.localStorage.setItem('createSiteError',(e && e.message) ? e.message : e)
            yield Auth.signOut({global: false});
        }
    }
}

export default [
    fork(signUp),
    fork(codeConfirmation)
];
