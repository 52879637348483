import React from "react";

const GoogleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" height="20" width="20">
            <g clipPath="url(#clip0)">
                <path
                    fill="#FBBB00"
                    d="M4.432 12.086l-.696 2.6-2.544.053A9.956 9.956 0 010 10a9.95 9.95 0 011.118-4.599l2.266.416.992 2.251A5.944 5.944 0 004.056 10c0 .734.132 1.438.376 2.086z"
                />
                <path
                    fill="#518EF8"
                    d="M19.825 8.132a10.018 10.018 0 01-.044 3.956 9.998 9.998 0 01-3.52 5.71h-.001l-2.854-.146-.403-2.52a5.96 5.96 0 002.564-3.044H10.22V8.132h9.605z"
                />
                <path
                    fill="#28B446"
                    d="M16.26 17.797v.001A9.958 9.958 0 0110 20a9.999 9.999 0 01-8.809-5.261l3.241-2.653a5.946 5.946 0 008.57 3.045l3.257 2.666z"
                />
                <path
                    fill="#F14336"
                    d="M16.383 2.302l-3.24 2.652a5.948 5.948 0 00-8.767 3.114L1.12 5.401A9.998 9.998 0 0110 0c2.426 0 4.651.864 6.383 2.302z"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H20V20H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default GoogleIcon;
