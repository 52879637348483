import "url-search-params-polyfill";
import { get } from "lodash";

/**
 * default headers
 * enabled cors
 */
const globalOptions = async () => {
    return {
        headers: {
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*"
        },
        mode: "cors",
    };
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
    let json = response.json();
    if (response.status >= 200 && response.status < 300) {
        // const responseData = await json;
        // !responseData.success && responseData.message === "Token has expired" && Storage.logout();
        return json;
    }
    return json.then(res => {
        throw res;
    });
}

/**
 * @author Yandex <ab@piogroup.net>
 * @author Alex Malyi <am@piogroup.net>
 * @param url string
 * @param options object
 * @returns {Promise<T>}
 */
export default async function http(url, options) {
    let newOptions = Object.assign({}, await globalOptions(), options);
    let urlParams = new URLSearchParams(get(options, "params", {}));
    let newUrl = `${url}${urlParams.toString() ? "?" : ""}${urlParams.toString()}`;
    return fetch(newUrl, newOptions)
        .then(checkStatus);
}
