import React from "react";

const GitHubIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" height="20" width="20">
            <path
                fill="#191717"
                fillRule="evenodd"
                d="M10 0C4.479 0 0 4.479 0 10c0 4.417 2.883 8.16 6.81 9.51.49.06.675-.246.675-.492v-1.717c-2.761.613-3.375-1.35-3.375-1.35-.429-1.166-1.104-1.473-1.104-1.473-.92-.613.061-.613.061-.613.982.061 1.534 1.043 1.534 1.043.92 1.534 2.332 1.104 2.884.859a2.136 2.136 0 01.613-1.35c-2.208-.245-4.54-1.104-4.54-4.97 0-1.103.368-1.962 1.043-2.699-.061-.184-.43-1.227.123-2.576 0 0 .859-.246 2.76 1.043.798-.246 1.657-.307 2.516-.307s1.718.123 2.515.307c1.902-1.289 2.761-1.043 2.761-1.043.552 1.35.184 2.392.123 2.638a3.968 3.968 0 011.043 2.7c0 3.864-2.332 4.662-4.54 4.907.368.307.675.92.675 1.84v2.761c0 .246.184.553.675.491A10.01 10.01 0 0020.061 10C20 4.479 15.521 0 10 0z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default GitHubIcon;
