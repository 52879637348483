// @flow

import { call, put, take, fork } from "redux-saga/effects";
import http from "../../api";
import * as actions from "./actions";
import * as startupActions from "actions/startup";
import { API } from "aws-amplify/lib/index";
import { INITIAL_SETTINGS_VALUE } from "../../constants";
import { isObject } from "lodash";
import { siteConfig } from "../../config/offlineData";
import { isInsideWpAdmin, getWpUrl } from "../../helpers/installationFlowHelper";
import  { Auth } from "aws-amplify";
import { logWrite,w3logger } from "../../logger";

const { REACT_APP_S3_BUCKET, REACT_APP_REGION } = process.env;

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Save Settings Request
 */
function* watchSaveProfiles() {
    while (true) {
        const { request } = yield take(actions.PROFILE_SETTINGS.REQUEST);
        try {
            if (request) {
                // let { domain, settings, subscriptionType } = request;

                w3logger('account>>> requestsaga >>>>', request)
                
                // Object.keys(settings).forEach(item => {
                //     isObject(settings[item]) && settings[item].label ? settings[item] = settings[item].label.toLowerCase() : item;
                // });
                // for (var p in settings) 
                // if (/sm_/.test(p))
                //     delete settings[p];
              

                // const sm_b_exclude_cats = Object.keys(settings)
                //     .filter(item => item.includes("ex_cat_") && settings[item] === true)
                //     .map(item => {
                //         return Number(item.slice(7));
                //     });
                // const sm_in_customtypes = Object.keys(settings)
                //     .filter(item => item.includes("posttype_") && settings[item] === true)
                //     .map(item => {
                //         return item.slice(9);
                //     });
                //     w3logger("sm_in_exclude",sm_in_customtypes)

                // settings = {
                //     ...settings,
                //     // sm_b_exclude_cats,
                //     // sm_in_customtypes
                // };
                // const config = {
                //     body: { domain, settings },
                //     response: true,
                // };

                // if(process.env.REACT_APP_MOCK === 'true'){
                //     localStorage.setItem('site-config', JSON.stringify(settings))
                // } else {

                //     w3logger(">>>watchSaveSettings - store to local storage");
                //     // local storage save:
                //     localStorage.setItem('site-config', JSON.stringify(settings))

                //     // if running from wp, save to local db (WP) in any case:
                //     if(isInsideWpAdmin()){
                //         w3logger(">>>watchSaveSettings - running inside wp-admin");
                //         let reqBody = {data: settings};
                //         let response = yield fetch(`/?rest_route=/sitemap_premium/v1/settings`, {method: "POST", body: JSON.stringify(reqBody)});
                //         const jsonData = yield response.json();

                //         w3logger(">>>watchSaveSettings - jsonData:");
                //         w3logger(jsonData);

                //     }

                //     // now save to cloud, if applicable:
                //     if(subscriptionType === "non-registered") {
                //         // cannot save to cloud
                //     } else {
                //         w3logger("action>>> else")
                //         yield API.post("auctollo", "/site/config-create", config);
                //     }

                //     w3logger("action>>>watchsaveProfiles - done, activeSite.type was:" + subscriptionType);

                // }


                // ORIGINAL SAVE CODE:
                // if(request.acc_pass_new!==request.acc_pass_conf){
                //     let error = {code: "MisMatch Password",
                //                 name: "MisMatch Password",
                //                 message:'Password does not match.'}
                //     yield put(actions.saveProfiles.error(error))
                // }else{
                //     let user = yield Auth.currentAuthenticatedUser();
                //     let newAttributes = {
                //         // name:  formProps.acc_det_name,
                //         name:user.attributes.name,
                //         PreviousPassword: request.acc_pass_old, /* required */
                //         ProposedPassword: request.acc_pass_new /* required */
                //     };
                //
                //     if(request.acc_det_email){
                //         newAttributes["email"] = request.acc_det_email;
                //     }
                //
                //     // let result = await Auth.updateUserAttributes(user, newAttributes);
                //     let result = yield Auth.changePassword(user, request.acc_pass_old,request.acc_pass_new)
                //
                //     yield put(actions.saveProfiles.success());
                // }

                // END OF ORIGINAL SAVE CODE

                // NEW SAVE CODE:
                // note:
                // https://github.com/aws-amplify/amplify-js/issues/2276

                let isPasswordChangeRequest = false;

                let user = yield Auth.currentAuthenticatedUser();
                logWrite(user, "User cognito user pool::");

                if(request.acc_pass_new){
                    isPasswordChangeRequest = true;
                }

                if(isPasswordChangeRequest === true){
                    // password change:
                        if(request.acc_pass_new!==request.acc_pass_conf){
                            throw new Error("Passwords not matching");
                        }

                        // let newAttributes = {
                        //     PreviousPassword: request.acc_pass_old, /* required */
                        //     ProposedPassword: request.acc_pass_new /* required */
                        // };

                        let result = yield Auth.changePassword(user, request.acc_pass_old,request.acc_pass_new);
                        logWrite(result, "password change result::");
                } else {
                    //regular profile save, no password change
                    let newAttributes = {
                        // name:  formProps.acc_det_name,
                        name: request.acc_det_name
                    };

                    if(request.acc_det_email){
                        // newAttributes["email"] = request.acc_det_email;
                    }

                    logWrite(newAttributes, "newAttributes");
                    let result = yield call([Auth, "updateUserAttributes"],user, newAttributes);

                    logWrite(result, "result::");
                }

                // refresh session
                yield Auth.currentAuthenticatedUser({bypassCache: true});
                yield put( startupActions.loadUserAndDomainsData.request( null ) );

                // reload form:
                const {acc_det_name="", acc_det_email =""} = request || {};
                // yield put (actions.initializeProfileSettings( { acc_det_name,acc_det_email }));

                let formType = "profile";
                let successMessage = "Profile changed successfully!";
                if(isPasswordChangeRequest === true) {
                    formType = "password";
                    successMessage = "Password changed successfully!";
                }
                yield put(actions.saveProfiles.success({"acc_det_name": request.acc_det_name, "acc_det_email": acc_det_email, "formType": formType, "successMessage": successMessage}));

                // END OF NEW SAVE CODE


            }
        } catch (e) {
            w3logger('reducererr',e);
            yield put(actions.saveProfiles.error(e));
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Generate SiteMap request
 */
function* watchGetSettings() {
    while (true) {
        const { request } = yield take(actions.GET_SETTINGS.REQUEST);
        try {
            if (request) {
                let { domain } = request;
                const config = {
                    body: {
                        domain: domain,
                    },
                    response: true,
                };
                let response = {}
                let site_config =  localStorage.getItem('site-config') || '{}';
                if(process.env.REACT_APP_MOCK === 'true'){
                    response = {data:yield site_config !== '{}' ? {data:JSON.parse(site_config)} : siteConfig}
                } else {
                    if(isInsideWpAdmin()){
                        w3logger(">>>watchGetSettings - running inside wp-admin");
                        let res = yield fetch(`${getWpUrl()}/?rest_route=/sitemap_premium/v1/settings`, {method: "GET"});
                        const jsonData = yield res.json();
                        w3logger(">>>watchGetSettings - jsonData:");
                        w3logger(jsonData);
                        response.data = jsonData

                    } else {
                        response = yield API.post("auctollo", "/site/site-config", config);
                    }
                }

                w3logger('response', response)
                yield put(actions.getConfig.success(response.data));
            }
        } catch (e) {
            // yield put(actions.getConfig.initDefaultValue(INITIAL_SETTINGS_VALUE)); // todo
            yield put(actions.getConfig.error(e));
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Read robots.txt file
 */
function* watchUpdateRobot() {
    while (true) {
        const { request } = yield take(actions.UPDATE_ROBOTS.REQUEST);
        try {
            if (request && request.url) {
                const { url } = request;
                const auctollo_bucket_url = `https://${String(REACT_APP_S3_BUCKET)}.s3.${String(REACT_APP_REGION)}.amazonaws.com`;
                const callUrl = `${url}/wp-json/jwt-auth/v1/check_robot`;
                const response = yield call(http, callUrl, {
                    method: "POST",
                    body: JSON.stringify({ auctollo_bucket_url: auctollo_bucket_url })
                });

                if (response.success){
                    yield put(actions.updateRobots.success(response));
                }
            }
        } catch (e) {
            yield put(actions.updateRobots.error(e));
        }
    }
}

export default [
    fork(watchSaveProfiles),
    fork(watchGetSettings),
    fork(watchUpdateRobot),
];
