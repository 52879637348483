import SettingsIcon from "./Settings";
import SettingsGearsIcon from "./SettingsGears";
import SpeechBublesIcon from "./SpeechBubles";
import GitHubIcon from "./GitHub.js";
import MicrosoftIcon from "./Microsoft";
import GoogleIcon from "./Google";
import CheckIcon from "./Check";
import BingIcon from "./Bing";
import ExclamationIcon from "./Exclamation";
import LockOpenedIcon from "./LockOpened";
import EyeCrossedIcon from "./EyeCrossed";
import ArrowAscendIcon from "./ArrowAscend";
import ArrowDescIcon from "./ArrowDesc";
import DNSIcon from "./DNS";
import ConnectionIcon from "./Connection";
import ErrorIcon from "./Error";

export {
    SettingsIcon,
    SettingsGearsIcon,
    SpeechBublesIcon,
    GitHubIcon,
    MicrosoftIcon,
    GoogleIcon,
    CheckIcon,
    BingIcon,
    ExclamationIcon,
    LockOpenedIcon,
    EyeCrossedIcon,
    ArrowAscendIcon,
    ArrowDescIcon,
    DNSIcon,
    ConnectionIcon,
    ErrorIcon
};
