/***
 * Returns the url of xml sitemap index in cloud for the provided domain
 * @param domain
 * @returns {string} - absolute URL of the index file
 */
export const getCloudSitemapIndexUrl = (domain,data) => {
    const {REACT_APP_S3_BUCKET, REACT_APP_REGION} = process.env;
    const sitemapFileName = getSitemapFileName(data); 

    return `https://${REACT_APP_S3_BUCKET}.s3.${REACT_APP_REGION}.amazonaws.com/hosts/${domain}/sitemap/${sitemapFileName}.xml`;
}

export const getSitemapFileName = data => {
    let sitemapFileName = "sitemap";
    if(data && data.sitemapName){
        sitemapFileName = data.sitemapName;
    }
    return sitemapFileName;
}
