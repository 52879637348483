import * as actions from "../actions";
import * as userActions from "actions/user";

const initialState = {
    loading: false,
    b_google_connected: false
};

/***
 * Site level premium settings
 *
 * @param state
 * @param action
 * @returns {({} & {loading: boolean, b_google_connected: boolean} & {loading: boolean})|{loading: boolean, b_google_connected: boolean}|({} & {loading: boolean, b_google_connected: boolean} & {data: {}, loading: boolean})|({} & {loading: boolean, b_google_connected: boolean} & {loading, b_google_connected})}
 */
const premiumSettings = (state = initialState, action) => {
    switch (action.type) {

        // reset on site change:
        case userActions.SET_ACTIVE_DOMAIN:
            return Object.assign({}, state, {
                ...initialState
            });

        // reset on site change:
        case userActions.UPDATE_ACTIVE_DOMAIN:
            return Object.assign({}, state, {
                ...initialState
            });


        case actions.GET_PREMIUM_SETTINGS.FAILURE:
            return Object.assign({}, state, {
                ...initialState
            });

        case actions.GET_PREMIUM_SETTINGS.SUCCESS: {
            const newData = {
                loading: false,
                ...action.response
            };

            return Object.assign({}, state, newData);
        }

        case actions.GET_PREMIUM_SETTINGS.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                data: {},
            });

        default:
            return state;
    }
};

export default premiumSettings;
