import * as actions from "actions/appSettings";

const initialState = {
    platform: false
};

const appSettings = (state = initialState, action) => {
    switch (action.type) {

        case actions.SET_PLATFORM: {
            return {
                ...state,
                platform: action.payload
            };
        }

        default:
            return state;
    }
};

export default appSettings;
