import { put, take, fork, call } from "redux-saga/effects";
import { API, Auth } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import dotenv from "dotenv";
import { premiumResponse } from "../../config/offlineData";
import { cloudApiCall } from "../../api/apiClient";
import { logWrite, w3logger } from "../../logger";
dotenv.load();

const {
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE
} = process.env;

function* watchLoadCloudSitemapStatus() {
    while (true) {
        const { request: { domain} } = yield take(actions.CLOUD_SITEMAP_STATUS_LOAD.REQUEST);
        try {
            if ( process.env.REACT_APP_MOCK === 'true' ) {
                yield put( actions.loadCloudSitemapStatus.success( {} ) );
                yield put(userActions.updateCloudSitemapStatus( { }));

            } else {
                const userDetails = yield Auth.currentAuthenticatedUser()
                if(userDetails!==null){
                // real call:
                    const reqBody = {domain};
                    const callRequest = {
                        relativeUrl: "build/checkSitemapStatus",
                        apiStack: "auctollo-sitemap-build-api",
                        data: reqBody,
                        method: "POST"
                    };

                    let jsonData = null;
                    let successObj = yield call(cloudApiCall, callRequest);

                    logWrite(successObj, "successObj");

                    if(typeof successObj.body == "string"){
                        jsonData = JSON.parse(successObj.body);
                    } else {
                        jsonData = successObj;
                    }

                    jsonData = jsonData.data;
                    logWrite(jsonData, "jsonData after successObj");

                    yield put(actions.loadCloudSitemapStatus.success(jsonData));
                    yield put(userActions.updateCloudSitemapStatus(jsonData));
                }
            }
        } catch (e) {
            w3logger("error in watchDisplayMySitesData");
            w3logger(e);
            yield put(actions.loadCloudSitemapStatus.error(e.message));
        }
    }
}

export default [
    fork(watchLoadCloudSitemapStatus),
];
