//@flow

import React from "react";
import { Heading, ButtonPrimary } from "common/components";

import "../../../assets/scss/global.scss";

import type { CompleteSetupTypes } from "./CompleteSetupTypes";
import { withTranslation } from "react-i18next";

const CompleteSetup = (props: CompleteSetupTypes) => {
    const { authorize, type = "Google" , t } = props;
    const buttonText = `Connect ${type}`;
    const subtitle = `To view this data you will need to complete your setup with ${type} Analytics.`;
    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <Heading
                align="center"
                title={t("Complete Your Setup","Complete Your Setup")}
                subtitle={t(subtitle,subtitle)} />
            <ButtonPrimary
                buttonSize="medium"
                buttonText={t(buttonText,buttonText)}
                action={authorize}
            />
        </div>
    );
};

export default withTranslation()(CompleteSetup);
