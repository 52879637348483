// @flow
import React from "react";
import {ButtonPrimary, FormInput} from "common/components/"
import { Field } from "redux-form";
import { required } from "../../signupOld/validate";
import * as actions from '../redux/actions'
import {useDispatch} from 'react-redux'
// type
import {w3logger} from 'logger'
// styles
import "assets/scss/global.scss";
import styles from "../signUpForm.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

export default ({ handleSubmit, loading }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const confirmation = useSelector(state=>state)

    const {email, password } = confirmation.user.userData!==null && confirmation.user.userData
    const onSubmitForm = data => {
        const domainData = confirmation.user.activeSite
        data.domainData = domainData
        data.settings = confirmation?.settings?.data
        dispatch(actions.confirmUser.request(data))
    }
    return (
        <>
            <h4 className="title">{t("Please check your email and input confirmation code here")}</h4>
            <form className={styles["form-input-group"]}>
                <div className="form-row">
                    <label className={styles.label}>{t("Confirmation Code")}</label>
                    <Field
                        name="confirmationCode"
                        id="confirmationCode"
                        placeholder={t("Code")}
                        component={FormInput}
                        validate={required}
                    />

                </div>
                <div className="error-block">{confirmation.confirmation && confirmation.confirmation.confirmationError}</div>
                <ButtonPrimary
                    buttonText={t("Get started")}
                    buttonSize="small"
                    loading={loading}
                    action={handleSubmit((values => onSubmitForm({ values, email, password })))}
                />
            </form>
        </>
    );
};
