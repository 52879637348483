// @flow
import React, { Component } from "react";

//styles
import "assets/scss/global.scss";
import styles from "./chartDropdown.module.scss";

//types
import type { ChartDropdownPropType, ChartDropdownStateType } from "./ChartDropdownType";

class ChartDropdown extends Component<ChartDropdownPropType, ChartDropdownStateType> {
    state = {
        listOpen: false,
        headerTitle: this.props.selected,
        chartDropdownConfig: this.props.list
    }
    handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
        this.setState({
            headerTitle: event.currentTarget.textContent,
            listOpen: false
        });

        this.props.onSelect(event.currentTarget.textContent)
    };

    toggleList() {
        this.setState({
            listOpen: !this.state.listOpen
        });
    }
    render() {
        return (
            <div className={styles["chart-dropdown"]}>
                <div className="d-flex">Show:
                    <span
                        className={`${styles["title"]} ${styles[this.state.listOpen && "opened"]}`}
                        onClick={() => this.toggleList()}>{this.state.headerTitle} <i className={styles["arrow-icon"]}></i></span>
                </div>
                {this.state.listOpen && <div className={` list bg-white ${styles["list"]}`}>
                    {this.state.chartDropdownConfig.map((item, i) => (
                        <button className={styles["list-item"]} key={i} onClick={this.handleClick}>{item.title}</button>
                    ))}
                </div>}
            </div>
        );
    }
}

export default ChartDropdown;
