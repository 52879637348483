/*
Calls to:
 WP REST API
 AWS Gateway APIs
 */
import { API } from "aws-amplify";
import http from "./index";
import { isInsideWpAdmin,getWpUrl } from "../helpers/installationFlowHelper";
import { Auth } from "aws-amplify/lib";
import { w3logger, logError  } from 'logger'

const {
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_API_PAYMENT_GATEWAY_URL,
    REACT_APP_SITES_API_GATEWAY_URL,
    REACT_APP_SITEMAPS_API_GATEWAY_URL,
    REACT_APP_COGNITO_DOMAIN,
    REACT_APP_LOCAL_WP_ADMIN_URL,
    REACT_APP_SIMULATE_LOCAL_WP_ADMIN,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE,
    REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL,
} = process.env;

/***
 * Returns api endpoint base:
 * @param apiLabel
 */
const getAwsApiUrl = apiLabel => {
    let urls = {
        "auctollo": REACT_APP_API_GATEWAY_URL,
        "auctollo-payment-api" : REACT_APP_API_PAYMENT_GATEWAY_URL,
        "auctollo-sites-api": REACT_APP_SITES_API_GATEWAY_URL,
        "auctollo-sitemaps-api": REACT_APP_SITEMAPS_API_GATEWAY_URL,
        "cognito": "https://" + REACT_APP_COGNITO_DOMAIN,
        "auctollo-sitemap-build-api":REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL,

    };

    return urls[ apiLabel ];
};

const getWpNonce = () =>{
    if(!window.gxsconfig || !window.gxsconfig){
        return "";
    } else {
        return window.gxsconfig.nonce;
    }
};

/**
 * Call to WP REST API
 * It is always a frontend call
 * @returns {Promise<void>}
 */
export const wpApiCall = async request => {
    let { relativeUrl, data, method } = request;
    if ( !method ) {
        method = "POST";
    }
    let url = "/?rest_route=/sitemap_premium/v1/" + relativeUrl;
    if ( REACT_APP_SIMULATE_LOCAL_WP_ADMIN === "true" ) {
        url = getWpUrl() + url;
    }

    let reqSettings = {
        method: method,
    };
    if ( data ) {
        reqSettings.body = JSON.stringify( data );
    }

    reqSettings.headers = {
        "X-WP-Nonce" : getWpNonce(),
        "Cache-Control": "no-cache"
    };

    const response = await http( url, reqSettings );

    return response;
};

/***
 *
 * @param request
 * @returns {Promise<*>} json {status, message, data}
 * @private
 */
export const frontendCloudApiCall = async request => {
    let { relativeUrl, data, method = "POST", apiStack } = request;

    let apiBaseUrl = getAwsApiUrl( apiStack );
    let reqBody = data;
    let jsonData = null, responseObject = null;

    if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
        // localhost:
        w3logger( "localhost connect" );
        let response = await fetch( `${apiBaseUrl}/${relativeUrl}`, {
            method,
            body: JSON.stringify( reqBody )
        } );
        const responseObject = await response.json();
        jsonData = JSON.parse( responseObject.body );

    } else {
        // staging / prod:
        w3logger( "prod connect!!!!" );
        const apiCallConfig = {
            body: { ...reqBody }
        };
        // returns response object, with property body as string
        if ( method.toLowerCase() === "post" ) {
            responseObject = await API.post( apiStack, `/${relativeUrl}`, apiCallConfig );
        } else {
            responseObject = await API.get( apiStack, `/${relativeUrl}`, apiCallConfig );
        }

        // at this point, responseObject is an object: {body - string, headers, statusCode}

        if ( responseObject && responseObject.body ) {
            jsonData = JSON.parse( responseObject.body );
        } else {
            jsonData = responseObject;
        }

    }
    return jsonData;
};

/***
 *
 * @param request
 * @returns {Promise<*>} json {status, message, data}
 * @private
 */
export const backendCloudApiCall = async request => {
    let { relativeUrl, data, method = "POST", apiStack } = request;
    let apiBaseUrl = getAwsApiUrl( apiStack );

    let reqBody = data;
    let jsonData = null, responseObject = null;
    if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
        // localhost:
        let url = `${apiBaseUrl}/${relativeUrl}`;

        let backendUrl = `/?rest_route=/sitemap_premium/v1/apiCallNoAuth`;

        if ( REACT_APP_SIMULATE_LOCAL_WP_ADMIN === "true" ) {
            backendUrl = REACT_APP_LOCAL_WP_ADMIN_URL + backendUrl;
        }

        const response = await http( backendUrl, {
            method: "POST",
            body: JSON.stringify( { url, data, method } )
        } );

        return response;
    } else {
        // staging / prod:
        w3logger( "prod connect!!!!" );
        let url = `${apiBaseUrl}/${relativeUrl}`;
        let backendUrl = `/?rest_route=/sitemap_premium/v1/apiCallWithAuth`;

        if ( REACT_APP_SIMULATE_LOCAL_WP_ADMIN === "true" ) {
            backendUrl = REACT_APP_LOCAL_WP_ADMIN_URL + backendUrl;
        }

        const response = await http( backendUrl, {
            method: "POST",
            headers: {
                "X-WP-Nonce" : getWpNonce(),
                "Cache-Control": "no-cache"
            },
            body: JSON.stringify( { url, data, method } )
        } );

        return response;

    }
};

/**
 * Returns true if there's an authenticated user in AmplifyJS:
 * @returns {Promise<void>}
 */
export const isLiveSessionAvailable = async () => {
    try{
        const authUser = (await Auth.currentAuthenticatedUser());
        if(authUser){
            return true;
        }
    } catch(ex){
        logError(ex, " Error in isLiveSessionAvailable");
    }
    return false;
};

/***
 * Wrapper around backend and frontend cloud api calls
 * @param request
 * @returns {Promise<*>}
 */
export const cloudApiCall = async request => {
    if ( isInsideWpAdmin() === true ) {
        // inside wp:
        //alert("backend call!");
        return backendCloudApiCall( request );
    } else {
        // standalone site
        //alert("frontend call!");
        return frontendCloudApiCall( request );
    }
};
