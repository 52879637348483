// @flow

import saveSettings from "./saveSettings";
import settings from "./settings";
import premiumSettings from "./premiumSettings";
import payment from "./payment";
import subscription from "./subscription";
import billingDetail from './billingDetail'
import transactionDetails from "./transactionDetails";
import receipt from "./receipt";
export default {
    saveSettings: saveSettings,
    settings: settings,
    premiumSettings: premiumSettings,
    payment:payment,
    billingDetail:billingDetail,
    subscription:subscription,
    transactionDetails:transactionDetails,
    receipt:receipt
};
