//@flow

import React, { Component } from "react";
import { cutString, prepareDataToPercent, cutNumber } from "helpers";
import styles from "./table.module.scss";
import type { TableWebmasterPropsTypes, ComponentStateTypes } from "./TableWebmasterPropsTypes";
import ReactPaginate from "react-paginate";
import { withTranslation } from "react-i18next";

const emptyDataItem = {
    clicks: "---",
    ctr: "---",
    impressions: "---",
    keys: ["---------------"],
    position: "---",
};

const emptyData = [emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem];

// const TableWebmaster = (props: TableWebmasterPropsTypes, state: ComponentStateTypes) => {
class TableWebmaster extends Component<TableWebmasterPropsTypes, ComponentStateTypes> {
    state = {
        currentPage: 0,
        data: []
    }
    componentWillReceiveProps(nextProps: any) {
        const tempData = nextProps.data;
        this.setState({data: tempData});
    }

    render() {
        const {t} = this.props;
        const PER_PAGE = 10;
        const offset = this.state.currentPage * PER_PAGE;
        const currentPageData = this.state?.data?.slice(offset, offset + PER_PAGE);
        const pageCount = Math.ceil(this.state?.data?.length / PER_PAGE);

        let handlePageClick = ({ selected: selectedPage }) => {
            this.setState({ currentPage: selectedPage });
        }

        return (
            <div className={` ${styles.tableresponsive} `}>
                <table className={`${styles.table}`}>
                    <thead>
                        <tr>
                            <th>{t("Path")}</th>
                            <th>{t("Clicks")}</th>
                            <th>{t("Impressions")}</th>
                            <th>{t("CTR")}</th>
                            <th>{t("Position")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td><a href={item.keys[0]} rel="noreferrer" target="_blank">{item.keys[0]}</a></td>
                                    <td>{item.clicks}</td>
                                    <td>{item.impressions}</td>
                                    <td>{prepareDataToPercent(item.ctr)}</td>
                                    <td>{cutNumber(item.position, 0)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="5">
                                <div className="table-footer">
                                    <ReactPaginate
                                        previousLabel={"← " + t("Previous")}
                                        nextLabel={t("Next")+" →"}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={styles["activePaginate"]}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}

export default withTranslation()(TableWebmaster);
