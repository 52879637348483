// @flow

import { call, put, take, fork } from "redux-saga/effects";
import http from "../../api";
import * as actions from "./actions";
import { API } from "aws-amplify/lib/index";
import { INITIAL_SETTINGS_VALUE } from "../../constants";
import { isObject } from "lodash";
import { siteConfig } from "../../config/offlineData";
import { isInsideWpAdmin } from "../../helpers/installationFlowHelper";
import { logWrite } from "../../logger";
import { w3logger } from 'logger';

const { REACT_APP_S3_BUCKET, REACT_APP_REGION } = process.env;

const {	
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_API_PAYMENT_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE	
} = process.env;

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Save Settings Request
 */
function* watchFetchPlans() {
    while (true) {
        const { request } = yield take(actions.FETCH_PLANS.REQUEST);
        w3logger("Got plans request");
        try {

            let response = {};
            let responseObject = {};
            let jsonData = null;

            w3logger("About to call the lambda backend for plan info");

            if(process.env.REACT_APP_MOCK === 'true'){
                jsonData  = {data: {}};
            } else {
                // reall call:

                // let reqBody ={domain};
                w3logger("REACT_APP_API_GATEWAY_URL",REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE)
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){
                    // localhost:
                    w3logger("localhost connect");
                    response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/get-plans`, {method: "GET"});
                    const responseObject = yield response.json();
                    jsonData = JSON.parse(responseObject.body);

                } else {
                    // staging / prod:
                    w3logger("prod connect!!!!");
                    // const apiCallConfig = {
                    //     body: { ...reqBody }
                    // };
                    const config = {
                            response: true,
                        };
                    // returns response object, with property body as string
                    const response = yield API.get("auctollo-payment-api", "/payment/get-plans");
                    // let responseObject = JSON.parse(response.body)
                    jsonData = JSON.parse(response.body)
                  
                }

            }
            w3logger("resulting jsonData get plans",jsonData.data.plans);
            w3logger(jsonData);

            yield put(actions.fetchPlans.success(jsonData.data));
        } catch (e) {
            w3logger("error in getplan:Error",e);
            w3logger(e);
            yield put(actions.fetchPlans.error(e.message));
        }
    }
}

export default [
    fork(watchFetchPlans)
];
