// @flow

import { call, put, take, fork } from "redux-saga/effects";
import http from "../../api";
import * as actions from "./actions";
import * as userActions from "actions/user";
import { API } from "aws-amplify/lib/index";
import { Auth } from 'aws-amplify'
import { INITIAL_SETTINGS_VALUE } from "../../constants";
import { isObject } from "lodash";
import { siteConfig } from "../../config/offlineData";
import { isInsideWpAdmin, getWpUrl } from "../../helpers/installationFlowHelper";
import { cloudApiCall } from "api/apiClient";
import { logWrite } from "../../logger";
import AuthRoute from '../../router/AuthRoute';
import { w3logger, logError } from 'logger';

const { REACT_APP_S3_BUCKET, REACT_APP_REGION } = process.env;

const {	
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_API_PAYMENT_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE	
} = process.env;

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Save Settings Request
 */
 function* watchSetLicenceParams() {
    while (true) {
        const { request } = yield take(actions.SET_LICENSE_PARAMS.REQUEST);
        try {
            if (request) {
                let { domain = "", settings, subscriptionType } = request;
                if(!domain){
                    alert("Domain name not provided. Cannot save settings.");
                    break;
                }

                w3logger('request >>>>', request)


                const config = {
                    body: { domain, settings },
                    response: true,
                };

                if(process.env.REACT_APP_MOCK === 'true'){
                    localStorage.setItem('site-config', JSON.stringify(settings))
                } else {

                    w3logger(">>>watchSaveSettings - store to local storage");
                    // local storage save:
                    localStorage.setItem('site-config', JSON.stringify(settings))

                    // if running from wp, save to local db (WP) in any case:
                    if(isInsideWpAdmin()){
                        w3logger(">>>watchSaveSettings - running inside wp-admin");
                        let reqBody = {data: settings};
                        let response = yield fetch(`${getWpUrl()}/?rest_route=/sitemap_premium/v1/setLicenseParams`, {method: "POST", body: JSON.stringify(reqBody)});
                        const userDetails = yield Auth.currentAuthenticatedUser()
                        if(userDetails!==null){
                            yield API.post("auctollo", "/site/save-settings-s3", config);
                        }

                        // const jsonData = yield response.json();

                        w3logger(">>>watchSaveSettings - jsonData:");
                        // w3logger(jsonData);

                    } else {   
                        // now save to cloud, if applicable:
                        if(subscriptionType === "non-registered") {
                            // cannot save to cloud
                        } else {
                            let response =  yield API.post("auctollo", "/site/config-create", config);
                            w3logger("responsesavesettings>>>",response)
                            yield put(actions.getConfig.success(response.data));
                        }
                    }
                    w3logger(">>>watchSaveSettings - done, activeSite.type was:" + subscriptionType);

                }

                logWrite("going to reloading settings...");
                // reload settings:
                //yield put(actions.getConfig.request({domain}));
                //logWrite("reloading settings...");
            }
        } catch (e) {
            w3logger('error',e)
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Save Settings Request
 */
function* watchSaveSettings() {
    while (true) {
        const { request } = yield take(actions.SAVE_SETTINGS.REQUEST);
        try {
            if (request) {
                let { domain = "", settings, subscriptionType } = request;
                if(!domain){
                    alert("Domain name not provided. Cannot save settings.");
                    break;
                }

                w3logger('request >>>>', request)
                if(subscriptionType !== 'premium'){
                    settings.sm_news = false
                }
                Object.keys(settings).forEach(item => {
                    isObject(settings[item]) && settings[item].label ? settings[item] = settings[item].label.toLowerCase() : item;
                });

                const sm_b_exclude_cats = Object.keys(settings)
                    .filter(item => item.includes("ex_cat_") && settings[item] === true)
                    .map(item => {
                        return Number(item.slice(7));
                    });

                const sm_in_customtypes = Object.keys(settings)
                    .filter(item => item.includes("posttype_") && settings[item] === true)
                    .map(item => {
                        return item.slice(9);
                    });

                const sm_in_tax = Object.keys(settings)
                    .filter(item => item.includes("taxonomies_") && settings[item] === true)
                    .map(item => {
                        return item.slice(11);
                    });

                settings = {
                    ...settings,
                    sm_b_exclude_cats,
                    sm_in_customtypes,
                    sm_in_tax
                };

                const config = {
                    body: { domain, settings },
                    response: true,
                };

                if(process.env.REACT_APP_MOCK === 'true'){
                    localStorage.setItem('site-config', JSON.stringify(settings))
                } else {

                    w3logger(">>>watchSaveSettings - store to local storage");
                    // local storage save:
                    localStorage.setItem('site-config', JSON.stringify(settings))

                    // if running from wp, save to local db (WP) in any case:
                    if(isInsideWpAdmin()){
                        w3logger(">>>watchSaveSettings - running inside wp-admin");
                        let reqBody = {data: settings};
                        let response = yield fetch(`${getWpUrl()}/?rest_route=/sitemap_premium/v1/settings`, {method: "POST", body: JSON.stringify(reqBody)});
                        const userDetails = yield Auth.currentAuthenticatedUser()
                        if(userDetails!==null){
                            yield API.post("auctollo", "/site/save-settings-s3", config);
                        }
                        const jsonData = yield response.json();

                        w3logger(">>>watchSaveSettings - jsonData:");
                        w3logger(jsonData);
                        yield put(actions.getConfig.success(jsonData));

                    } else {   
                        // now save to cloud, if applicable:
                        if(subscriptionType === "non-registered") {
                            // cannot save to cloud
                        } else {
                            let response =  yield API.post("auctollo", "/site/config-create", config);
                            w3logger("responsesavesettings>>>",response)
                            yield put(actions.getConfig.success(response.data));
                        }
                    }
                    w3logger(">>>watchSaveSettings - done, activeSite.type was:" + subscriptionType);

                }

                yield put(actions.saveSettings.success());
                logWrite("going to reloading settings...");
                // reload settings:
                //yield put(actions.getConfig.request({domain}));
                //logWrite("reloading settings...");
            }
        } catch (e) {
            yield put(actions.saveSettings.error(e));
        }
    }
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Generate SiteMap request
 */
function* watchGetSettings() {
    while (true) {
        const { request } = yield take(actions.GET_SETTINGS.REQUEST);
        try {
            if (request) {
                let { domain } = request;
                const config = {
                    body: {
                        domain: domain,
                    },
                    response: true,
                };
                let response = {}
                let site_config =  localStorage.getItem('site-config') || '{}';
                if(process.env.REACT_APP_MOCK === 'true'){
                    response = {data:yield site_config !== '{}' ? {data:JSON.parse(site_config)} : siteConfig}
                } else {
                    if(isInsideWpAdmin()){
                        w3logger(">>>watchGetSettings - running inside wp-admin");
                        let res = yield fetch(`${getWpUrl()}/?rest_route=/sitemap_premium/v1/settings`, {method: "GET"});
                        const jsonData = yield res.json();
                        w3logger(">>>watchGetSettings - jsonData:");
                        w3logger(jsonData);
                        response.data = jsonData

                    } else {
                        response = yield API.post("auctollo", "/site/site-config", config);
                        w3logger('OK!! we got settings: ', response.data)
                    }
                }

                w3logger('OK!! its time to call back action success function', actions.getConfig);
                yield put(actions.getConfig.success(response.data));
                w3logger("OK!! reducer success function called");
            }
        } catch (e) {
            w3logger('get setting error',e)
            // yield put(actions.getConfig.initDefaultValue(INITIAL_SETTINGS_VALUE)); // todo
            yield put(actions.getConfig.error(e));
        }
    }
}

/***
 * Get premium settings for the site
 * @returns {Generator<<"PUT", PutEffectDescriptor<*|{response: *, type: *}>>|<"CALL", CallEffectDescriptor>|<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<*|{type: *, error: *}>>, *>}
 */
function* watchGetPremiumSettings() {
    while (true) {
        const { request } = yield take(actions.GET_PREMIUM_SETTINGS.REQUEST);
        try {
            if (request) {
                let { domain } = request;
                if(!domain){
                    logError(">>>watchGetPremiumSettings exiting - no domain provided");
                    continue;
                }
                let reqBody = { domain, settingsType: "premium" };

                const callRequest = {
                    relativeUrl: "sites/getSiteSettings",
                    apiStack: "auctollo-sites-api",
                    data: reqBody,
                    method: "POST"
                };

                let jsonData = yield call(cloudApiCall, callRequest);
                jsonData = jsonData.data;

                yield put(actions.getPremiumSettings.success(jsonData));
            }
        } catch (e) {
            // yield put(actions.getPremiumSettings.initDefaultValue(INITIAL_SETTINGS_VALUE)); // todo
            yield put(actions.getPremiumSettings.error(e));
        }
    }
}

/***
 * Connect to Google in Site Settings
 * @returns {Generator<<"PUT", PutEffectDescriptor<*|{response: *, type: *}>>|<"CALL", CallEffectDescriptor>|<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<*|{type: *, error: *}>>, *>}
 */
function* watchConnectGoogleToSite () {
    while (true) {
        const { request } = yield take(actions.CONNECT_GOOGLE_TO_SITE.REQUEST);
        try {
            if (request) {
                let { domain } = request;
                if(!domain){
                    logError(">>>watchConnectGoogleToSite exiting - no domain provided");
                    continue;
                }
                let reqBody = { domain };

                const callRequest = {
                    relativeUrl: "sites/googleAuthStartAjax",
                    apiStack: "auctollo-sites-api",
                    data: reqBody,
                    method: "POST"
                };

                let jsonData = yield call(cloudApiCall, callRequest);
                jsonData = jsonData.data;

                let {url = ""} = jsonData ||{};

                if(url){
                    yield put(actions.connectGoogleToSite.success(jsonData));
                    // todo, sanitize / check url:
                    window.location.href = url;
                } else {
                    yield put(actions.connectGoogleToSite.error({"message": "Empty url"}));
                }

            }
        } catch (e) {
            // yield put(actions.connectGoogleToSite.initDefaultValue(INITIAL_SETTINGS_VALUE)); // todo
            yield put(actions.connectGoogleToSite.error(e));
        }
    }
}


/***
 * Remove Google Account from site (disconnect)
 */
function* watchDisconnectGoogleFromSite () {
    while (true) {
        const { request } = yield take(actions.DISCONNECT_GOOGLE_FROM_SITE.REQUEST);
        try {
            if (request) {
                let { domain } = request;
                if(!domain){
                    logError(">>>watchDisconnectGoogleFromSite exiting - no domain provided");
                    continue;
                }
                let reqBody = { domain };
                w3logger('setGoogleToken>>>')
                try{
                    const config = {
                        body: { token: null },
                        response: true,
                    };
                    const response = yield API.put("auctollo", "/site/tokens",config);
                    w3logger('setGoogleToken>>>',response)
                    let jsonData = {
                        b_google_connected: false
                    };                        
                    yield put(actions.disconnectGoogleFromSite.success(jsonData));
                    yield put(actions.getPremiumSettings.request( { domain }));
                    localStorage.removeItem("GOOGLE_TOKENS")
                    window.location.reload();
                } catch(e){
            yield put(actions.disconnectGoogleFromSite.error(e));
                    w3logger('e', e)
                }

                // const callRequest = {
                //     relativeUrl: "sites/googleAuthDisconnect",
                //     apiStack: "auctollo-sites-api",
                //     data: reqBody,
                //     method: "POST"
                // };

                // let jsonData = yield call(cloudApiCall, callRequest);
                // jsonData = jsonData.data;
                // if(jsonData){
                //     yield put(actions.disconnectGoogleFromSite.success(jsonData));
                //     localStorage.removeItem("GOOGLE_TOKENS")
                //     // reload premium settings:
                //     yield put(actions.getPremiumSettings.request( { domain }));
                // }else{
                //     throw Error("An error occurred.")
                // }

            }
        } catch (e) {
            // yield put(actions.connectGoogleToSite.initDefaultValue(INITIAL_SETTINGS_VALUE)); // todo
            yield put(actions.disconnectGoogleFromSite.error(e));
        }
    }
}


/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Read robots.txt file
 */
function* watchUpdateRobot() {
    while (true) {
        const { request } = yield take(actions.UPDATE_ROBOTS.REQUEST);
        try {
            if (request && request.url) {
                const { url } = request;
                const auctollo_bucket_url = `https://${String(REACT_APP_S3_BUCKET)}.s3.${String(REACT_APP_REGION)}.amazonaws.com`;
                const callUrl = `${url}/wp-json/jwt-auth/v1/check_robot`;
                const response = yield call(http, callUrl, {
                    method: "POST",
                    body: JSON.stringify({ auctollo_bucket_url: auctollo_bucket_url })
                });

                if (response.success){
                    yield put(actions.updateRobots.success(response));
                }
            }
        } catch (e) {
            yield put(actions.updateRobots.error(e));
        }
    }
}

function* watchDisplaySubscriptionInfo() {
    while (true) {
        const { request: { activeSite, userEmail, userName, plugin, action } } = yield take(actions.DISPLAY_SUBSCRIPTION_INFO.REQUEST);
        w3logger("Got subscription display request",userEmail,plugin);
        try {

            let response = {};
            let responseObject = {};
            let jsonData = null;

            w3logger("About to call the lambda backend for subscription info");

            if(process.env.REACT_APP_MOCK === 'true'){
                jsonData  = {data: {}};
            } else {
                // reall call:
                const user = yield Auth.currentAuthenticatedUser()
                if(user!==null){
                    let {domain} = activeSite
                    w3logger('subs',domain,activeSite,plugin)
                    let reqBody ={domain,userEmail,userName,plugin};
                    w3logger("REACT_APP_API_GATEWAY_URL",REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE)
                    if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){
                        // localhost:
                        w3logger("localhost connect");
                        response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/subscriptionInfo`, {method: "POST", body: JSON.stringify(reqBody)});
                        const responseObject = yield response.json();
                        jsonData = JSON.parse(responseObject.body);

                    } else {
                        // staging / prod:
                        w3logger("prod connect!!!!");
                        const apiCallConfig = {
                            body: { ...reqBody }
                        };
                        const config = {
                                response: true,
                            };
                        // returns response object, with property body as string
                        const response = yield API.post("auctollo-payment-api", "/payment/subscriptionInfo",apiCallConfig);
                        let responseObject = JSON.parse(response.body)
                        let wpPlugin = {"wpPlugin":responseObject.wpPlugin,'wpPluginDomain':responseObject.domain}
                        yield put(userActions.setToken(wpPlugin))
                        localStorage.setItem("wpPlugin",responseObject.wpPlugin)
                        localStorage.setItem("wpPluginDomain",responseObject.domain)
                        if(responseObject.data.additionalData){
                            jsonData = responseObject.data.additionalData.subscriptionData
                            w3logger("resulting jsonData subscriptionqqq:jsondata",responseObject);
                        }
                        // w3logger("resulting jsonData subscription:setting",jsonData);
                    
                    }
                }

            }
            w3logger("resulting jsonData subscription:setting",jsonData);
            w3logger(jsonData);

            yield put(actions.displaySubscriptionInfo.success(jsonData));
        } catch (e) {
            w3logger("error in watchDisplaySubscriptionInfo:settingError",e);
            w3logger(e);
            yield put(actions.displaySubscriptionInfo.error(e.message));
        }
    }
}

function* watchPaymentUpdateInfo() {
    while (true) {
        const { request } = yield take(actions.PAYMENT_UPDATE_INFO.REQUEST);
        try {
            let response = {};
            let responseObject = {};
            let jsonData = null;

            if(process.env.REACT_APP_MOCK === 'true'){
                // response  = {data:yield premiumResponse};
                jsonData  = {data: {}};
            } else {
                // real call:
                const reqBody = {
                    ...request
                };

                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){
                    // localhost:
                    w3logger("localhost connect");

                    response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/update-payment-info`, {method: "PUT", body: JSON.stringify(reqBody)});
                    const responseObject = yield response.json();
                    jsonData = JSON.parse(responseObject.body);

                } else {
                    // staging / prod:
                    w3logger("prod connect!!!!");
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    // returns response object, with property body as string
                    const response = yield API.put("auctollo-payment-api", "/payment/update-payment-info",apiCallConfig);
                    // const responseObject = yield response.json();
                    jsonData = JSON.parse(response.body);
                }
            }
            w3logger("resulting json data:");
            w3logger(jsonData);

            const { data } = jsonData;
            yield put(actions.paymentUpdateInfo.success());
            yield call(actions);
        } catch (e) {
            w3logger("dd error:");
            w3logger(e);
            yield put(actions.paymentUpdateInfo.error(e.message));
        }
    }
}

function* watchGetPaymentInfo() {
    while (true) {
        const { request } = yield take(actions.DISPLAY_PAYMENT_INFO.REQUEST);
        w3logger("Got display request paymentinfo",request);
        try {

            let response = {};
            let responseObject = {};
            let jsonData = null;

            w3logger("About to call the lambda backend for subscription info");

            if(process.env.REACT_APP_MOCK === 'true'){
                jsonData  = {data: {}};
            } else {
                // reall call:

                let reqBody ={"userId":request};
                w3logger("REACT_APP_API_GATEWAY_URL",REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE)
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){
                    // localhost:
                    w3logger("localhost connect");
                    response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/get-payment-info?data=${request}`, {method: "GET"});
                    const responseObject = yield response.json();
                    jsonData = JSON.parse(responseObject.body);

                } else {
                    // staging / prod:
                    w3logger("prod connect!!!!");
                    // const apiCallConfig = {
                    //     body: { ...reqBody }
                    // };
                    const config = {
                            response: true,
                        };
                    const response = yield API.get("auctollo-payment-api", `/payment/get-payment-info?userId=${request.userId}&domain=${request.domain}`);
                    // const responseObject = yield response.json();
                    jsonData = JSON.parse(response.body)
                }

            }
            w3logger("resulting jsonData:asdf");
            w3logger(jsonData);

            yield put(actions.displayPaymentInfo.success(jsonData.data));
        } catch (e) {
            w3logger("error in watchGetPaymentInfo",e);
            w3logger(e);
            yield put(actions.displayPaymentInfo.error(e.message));
        }
    }
}

function* watchPaymentStatus() {
    while(true){
        const { request } = yield take(actions.GET_PAYMENT_STATUS.REQUEST);
        let jsonData = null;

        try{
            if(process.env.REACT_APP_MOCK === 'true'){	
                jsonData  = {data: {}};	
            } else {	
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){	
                    w3logger("localhost connect get customer detail");	
                } else {	
                    w3logger("prod connect!!!! get cusotmer detail");	
                    const apiCallConfig = {
                        body: { ...request }
                    };
                    const response = yield API.post("auctollo-payment-api", `/payment/checkPaymentStatus`,apiCallConfig);
                    jsonData = response
                }	
            }	
            w3logger("resulting BILLINGDATA res body",jsonData);
            yield put(actions.getPaymentStatus.success(jsonData.data));
            yield window.localStorage.setItem('transactionStatus',jsonData.data)
        } catch(e) {
            yield put(actions.getPaymentStatus.error(e))
        }
    }
}

function* watchGenerateReceipt() {
    while(true){
        const { request } = yield take(actions.GENERATE_RECIEPT.REQUEST);
        let jsonData = null;

        try{
            if(process.env.REACT_APP_MOCK === 'true'){	
                jsonData  = {data: {}};	
            } else {	
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){	
                    w3logger("localhost connect get customer detail");	
                } else {	
                    w3logger("prod connect!!!! get cusotmer detail");	
                    const apiCallConfig = {
                        body: { ...request }
                    };
                    const response = yield API.post("auctollo-users-api", `/users/receiptPDF`,apiCallConfig);
                    jsonData = response
                }	
            }	
            w3logger("resulting Reciept res body",jsonData);
            yield put(actions.generateReceipt.success(jsonData.data));
        } catch(e) {
            yield put(actions.generateReceipt.error(e))
        }
    }
}

function* watchGetAllTransaction() {
    while(true){
        const { request } = yield take(actions.GET_ALL_TRANSACTION.REQUEST);
        let jsonData = null;

        try{
            if(process.env.REACT_APP_MOCK === 'true'){	
                jsonData  = {data: {}};	
            } else {	
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){	
                    w3logger("localhost connect get customer detail");	
                } else {	
                    w3logger("prod connect!!!! get cusotmer detail");	
                    const apiCallConfig = {
                        body: { ...request }
                    };
                    const response = yield API.post("auctollo-payment-api", `/payment/getAllTransaction`,apiCallConfig);
                    jsonData = response
                }	
            }	
            w3logger("resulting BILLINGDATA res body",jsonData);
            yield put(actions.getAllTransaction.success(jsonData.data));
        } catch(e) {
            yield put(actions.getAllTransaction.error(e))
        }
    }
}

function* watchBillingDetail() {	
    while (true) {	
        const { request } = yield take(actions.BILLING_DETAIL.REQUEST);	
        try {	
            let response = {};	
            let responseObject = {};	
            let jsonData = null;
            let res = {}	
            w3logger("About to call the lambda backend for billing info");	
            if(process.env.REACT_APP_MOCK === 'true'){	
                jsonData  = {data: {}};	
            } else {	
                // real call:
               
                w3logger("REACT_APP_API_GATEWAY_URL",REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE)	
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){	
                    // localhost:	
                    w3logger("localhost connect get customer detail");	
                    response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/get-customer-detail`, {method: "GET"});	
                    const responseObject = yield response.json();	
                    jsonData = JSON.parse(responseObject.body);	
                } else {	
                    // staging / prod:	
                    w3logger("prod connect!!!! get cusotmer detail");	
                  
                    const response = yield API.get("auctollo-payment-api", `/payment/get-customer-detail?domain=${request.domain}`);
                    // const responseObject = yield response.json();
                    jsonData = JSON.parse(response.body)
                }	
            }	
            w3logger("resulting BILLINGDATA res body",jsonData);
            yield put(actions.billingDetail.success(jsonData.data));	
        } catch (e) {	
            w3logger("error in watchBILLINGDETAIL",e);	
            w3logger(e);	
            yield put(actions.billingDetail.error(e.message));	
        }	
    }	
}	
function* watchUpdateCard() {	
    while (true) {	
        const { request: { nonce, domain, token,customerId, formData, action } } = yield take(actions.UPDATE_CARD.REQUEST);	
        try {	
            // let response = {};	
            // let responseObject = {};	
            let jsonData = null;	
          
            let reqBody ={"paymentMethodNonce": nonce, domain,token,customerId,formData};	

        const response = yield API.get("auctollo-payment-api", "/payment/update-customer-detail");
                    
            jsonData = JSON.parse(response.body)
            w3logger("resulting json update data:",jsonData);	
            w3logger(jsonData);	
            const { data } = jsonData;	
            
            yield put(actions.updateCard.success(data));	
            yield call(action);	
        } catch (e) {	
            w3logger("dd error:");	
            w3logger(e);	
            yield put(actions.updateCard.error(e.message));	
        }	
    }	
}

function* watchEditCard() {	
    while (true) {	
        const { request: { domain, data, token, type, action } } = yield take(actions.EDIT_CARD.REQUEST);	
        try {	
            let response = {};	
            let responseObject = {};	
            let jsonData = null;	
           
            let reqBody ={domain,data,token,type};
            if(process.env.REACT_APP_MOCK === 'true'){	
                jsonData  = {data: {}};	
            } else {	
                // reall call:	
               
                w3logger("REACT_APP_API_GATEWAY_URL",REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE)	
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){	
                    // localhost:	
                    w3logger("localhost connect edit card");	
                    response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/payment/update-card`, {method: "POST", body: JSON.stringify(reqBody)});	
                    const responseObject = yield response.json();	
                    jsonData = JSON.parse(responseObject.body);	
                } else {	
                    // staging / prod:	
                    w3logger("prod connect!!!! edit card");	
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    const response = yield API.post("auctollo-payment-api", "/payment/update-card",apiCallConfig);
                    // const responseObject = yield response.json();
                    jsonData = JSON.parse(response.body)
            w3logger("resulting BILLINGDATA res body",jsonData);
                }	
            }	
            yield put(actions.editCard.success(jsonData.data));	
            yield call(action);	
        } catch (e) {	
            w3logger("dd error:");	
            w3logger(e);	
            yield put(actions.editCard.error(e.message));	
        }	
    }	
}

function* watchAddCard() {	
    while (true) {	
        const { request: { domain, data, action } } = yield take(actions.ADD_CARD.REQUEST);	
        try {	
            let response = {};	
            let responseObject = {};	
            let jsonData = null;	
           
            let reqBody ={domain,data};
            if(process.env.REACT_APP_MOCK === 'true'){	
                jsonData  = {data: {}};	
            } else {	
                // reall call:	
               
                w3logger("REACT_APP_API_GATEWAY_URL",REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE)	
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){	
                    // localhost:	
                    w3logger("localhost connect add card");	
                    response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/payment/add-card`, {method: "POST", body: JSON.stringify(reqBody)});	
                    const responseObject = yield response.json();	
                    jsonData = JSON.parse(responseObject.body);	
                } else {	
                    // staging / prod:	
                    w3logger("prod connect!!!! add card");	
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    const response = yield API.post("auctollo-payment-api", "/payment/add-card",apiCallConfig);
                    // const responseObject = yield response.json();
                    jsonData = JSON.parse(response.body)
            w3logger("resulting BILLINGDATA res body",jsonData);
                }	
            }	
            yield put(actions.addCard.success(jsonData.data));	
            yield call(action);	
        } catch (e) {	
            w3logger("dd error:");	
            w3logger(e);	
            yield put(actions.addCard.error(e.message));	
        }	
    }	
}

function* watchTransaction() {	
    while (true) {	
        const { request: { domain, transactionId, subscriptionId,type, userEmail, userName, actionSuccess, actionFailure } } = yield take(actions.TRANSACTION.REQUEST);	
        try {	
            let response = {};	
            let responseObject = {};	
            let jsonData = null;	
           
            let reqBody ={domain, transactionId, subscriptionId,type,userEmail,userName};
            if(process.env.REACT_APP_MOCK === 'true'){	
                jsonData  = {data: {}};	
            } else {	
                // reall call:	
               
                w3logger("REACT_APP_API_GATEWAY_URL",REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE)	
                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){	
                    // localhost:	
                    w3logger("localhost connect transaction");	
                    response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/payment/transaction`, {method: "POST", body: JSON.stringify(reqBody)});	
                    const responseObject = yield response.json();	
                    jsonData = JSON.parse(responseObject.body);	
                } else {	
                    // staging / prod:	
                    w3logger("prod connect!!!! transaction");	
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    const response = yield API.post("auctollo-payment-api", "/payment/transaction",apiCallConfig);
                    // const responseObject = yield response.json();
                    jsonData = JSON.parse(response.body)
            w3logger("resulting transaction res body",jsonData);
                }	
            }	
            yield call(actionSuccess, jsonData.data);	
            yield put(actions.transaction.success(jsonData.data));
            yield window.localStorage.setItem('subscriptionCancelled',true)
            yield window.localStorage.removeItem('selectedPlan')
            yield window.localStorage.removeItem('targetPlan')
            // yield call(action);	
        } catch (e) {	
            w3logger("dd error:");	
            window.localStorage.removeItem('subscriptionCancelled')
            w3logger(e);	
            yield call(actionFailure, e);	
        }	
    }	
}

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc flush 
 */
function* watchFlushPermalinks() {
    while (true) {
        const { request } = yield take(actions.FLUSH_PERMALINKS.REQUEST);
        try {
            if (request) {
                let { domain = "", settings, subscriptionType } = request;
                const config = {
                    body: {
                        domain: domain,
                    },
                    response: true,
                };
                let response = {}
                if(isInsideWpAdmin()){
                    w3logger(">>>watchFlushPermalinks - running inside wp-admin");
                    response = yield fetch(`/?rest_route=/sitemap_premium/v1/build_sitemap`, {method: "GET"});
                    const jsonData = yield response.json();
                    w3logger(">>>watchFlushPermalinks - jsonData:",jsonData);
                    w3logger(jsonData);
                    yield put(actions.flushPermalinks.success(jsonData));
                }
            }
        } catch (e) {
            w3logger("Error=>",e);
            yield put(actions.flushPermalinks.error(e));
        }
    }
}

export default [
    fork(watchSaveSettings),
    fork(watchGetSettings),
    fork(watchGetPremiumSettings),
    fork(watchConnectGoogleToSite),
    fork(watchDisconnectGoogleFromSite),
    fork(watchUpdateRobot),
    fork(watchDisplaySubscriptionInfo),
    fork(watchPaymentUpdateInfo),
    fork(watchGetPaymentInfo),	
    fork(watchBillingDetail),	
    fork(watchUpdateCard),	
    fork(watchEditCard),	
    fork(watchAddCard),
    fork(watchTransaction),
    fork(watchFlushPermalinks),
    fork(watchPaymentStatus),
    fork(watchGetAllTransaction),
    fork(watchSetLicenceParams),
    fork(watchGenerateReceipt)
];
