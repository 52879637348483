import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
    receiptLink:""
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Settings Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const receipt = (state = initialState, action) => {
    switch (action.type) {

            case actions.GENERATE_RECIEPT.SUCCESS:
                return Object.assign( {}, state, {
                    loading: false,
                    message: "",
                    receiptLink: action.data
                } );
    
            case actions.GENERATE_RECIEPT.REQUEST:
                return Object.assign( {}, state, {
                    loading: true,
                    message: "",
                    receiptLink: ""
                } );

            case actions.GENERATE_RECIEPT.FAILURE:
                return Object.assign({}, state, {
                    loading: false,
                    message: action.error,
                    receiptLink: ""
                });

        default:
            return state;
    }
};

export default receipt;
