// @flow
import React from "react";

// styles
import styles from "./serviceWorker.module.scss";

export type ServiceWorkerNotifierType = {
    serviceWorkerUpdated: boolean
}

import { useTranslation } from "react-i18next";
const ServiceWorkerNotifier = (
    { serviceWorkerUpdated }: ServiceWorkerNotifierType
) => {
    const { t } = useTranslation();
    return serviceWorkerUpdated ? (
        <div className={styles["modal-wrapper"]}>
            <div className={styles["modal-container"]}>
                <p>{t("There is a newer version of Google XML Sitemaps. Please reload the page to get the latest version.")}</p>
                <a
                    href="javascript:window.location.reload(true)"
                    className={` ${styles["get-started-link"]}`}>
                    {t("Reload")}
                </a>
            </div>
        </div>
    )
        : null;
};

export default ServiceWorkerNotifier;
