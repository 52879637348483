// @flow

import React from "react";
import { Link } from "react-router-dom";
import "../../../../assets/scss/global.scss";
import styles from "./navigation.module.scss";
import { isInsideWpAdmin } from "../../../../helpers/installationFlowHelper";

const Navigation = () => {
    return (
        <ul className={` d-flex list  ${styles["nav-menu"]}`}>
            {/* <li className={styles["list-item"]}>
                <a href="#" className={styles["nav-link"]}>Product</a>
            </li>
            <li className={styles["list-item"]}>
                <a href="#" className={styles["nav-link"]}>Pricing</a>
            </li>
            <li className={styles["list-item"]}>
                <a href="#" className={styles["nav-link"]}>Support</a>
            </li>
            <li className={styles["list-item"]}>
                <a href="#" className={styles["nav-link"]}>Blog</a>
            </li> */}
            <li className={`${styles["list-item"]} mr-2`}>
                <Link to="/sign-in" href="#" className={`${styles["nav-link"]} ${styles["get-started-link"]} ${styles["noOutline"]}`}>Login</Link>
            </li>
            {isInsideWpAdmin()===false &&
                <li className={styles["list-item"]}>
                    <Link to="/installation/app/select-plan" href="#" className={`${styles["nav-link"]} ${styles["get-started-link"]}`}>Get Started</Link>
                </li>
            }
        </ul>
    );
};

export default Navigation;
