import * as actions from "components/installation/actions/clientSite/actions";

const initialState = {
    step: "start",
    targetDomainData: null,
    planData: null,
    paymentData: null,
    siteCreatedData: null,
    createdOwnerCode: false
};

const clientSiteInstallDomain = (state = initialState, action) => {
    switch (action.type) {

        // DOMAIN URL INPUT:
        case actions.INSTALLATION_CLIENT_ENTERED_DOMAIN_URL: {
            return {
                ...state,
                targetDomainData: action.data
            };
        }

        // PRICING PLAN CHOSEN:
        case actions.INSTALLATION_CLIENT_PLAN_CHOSEN: {
            return {
                ...state,
                planData: action.data
            };
        }

        // PRICING PAYMENT COMPLETED:
        case actions.INSTALLATION_CLIENT_PAYMENT_COMPLETED.SUCCESS: {
            return {
                ...state,
                paymentData: action.data
            };
        }

        default:
            return state;
    }
};

export default {clientSiteInstallDomain};
