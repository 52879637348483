// @flow
import React from "react";
import { ButtonPrimary } from "..";
import { CheckIcon } from "../../../assets/icons";
import styles from "./pricingPlanCard.module.scss";
import "../../../assets/scss/global.scss";
import { w3logger } from 'logger'
import type { PricingPlanCardTypes, HandleActionButtonType } from "./PricingPlanCardTypes";
import Skeleton from "react-loading-skeleton";

const ACCOUNT_ACTIVE_TEXT = "Account active";
import { useTranslation } from "react-i18next";

const handleActionButton = ({ clickHandler, type, isAuthenticated, id, plans, push, url }: HandleActionButtonType) => {
    window.localStorage.setItem('selectedPlan',id)
    if (!clickHandler) {
        // acts as a simple link el.
        push({
            pathname: url,
            state: { type: type, id: id }
        });
    } else {
        clickHandler(type, isAuthenticated, plans);
    }

};

const PricingPlanCard = props => {
    const { clickHandler = null, icon, title, type,price,id, subtitle, buttonText, pricingPlan, push, url, isAuthenticated, plans, loading } = props;
    const { t } = useTranslation();
    const isCurrentActive = pricingPlan === type;
    const text = isCurrentActive && isAuthenticated ? ACCOUNT_ACTIVE_TEXT : buttonText;
    let discount = 0, grossPay = 0
    if(plans.plans[0].hasOwnProperty('discounts') && plans.plans[0].discounts.length > 0) {
        plans.plans[0].discounts.map(disc=> discount += parseFloat(disc.amount) )
    }
    if(discount!==0) {
        grossPay = parseFloat(price) - parseFloat(discount)
    }
    // let prem = plans.plans.find(plan => plan.id === 'premium-dev');
    // let premYearly = plans.plans.find(plan => plan.id === 'premium-dev-yearly')
    // localStorage.setItem('yearlyPlanPrice',premYearly.price)
    // localStorage.setItem('planPrice', prem.price);

    return (
        <>
        {loading?
        <div className={` bg-white d-flex with-shadow pricing-card-wrapper ${styles.card}`}>
            <Skeleton height={375} width={250}/>
        </div>
        :
        <div className={` bg-white d-flex with-shadow pricing-card-wrapper ${styles.card}`}>
            {isCurrentActive && isAuthenticated == true &&
                <div className={` d-flex  ${styles["icon-checked"]}`}>
                    <CheckIcon />
                </div>
            }
            <div className={` d-flex ${styles["card-icon"]}`}>
                {icon}
            </div>
            <div className={` ${styles["card-body"]} pricing-body`}>
                <h4 className={` title ${styles["card-title"]}`}>
                    {title}
                </h4>
                <h5 className={` title card-subtitle ${styles["card-subtitle"]}`}>
                    {subtitle}
                </h5>
                <div className={` ${styles["card-action"]} pricing-actions`}>
                    {type == "enterprise" ?
                        <a href="mailto:sales@auctollo.com">
                            <ButtonPrimary
                                buttonText={t(text,text)}
                                buttonSize="large"
                                class={props.class}
                                // action={() => handleActionButton({ type, push, url })}
                                disabled={isCurrentActive}
                                btnCenter="" />
                        </a>
                        :
                        <ButtonPrimary
                            // buttonText={type=="premium" ? plans!==undefined ? plans.plans!==undefined ?  : text : text}
                            discount={discount}
                            buttonText={type=="premium" ? `Get Started -$${price}` : 'Get Started - Free'}
                            // buttonText={type == "premium" ? plans !== undefined ? plans.plans !== undefined ? plans.plans.map((ele, i) => {
                            //     if (ele.id != "test" || ele.type !== "community" ) { return (`Get Started - $${ele.price}`) }
                            // }) : t(text) : t(text) : t(text)}
                            buttonSize="large"
                            class={props.class}
                            action={type == "Premium" && isAuthenticated == false ?
                                () => handleActionButton({ clickHandler, isAuthenticated, id, type, push, url: "/sign-up/target-plan/premium" })
                                : () => handleActionButton({ clickHandler, isAuthenticated, id, type, push, url }, url == '/settings' ? localStorage.setItem('activePayment', true) : '')}
                            disabled={isAuthenticated ? isCurrentActive : false}
                            btnCenter=""
                            planType={type}
                        >
                            {(type=="premium"&&discount!==0) && <>Get Started -${grossPay} <del>${price}</del></>}
                        </ButtonPrimary>
                    }
                </div>
            </div>
        </div>
        }
        </>
    );
};

export default PricingPlanCard;
