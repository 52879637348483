import * as actions from "../actions";
import { w3logger } from 'logger';

const initialState = {
    loading: false,
    message: "",
};

const paymentBraintree = ( state = initialState, action) => {
    switch (action.type) {

        case actions.PAYMENT_BRAINTREE.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error,
            });

        case actions.PAYMENT_BRAINTREE.SUCCESS:
            w3logger("PAYMENT SUCCESS DETECTED!!");
            return Object.assign({}, state, {
                loading: false,
                message: "",
                paymentCompleted: true
            });

        case actions.PAYMENT_BRAINTREE.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });


            case actions.GENERATE_TOKEN.SUCCESS:
            return Object.assign( {}, state, {
                loading: false,
                message: "",
                generatedToken: action.data
            } );

        case actions.GENERATE_TOKEN.REQUEST:
            return Object.assign( {}, state, {
                loading: true,
                message: "",
            } );

            case actions.GENERATE_TOKEN.FAILURE:
                return Object.assign({}, state, {
                    loading: false,
                    message: action.error,
                });
                case actions.CUSTOMER_ID.SUCCESS:
                return Object.assign( {}, state, {
                    loading: false,
                    message: "",
                    customerId: action.data
                } );
    
            case actions.CUSTOMER_ID.REQUEST:
                return Object.assign( {}, state, {
                    loading: true,
                    message: "",
                } );
    
                case actions.CUSTOMER_ID.FAILURE:
                    return Object.assign({}, state, {
                        loading: false,
                        message: action.error,
                    });


        default:
            return state;
    }
};

export default paymentBraintree;
