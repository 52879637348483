//@flow

import React from "react";
//import { cutString, prepareDataToPercent, cutNumber } from "helpers";

import styles from "./table.module.scss";

import type { TableSitemapPropsTypes } from "./TableSitemapPropsTypes";
import { withTranslation } from "react-i18next";

const emptyDataItem = {
    path: ["---------------"],
    types: "---",
    processed: "---",
    issues: "---",
    submited: "---",
    indexed: "---",
};

const emptyData = [emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem, emptyDataItem];

const TableSitemap = (props: TableSitemapPropsTypes) => {
    const { data, t } = props;
    const rowsData = data && data.length > 0 ? data : emptyData;
    return (
        <div className={` ${styles.tableresponsive} `}>
            <table className={`${styles.table}`}>
                <thead>
                    <tr>
                        <th>{t("Site maps")}</th>
                        <th>{t("Types")}</th>
                        <th>{t("Processed")}</th>
                        <th>{t("Issues")}</th>
                        <th>{t("Submited")}</th>
                        <th>{t("Indexed")}</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {rowsData.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.path}</td>
                            <td>{item.type}</td>
                            <td>{item.processed }</td>
                            <td>{item.errors == 0 ? 'Active' : item.errors }</td>
                            <td>{item.submitted}</td>
                            <td>{item.indexed}</td>
                        </tr>
                    );
                })} */}

                    {rowsData.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.path}</td>
                                <td>{item.contents instanceof Array && item.contents.length > 0 && item.contents[0].type}</td>
                                <td>{"---"}</td>
                                <td>{item.issues}</td>
                                <td>{item.contents instanceof Array && item.contents.length > 0 && item.contents[0].submitted}</td>
                                <td>{item.contents instanceof Array && item.contents.length > 0 && item.contents[0].indexed}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default withTranslation()(TableSitemap);
