import React, { Component } from "react";
import Skeleton from 'react-loading-skeleton';
import styles from '../common/components/loaderElement/loaderElement.module.scss';
export default function asyncComponent(importComponent) {
    class AsyncComponent extends Component {
        state = {
            component: null,
            error: null
        };

        componentDidCatch(error) {
            this.setState({ error });
        }

        async componentDidMount() {
            const { default: component } = await importComponent();

            this.setState({
                component: component
            });
        }

        render() {
            const C = this.state.component;

            return C ? <C {...this.props} /> :<></> 
        //      <div className={`${styles["loading-wrapper"]}  align-items-center `}>
        //     <div>
        //         <Skeleton height={50} width={300} count={3} />
        //     </div>
        //     <h2 className={`${styles["loader-text"]}`}>Loading...</h2>
        // </div>;
        }
    }

    return AsyncComponent;
}
