// @flow
import { PATH_LENGTH } from "constants";
import { googleWebmasterHeaderConfig, bingWebmasterHeaderConfig,  googleAnalyticsHeaderConfig} from "components/dashboard/mockedData";
import moment from "moment";
import { w3logger } from 'logger'

type webmasterRequestBody = {
    startDate: string,
    endDate: string,
};

type rowItem = {
    clicks: number,
    ctr: number,
    impressions: number,
    keys: Array<string>,
    position: number,
}

export const cutString = (string: string) => {
    return `${string.slice(0, PATH_LENGTH)}${string.length > PATH_LENGTH ? "..." : ""}`;
};

export const getTotalSum = (data: Array<rowItem>, key: string) => {
    return data.reduce((prev, cur) => prev + cur[key], 0);
};

export const cutNumber = (data: string | number, lengthAfterComma: number = 1) => {
    if (typeof data === "string") return data;
    return Number(data).toFixed(lengthAfterComma);
};

export const prepareDataToPercent = (data: string | number) => {
    if (typeof data === "string") return data;
    return `${Number(data * 100).toFixed(1)} %`;
};

export const prepereBigNumber = (count: number) => {
    if(count < 9999) {
        return count;
    }
    if(count < 1000000) {
        return `${(count/1000).toFixed(1)} K`;
    }
    if(count < 10000000) {
        return `${(count/1000000).toFixed(1)} M`;
    }
    if(count < 1000000000) {
        return `${Math.round(count/1000000)} M`;
    }
    if(count < 1000000000000) {
        return `${Math.round(count/1000000000)} B`;
    }
};

export const preparetotalClick = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "clicks");
    return prepereBigNumber(count);
};

export const prepareaverageViews = (data: Array<rowItem>,startDate,endDate) => {
    var start = moment(startDate, "YYYY-MM-DD");
    var end = moment(endDate, "YYYY-MM-DD");
    var days = moment.duration(end.diff(start)).asDays();
    let count = getTotalSum(data, "impressions");
    count = count/days;
    return prepereBigNumber(count);
};

export const prepareaverageCtr = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "ctr") / data.length;
    return count !== 0 ? `${Number(count * 100).toFixed(1)} %` : "0 %";
};

export const prepareaveragePosition = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "position");
    return count !== 0 ? +Number(count / data.length).toFixed(1) : 0;
};

export const prepareHeaderData = (data: Array<rowItem>,startDate,endDate) => {
    const prepare = { preparetotalClick, prepareaverageViews, prepareaverageCtr, prepareaveragePosition };
    return googleWebmasterHeaderConfig.map(item => {
        item.value = prepare[`prepare${item.type}`](data,startDate,endDate);
        return item;
    });
};

export const preparetotalClicksFromSearch = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "Clicks");
    return prepereBigNumber(count);
};

export const preparetotalPagesCrawled = (data: Array<rowItem>) => {
    let count = getTotalSum(data, "CrawledPages");
    return prepereBigNumber(count);
};
// totalClicksFromSearch
// totalPagesCrawled
export const prepareBingHeaderData = (data: Array<rowItem>,requestBody) => {
    const { startDate, endDate } = requestBody;

    const prepare = { preparetotalPagesCrawled, preparetotalClicksFromSearch };
    if(data.length > 0){
        return bingWebmasterHeaderConfig.map(item => {
            item.value = prepare[`prepare${item.type}`](data,startDate,endDate);
            return item;
        });
    }else{
        return bingWebmasterHeaderConfig;
    }
};

export const prepareHeaderDataForAnalytics = (data: Array<rowItem>,startDate,endDate) => {
    var start = moment(startDate, "YYYY-MM-DD");
    var end = moment(endDate, "YYYY-MM-DD");
    var days = moment.duration(end.diff(start)).asDays();
    return googleAnalyticsHeaderConfig.map((item, index) => {
        switch (item.type) {
            case "totalUniqueViews":
                item.value = data[0] ? data[0] : 0;
                break;
            case "averageViewsDaily":
                item.value = data[1] ? data[1] : 0;
                break;
            case "uniqueViewsDaily":
                item.value = data[0]/days ? (data[0]/days ).toFixed(0): 0;
                break;
        }
        return item;
    });
};

const getRangeName = (date: moment, type: string) => {
    switch (true) {
        case type === "month":
            return `${date.format("MMM")} ${date.format("YYYY")}`;
        case type === "day":
            return `${date.format("DD")} ${date.format("MMM")} ${date.format("YYYY")}`;
    }
};

const getSumTypeByDate = (data: Array<rowItem>, item: { date: string }, type: string) => {
    return data.reduce((prev, cur) => {
        return cur.keys[0].split("-")[1] === item.date.split("-")[1] ?
            prev + cur[type] :
            prev;
    }, 0);
};

export const prepareChartData = (data: Array<rowItem>, requestBody: webmasterRequestBody) => {

     const { startDate, endDate } = requestBody;
    let type = data.length >= 30 ? "month" : "day"; // TODO refactor when implement pagination
    const end = moment(new Date(endDate));
    let listRangeEntities = [];
    let counter = moment(new Date(startDate));
    while( end > counter || counter.format("M") === end.format("M")) {
        listRangeEntities.push({
            date: counter.format("YYYY-MM"),
            "name": getRangeName(counter, type),
            "Clicks": 0,
            "Impressions": 0,
            "CTR": "0.00",
            "Position": "0.00",
        });
        counter.add(1, type);
    }

    listRangeEntities.map(item => {
        item["Clicks"] = getSumTypeByDate(data, item, "clicks");
        item["Impressions"] = getSumTypeByDate(data, item, "impressions");
        item["CTR"] = getSumTypeByDate(data, item, "ctr").toFixed(2);
        item["Position"] = (getSumTypeByDate(data, item, "position") / moment(new Date(item.date)).daysInMonth()).toFixed(2);
        return item;
    });
    return listRangeEntities;
};

const getSumTypeByDateBing = (data: Array<rowItem>, item: { date: string }, type: string) => {
    return data.reduce((prev, cur) => {
        let curDate = cur.Date.replace(/\//g, "");
        curDate.replace(/Date/g,"")
        // w3logger("crawlState::::",cur[type],item.date );
        return moment(new Date()).format("YYYY-MM") === item.date ?
            prev + cur[type] :
            prev;
    }, 0);
};

export const prepareBingChartData = (data: Array<rowItem>, requestBody: webmasterRequestBody) => {
    const { startDate, endDate } = requestBody;
    let type = "month"; // TODO refactor when implement pagination
    const end = moment(new Date(endDate));
    let listRangeEntities = [];
    let counter = moment(new Date(startDate));
    while( end > counter || counter.format("M") === end.format("M")) {
        listRangeEntities.push({
            date: counter.format("YYYY-MM"),
            "name": getRangeName(counter, type),
            "CrawledPages": 0,
            "Clicks": 0,
        });
        counter.add(1, type);
    }

    listRangeEntities.map(item => {
        item["CrawledPages"] = getSumTypeByDateBing(data.GetCrawlStats.d, item, "CrawledPages");
        return item;
    });
    listRangeEntities.map(item => {
        item["Clicks"] = getSumTypeByDateBing(data.GetQueryStats.d, item, "Clicks");
        return item;
    });
    w3logger("crawlState::::",listRangeEntities);

    return listRangeEntities;
};


export const prepareGoogleAnaliticsData = (data: Object, startDate: moment, endDate: moment) => {

    let temp_Arrry = [];
    if(data.reports[0].data.hasOwnProperty('rows')) {
        data.reports[0].data.rows.forEach((Aitem, Aindex) => {
            let res = {};
            data.reports[0].columnHeader.dimensions.forEach((Bitem, Bindex) => {
            Aitem.dimensions.forEach((item, index) => {
                res[Bitem] = item;
            });
            data.reports[0].columnHeader.metricHeader.metricHeaderEntries.forEach((Citem, Cindex) => {
                Aitem.metrics.forEach((Ditem, Dindex) => {
                res[Citem.name] = Ditem.values[Cindex];
                });
            });
            });
            temp_Arrry.push(res);
        });
    }
    
    var dateStart = moment(new Date(startDate));
    var dateEnd = moment(new Date(endDate));

    var timeValues = [];
    while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
    timeValues.push(dateStart.format("YYYYMM"));
    dateStart.add(1, "month");
    }


    let finalData = []
    if(temp_Arrry.length > 0) {
        timeValues.forEach(Aitem=> {
            let res = {}
            let data = temp_Arrry.find(data => data['ga:yearMonth'] === Aitem);
            if(data !== undefined) {
                res.date = moment(new Date(data['ga:yearMonth'].replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,''))).format('MMM YYYY')
                res.uniquePageviews = data['ga:uniquePageviews'];
                res.visitors = data['ga:visitors']
            }  else {
                res.date = moment(new Date(Aitem.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,''))).format('MMM YYYY');
                res.uniquePageviews = "0";
                res.visitors = "0"
            }
            finalData.push(res)
        })
    } else {
        timeValues.forEach(Aitem=> {
            let res = {}
            res.date = moment(new Date(Aitem.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,''))).format('MMM YYYY');
            res.uniquePageviews = "0";
            res.visitors = "0"
            finalData.push(res)
        })
    }
    return finalData;
};

export const prepareSitemapURL = (activeSite: Object, type: String) => {
    const s3RootUrl = "https://1-auctollo-bucket.s3.us-east-2.amazonaws.com";

    if(type == "rss") {
        try {
            if(typeof activeSite.type != "undefined") {
                if(activeSite.type === "premium") {
                    return s3RootUrl + "/hosts/" + activeSite.domain + "/sitemap/index.rss";
                } else {
                    return "https://" + activeSite.domain + "/sitemap.php?rss";
                }
            }
        } catch(error) {
            return "";
        }
    } else if(type == "html") {
        try {
            if(typeof activeSite.type != "undefined") {
                if(activeSite.type === "premium") {
                    return s3RootUrl + "/hosts/" + activeSite.domain + "/sitemap/index.html";
                } else {
                    return "https://" + activeSite.domain + "/sitemap.html";
                }
            }
        } catch(error) {
            return "";
        }
    }
};
