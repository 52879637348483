//@flow

import React, { Fragment } from "react";
import "./../../../assets/scss/global.scss";
import styles from "./heading.module.scss";
import Skeleton from "react-loading-skeleton";
import type { HeadingType } from "./HeadingType.js";
const Heading = ({
  title,
  subtitle,
  align,
  icon,
  onDatesChange,
  selectedDate,
  page,
  isGoogleAuthorized,
  loading
}: HeadingType) => {
  return (
    <Fragment>
      <div className={`${styles["heading"]} ${styles[align]}`}>
        {
          loading === true
          ?
          <Skeleton />
          :
          (
            <>
              {icon} <h1 className={` title ${styles.title}`}> {title} </h1>{" "}
              <p className={styles.subtitle}> {subtitle} </p>{" "}
            </>
          )
        }
      </div>{" "}
    </Fragment>
  );
};

export default Heading;
