import React from "react";

const BingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 24" width="19" height="24">
            <path
                fill="#008373"
                d="M.314 0C2.147.558 3.98 1.12 5.813 1.683c-.005 4.523.005 9.047-.005 13.57-1.781 1.575-3.548 3.164-5.325 4.744 4.34-2.278 8.677-4.566 13.013-6.849-1.2-.548-2.41-1.087-3.61-1.636-.81-1.767-1.63-3.53-2.442-5.292 4.027 1.228 8.049 2.461 12.075 3.694v5.902L5.78 24c-1.823-1.275-3.642-2.555-5.466-3.83V0z"
            />
        </svg>
    );
};

export default BingIcon;
