import React from "react";
import Messages from "UI/messages";
import withUser from "helpers/withUser";
import ServiceWorkerNotifier from "./../serviceWorkerNotifier";
import DebugWindow from "../../../components/debugWindow/";

const dashboardLayout = ({ children }, ) => {
    return (
        <>

            <Messages />
            <ServiceWorkerNotifier />
            {children}
            {/* <DebugWindow/> */}
        </>
    );
};

export default withUser(dashboardLayout);
