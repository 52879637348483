//@flow

import React from "react";
import "../../../assets/scss/global.scss";
import styles from "./label.module.scss";

import type { LabelType } from "./LabelType";

const Label = (props: LabelType) => {
    return (
        <div className={`${styles["label"]} ${styles[props.labelConfig]}  d-flex align-items-center `}>
            {props.labelIcon && props.labelIcon}
            <span>{props.labelText && props.labelText}</span>
        </div>
    );
};

export default Label;
