import React from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import * as actions from "actions/messages";
import { Button, Alert } from "react-bootstrap";
import type { MessagesProps } from "./Types";

const modalComponent = document.getElementById("modal");

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Component which render messages
 */
const renderMessages = ({ message, type, resetMessage }: MessagesProps) => message ? (
    <div className="modal-window">
        <div className="modal-window-container">
            <Alert variant={type}>
                {message}
            </Alert>
            <Button onClick={resetMessage}>Okay</Button>
        </div>
    </div>
) : null;

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Function for render Message component with portal
 */
const Messages = (props: MessagesProps) => {
    return createPortal(renderMessages(props), modalComponent);
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Put state objects to component
 * @param {Object} state - The global state object
 */
const mapStateToProps = ({ messages: { message, type } }) => ({ message, type });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Put actions function to component
 * @param {Function} dispatch - The function which dispatch actions
 */
const mapDispatchToProps = dispatch => ({
    resetMessage: () => dispatch(actions.resetMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
