import { createRequestTypes } from "../../../helpers/constants";

export const SIGN_UP = createRequestTypes("SIGN_UP");
export const CONFIRM_USER = createRequestTypes("CONFIRM_USER");

export const signUp = {
    request: request => ({ type: SIGN_UP.REQUEST, request }),
    success: success  => ({ type: SIGN_UP.SUCCESS, success}),
    error:   error => ({ type: SIGN_UP.FAILURE, error })
};

export const confirmUser = {
    request: request => ({ type: CONFIRM_USER.REQUEST, request }),
    success: response => ({ type: CONFIRM_USER.SUCCESS, response }),
    error:   error => ({ type: CONFIRM_USER.FAILURE, error })
};

