import * as actions from "actions/appState";

const initialState = {
    isRegistered: false
};

/**
 * Global application state:
 * @param state
 * @param action
 * @returns {({} & {isRegistered: boolean} & {userData: *, user: *})|{isRegistered: boolean}}
 */
const appState = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_IS_REGISTERED:
            return Object.assign({}, state, {
                isRegistered: action.data
            });

        default:
            return state;
    }
};

export default appState;
