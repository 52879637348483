import * as actions from "actions/messages";

const initialState = {
    message: "",
    type: "",
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Messages Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const messages = (state = initialState, action) => {
    switch (action.type) {

        case actions.SET_MESSAGE:
            return Object.assign({}, state, {
                message: action.data.message,
                type: action.data.type,
            });
        case actions.RESET_MESSAGE:
            return Object.assign({}, state, {
                message: "",
                type: "",
            });

        default:
            return state;
    }
};

export default messages;
