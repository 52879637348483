import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Settings Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const transactionDetails = (state = initialState, action) => {
    switch (action.type) {

            case actions.GET_ALL_TRANSACTION.SUCCESS:
                return Object.assign( {}, state, {
                    loading: false,
                    message: "",
                    transactionData: action.data
                } );
    
            case actions.GET_ALL_TRANSACTION.REQUEST:
                return Object.assign( {}, state, {
                    loading: true,
                    message: "",
                } );

                case actions.GET_ALL_TRANSACTION.FAILURE:
                    return Object.assign({}, state, {
                        loading: false,
                        message: action.error,
                    });
    
        default:
            return state;
    }
};

export default transactionDetails;
