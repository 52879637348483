// @flow

import { connect } from "react-redux";
import Component from "./TopPanel";
import * as actions from "actions/user";
import * as signUpActions from "components/signupOld/actions";
import * as DashboardActions from 'components/dashboard/actions';
import {logWrite} from "logger";

const mapStateToProps = props => ({
    user:props.user,
    isAuthenticated: props.user.isAuthenticated,
    userData: props.user.userData, 
    activeSite: props.user.activeSite,
    siteCreated: props.settings.siteCreated,
    s3SmUrl: props.settings.s3SmUrl
});

const mapDispatchToProps = dispatch => {
    return {
        changeActiveDomain: data => {
            const cookie_key = "activeDomain"
            document.cookie = `${cookie_key}=${JSON.stringify(data)}; max-age=86400`
            dispatch(actions.setActiveDomain(data));
        },
        logout: () => {
            dispatch(actions.logout());
        },
        getCognitoToken: data => {
            dispatch(DashboardActions.getCognitoToken.request(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
