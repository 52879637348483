import dotenv from "dotenv";
import { actionChannel, put, take, fork, call } from "redux-saga/effects";
import { API, Auth } from "aws-amplify";
import * as startupActions from "actions/startup";
import * as userActions from "actions/user";
import * as cloudSitemapStatusActions from "components/cloudSitemapStatus/actions";
import { getCurrentUrlProtocol, isInsideWpAdmin } from "../helpers/installationFlowHelper";
import { cloudApiCall, wpApiCall } from "../api/apiClient";
import { awsAuth } from "config/offlineData";
import { logError, logWrite } from "logger";
import { getCurrentDomainFromBrowser } from "../helpers/installationFlowHelper";
import { getCloudSitemapIndexUrl } from "../helpers/cloudSitemapsHelper";
import user from "../reducers/user";
import { w3logger } from 'logger'

dotenv.load();

const {
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE
} = process.env;

/***
 * Update WP options, ie: sgp_cloud_sitemaps_mode
 * @returns {IterableIterator<*>}
 * @private
 */
const _updateWpOptions = async ( domain, planName ) => {
    await Auth.currentAuthenticatedUser().then(async user=>{

        const request = {
            relativeUrl: "saveCloudSitemapsMode",
            method: "POST",
            data: {
                enabled: planName === "premium" ? true : false,
                cloud_index_url: planName === "premium" ? getCloudSitemapIndexUrl( domain ) : ""
            }
        };
    const res = await wpApiCall( request );
    return res;
    })
};

/***
 * Helper method. Loads user data from WP DB (if applicable)
 * @returns {IterableIterator<<"CALL", CallEffectDescriptor>|*>}
 * @private
 */
const _loadUserDataFromDb = async () => {
    logWrite( "_loadUserDataFromDb starting..." );

    // read from database:
    const request = {
        relativeUrl: "getTokenPayload",
        method: "POST",
        data: null
    };
    logWrite( "_loadUserDataFromDb calling getTokenPayload..." );

    const tokenPayloadResult = await wpApiCall( request );

    if ( !tokenPayloadResult || !tokenPayloadResult.data ) {
        w3logger( ">>>getCurrentUserAttributes no payload data" );
        return null;
    } else {
        w3logger( ">>>getCurrentUserAttributes - returning data from db:" );
        w3logger( tokenPayloadResult.data );
        return tokenPayloadResult.data;
    }
}

/***
 * Saga for loading user data from wordpress.
 * Basic user data is stored to wordpress, only after premium features are unlocked!
 * @returns {IterableIterator<<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>>}
 */
// function* _loadUserData(userDataOutput = {}) {
//     logWrite( ">>>_loadUserData started" );
//     // const { request } = yield take( startupActions.STARTUP_LOAD_USER_DATA.REQUEST );
//     logWrite( ">>>_loadUserData taken" );
//     try {
//         let userData = null;
//         let source = "";
//
//         if ( process.env.REACT_APP_MOCK === "true" ) {
//             userData = awsAuth;
//         } else {
//
//             if ( isInsideWpAdmin() === true ) {
//                 source = "DB";
//                 logWrite("obtaining dbAtt");
//                 logWrite(typeof _loadUserDataFromDb);
//                 let dbAtt = yield call(_loadUserDataFromDb);
//                 logWrite(dbAtt,"dbAtt");
//
//                 userData = { attributes: dbAtt };
//             } else {
//                 source = "live data";
//                 userData = yield call( [Auth, "currentAuthenticatedUser"]); // "this" format of a call
//             }
//         }
//
//         if(!userData || !userData.attributes){
//             throw new Error("No user data in wp db");
//         }
//
//         logWrite(">>>_loadUserData - setting userdata:");
//         logWrite(userData);
//         yield put( userActions.setUserData( userData ));
//         yield put( userActions.setIsauthenticated( true ));
//         yield put( startupActions.loadUserData.success( userData ) );
//
//         logWrite( ">>>_loadUserData completed. Source=" + source );
//         logWrite(userData, "temp user data");
//         //return userData;
//         userDataOutput.value = userData;
//         logWrite("finished _loadUserData");
//
//     } catch (e) {
//         logWrite( ">>>_loadUserData error:" );
//         logWrite( e );
//         yield put( userActions.setIsauthenticated( false ));
//         yield put( startupActions.loadUserData.error( e.message ) );
//     }
// }

/***
 * Returns user data . Example {{atrributes: {name: "a"}}}
 * @returns {Promise<null|object>}
 * @private
 */
const _loadUserData = async () => {
    logWrite( ">>>_loadUserData started" );

    let userData = null;
    let source = "";

    if ( process.env.REACT_APP_MOCK === "true" ) {
        userData = awsAuth;
    } else {
        if ( isInsideWpAdmin() === true ) {
            source = "DB";
            logWrite( "obtaining dbAtt" );
            logWrite( typeof _loadUserDataFromDb );
            let dbAtt = await _loadUserDataFromDb();
            logWrite( dbAtt, "dbAtt" );

            userData = { attributes: dbAtt };
        } else {
            source = "live data";
            userData = await Auth.currentAuthenticatedUser(); // "this" format of a call
        }
    }

    if ( !userData || !userData.attributes ) {
        throw new Error( "No user data in wp db" );
    }

    logWrite( ">>>_loadUserData - setting userdata:" );
    logWrite( userData );

    logWrite( ">>>_loadUserData completed. Source=" + source );
    logWrite( userData, "temp user data" );
    //return userData;
    return userData;
}


/**
 * Helper method. Extract user sites from cloud API
 * @returns {Promise<*>}
 * @private
 */
const _loadUserDomainsFromApi = async () => {
    //yield put(userActions.setDomainsData(response.data));
    ///response = yield API.get("auctollo", "/site/get-sites");
    const request = {
        relativeUrl: "site/get-sites",
        apiStack: "auctollo",
        data: {},
        method: "GET"
    };
    return cloudApiCall( request );
}

/***
 * Loads user domains
 * @returns {IterableIterator<<"PUT", PutEffectDescriptor<*|void>>|<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>>}
 * @private
 */
// function* _loadUserDomainsData() {
//     logWrite( ">>>loadUserDomains data started" );
//     //const { request } = yield take( startupActions.STARTUP_LOAD_DOMAINS_DATA.REQUEST );
//     logWrite( ">>>loadUserDomains data taken" );
//     try {
//         let domainsData = null;
//         let activeDomain = null;
//         let source = "";
//
//         if ( process.env.REACT_APP_MOCK === "true" ) {
//             domainsData = [];
//         } else {
//             domainsData = (yield call(_loadUserDomainsFromApi)).data;
//         }
//
//         logWrite(">>>_loadUserDomainsData - got domains data:");
//         logWrite(domainsData);
//
//         if(!domainsData || domainsData.length === 0){
//             logWrite(">>>_loadUserDomainsData exiting - no domains found for the user");
//             return false;
//         }
//
//         if(domainsData && domainsData.length>0){
//             // to do - find correct / matching domain here:
//
//             // look for the current domain in set of user domains:
//             //let [activeDomain,rest] = domainsData;
//
//
//             // todo: read the domain from backend:
//             let browserDomain = getCurrentDomainFromBrowser();
//
//             if(isInsideWpAdmin() === true){
//                 domainsData.forEach(element => {
//                     if(browserDomain === element.domain){
//                         activeDomain = element;
//                     }
//                 });
//
//                 if(!activeDomain){
//                     activeDomain = {
//                         "domain": browserDomain,
//                         "type": "non-registered"
//                     };
//                     domainsData.push(activeDomain);
//                 }
//
//             } else {
//                 // app site, pick first:
//                 let otherDomains = null;
//                 ([activeDomain, otherDomains] = domainsData);
//             }
//
//             yield put( userActions.setDomainsData( domainsData ));
//             yield put( startupActions.loadDomainsData.success( domainsData ) );
//
//             // alert("active domain name=" + activeDomain.domain);
//
//             if(isInsideWpAdmin() === true && !activeDomain){
//                 alert("Error: The domain " + browserDomain + " is not listed in user's domains and couldn't be added!");
//                 throw new Error("The domain " + browserDomain + " is not listed in user's domains and couldn't be added");
//             }
//
//
//             yield put(userActions.setActiveDomain(activeDomain));
//
//             // update settings in wp db, according to the seen domain state:
//             if(isInsideWpAdmin()){
//                 yield* _updateWpOptions(activeDomain.domain, activeDomain.type);
//             }
//
//             logWrite( ">>>loadUserDomains - set active domain:");
//             logWrite(activeDomain);
//         }
//
//         logWrite( ">>>loadUserDomains data completed. Source=" + source );
//         yield {activeDomain, domainsData};
//
//     } catch (e) {
//         logWrite( ">>>loadUserDomains data error:" );
//         logWrite( e );
//         yield put( startupActions.loadDomainsData.error( e.message ) );
//     }
// }

/**
 * Load domains for the user...
 * @returns {Promise<{domainsData: null, dynamicWpDomainCreated: boolean, activeDomain: null}|boolean>}
 * @private
 * @requires user to be loaded prior to calling
 */
const _loadUserDomainsData = async () => {
    w3logger("OK!! we are gonna call the cloud functions to get domains data");
    let domainsData = [];
    let activeDomain = {};
    let dynamicWpDomainCreated = false;

    if ( process.env.REACT_APP_MOCK === "true" ) {
        domainsData = [];
    } else {
        domainsData = ( await _loadUserDomainsFromApi() ).data;
    }


    if ( !Array.isArray( domainsData ) ) {
        throw new Error( "domainsData is not an array!" );
    }

    logWrite( ">>>loadUserDomains data completed." );
    return domainsData;
}

/***
 * Inside wp, dynamically create temporary site entry...
 * @param currentDomain
 * @param currentProtocol
 * @param domainsData
 * @returns {*[]|*}
 * @private
 */
const _createDynamicWpSiteEntryMaybe = ( currentDomain, currentProtocol, domainsData ) => {
    // todo: read the domain from backend:
    let found = false;
    if ( isInsideWpAdmin() === true ) {
        domainsData.forEach( element => {
            if ( currentDomain === element.domain ) {
                found = true;
            }
        } );

        if ( found === false ) {
            let activeDomain = {
                "domain": currentDomain,
                "protocol": currentProtocol,
                "type": "non-registered"
            };
            domainsData.push( activeDomain );
        }
        return domainsData;
    } else {
        return [];
    }
};

/***
 * Set default active site...
 * @param currentDomain
 * @param domainsData
 * @returns {{}}
 * @private
 */
const _setDefaultActiveSite = ( currentDomain, domainsData ) => {

    if ( !domainsData || domainsData.length === 0 ) {
        return {};
    }

    let activeDomain = {};
    domainsData.forEach( element => {
        if ( currentDomain === element.domain ) {
            activeDomain = element;
        }
    } );

    // for app site:
    if ( isInsideWpAdmin() === false && !activeDomain.type ) {
        activeDomain = domainsData[ 0 ];
    }

    return activeDomain;
};

/**
 * Central saga in this file. Loads user data first and looks for their domains using AWS API.
 * @returns {IterableIterator<IterableIterator<*|<"PUT", PutEffectDescriptor<*>>|<"PUT", PutEffectDescriptor<*>>|<"PUT", PutEffectDescriptor<*>>>|IterableIterator<<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>|<"PUT", PutEffectDescriptor<*>>|<"PUT", PutEffectDescriptor<*>>|<"PUT", PutEffectDescriptor<*>>>|<"TAKE", TakeEffectDescriptor>>}
 */
function* loadUserAndDomains() {
    logWrite( ">>>loadUserAndDomains started" );
    while (true) {
        try {
            const { request } = yield take( startupActions.STARTUP_LOAD_USER_AND_DOMAINS_DATA.REQUEST );
            logWrite( ">>>loadUserAndDomains taken" );
            let retUserData = null;
            let domainsData = [];
            let activeDomain = {};

            try {
                retUserData = yield call( _loadUserData );
                if ( retUserData && retUserData.attributes ) {
                    yield put( userActions.setUserData.success( retUserData ) );
                    yield put( userActions.setIsauthenticated( true ) );
                } else {
                    throw new Error( "User not found in db / live session" );
                }
            } catch (loadUserError) {
                yield put( userActions.setUserData.success( null ) );
                yield put( userActions.setIsauthenticated( false ) );
                logError( loadUserError, "Error while executing _loadUserData()" );
            }

            logWrite( retUserData, ">>>loadUserAndDomains - after user data:" );

            if ( !retUserData || !retUserData.attributes ) {
                // no user detected...
                logWrite( ">>>loadUserAndDomains - no user detected. skipping user domains loading." )
            } else {
                // there is a user:
                domainsData = yield call( _loadUserDomainsData );
                logWrite( ">>>loadUserAndDomains - completed" );
            }

            w3logger("OK!! we got domains data: ", domainsData);
            let currentDomain = getCurrentDomainFromBrowser();
            let currentProtocol = getCurrentUrlProtocol();

            // see if a dynamic wp site needs to be created:
            if ( isInsideWpAdmin() === true ) {
                domainsData = _createDynamicWpSiteEntryMaybe( currentDomain, currentProtocol, domainsData );
            }

            // choose active site:
            activeDomain = _setDefaultActiveSite( currentDomain, domainsData );

            logWrite( activeDomain, "selected activeDomain:" );

            if ( activeDomain && domainsData && domainsData.length > 0 ) {
                yield put( userActions.setDomainsData( domainsData ) );
                yield put( userActions.setActiveDomain( activeDomain ) );
            } else {
                yield put( userActions.setDomainsData( [] ) ); //setting null here would lead to unstable state of the dashboard jsx component (e is null error)
                yield put( userActions.setActiveDomain( {} ) );
            }

            logWrite( "---dispatching success!" );
            if ( isInsideWpAdmin() ) {
                yield call( _updateWpOptions, activeDomain.domain, activeDomain.type );
            }

            const { domain } = activeDomain;

            if ( activeDomain.type === "premium" ) {
                const delay = time => new Promise(resolve => setTimeout(resolve, time));
                yield call(delay, 5000);
                
                yield put( cloudSitemapStatusActions.loadCloudSitemapStatus.request( { domain } ) );
            }

            yield put( startupActions.loadUserAndDomainsData.success( { data: "OK" } ) );
        } catch (err) {
            logError( err, "There was a critical error in loadUserAndDomains" );
            yield put( startupActions.loadUserAndDomainsData.error( err.message ) );
        }
    }
}

function start() {
    const forks = [
        fork( loadUserAndDomains )
    ];

    return forks;
}

export default start();
