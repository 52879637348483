//@flow
import React from "react";
import type { InputComponentTypes } from "./inputComponentTypes";
import styles from "./input.module.scss";

const Input = ({ type, placeholder, onChange, name, id }:InputComponentTypes) => {
    return (
        <input type={type} placeholder={placeholder} className={styles.input} onChange={onChange} name={name} id={id}/>
    );
};

export default Input;
