import * as actions from "../../actions";

const initialState = {
    loading: false,
    signInError: "",
 
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Sign Up Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const signIn = (state = initialState, action) => {
    switch (action.type) {

        case actions.SIGN_IN.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                signInError: action.error,
            });

        case actions.SIGN_IN.SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                signInError: "",
            });

        case actions.SIGN_IN.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                signInError: "",
            });

        case actions.SIGN_IN.SIGN_UP_REDIRECT:
            return Object.assign({}, state, {
                loading: false,
                signInError: "",
            });

        case actions.SIGN_IN.SET_LOADER_STATUS:
            return Object.assign({}, state, {
                loading: action.data,
                signInError: "",
            });

        default:
            return state;
    }
};

export default {signIn};
