// @flow
import React from "react";
import "../../../assets/scss/global.scss";
import styles from "./buttonOutline.module.scss";
import { Spinner } from "reactstrap";

import type { ButtonOutlineType } from "./buttonOutlineType";

const ButtonOutline = (props: ButtonOutlineType) => {
    const { buttonText, action, hasActivatedSite, loading, buttonSize, disabled } = props;
    return (
        <button
            className={` d-flex ${styles.button}`}
            type="button"
            onClick={action}
            disabled={loading === true || disabled === true || hasActivatedSite==false ? true : false}
        >
            {loading == true ?
            <Spinner
                color="info"
                size="sm"
            // style={{ width: '2rem', height: '2rem' }}
            />
            :
            <>
              
                {/* <span> */}
                    {buttonText}
                {/* </span> */}
            </>

        }
            {/* {buttonText} */}
        </button>
    );
};

export default ButtonOutline;
