import { createRequestTypes } from "helpers/constants";

export const AUTH_GET_TOKEN = createRequestTypes("AUTH_GET_TOKEN");
export const AUTH_SET_TOKEN = createRequestTypes("AUTH_SET_TOKEN");
export const SET_DATE_RANGE = createRequestTypes("SET_DATE_RANGE");


export const AUTH_SET_REFRESH_TOKEN = createRequestTypes("AUTH_SET_REFRESH_TOKEN");
export const SET_COGNITO_TOKEN = createRequestTypes("SET_COGNITO_TOKEN");
export const GET_COGNITO_TOKEN = createRequestTypes("GET_COGNITO_TOKEN");
export const SET_VERIFIED_FLAG = createRequestTypes("SET_VERIFIED_FLAG");
export const GET_PING_INFO = createRequestTypes("GET_PING_INFO");

export const GENERATE_SITE_MAP = createRequestTypes("GENERATE_SITE_MAP");
export const GET_BING_WEBMASTER_DATA = createRequestTypes("GET_BING_WEBMASTER_DATA");
export const GET_GOOGLE_WEBMASTER_DATA = createRequestTypes("GET_GOOGLE_WEBMASTER_DATA", {
    SUCCESS_HEADER_DATA: "SUCCESS_HEADER_DATA",
    SUCCESS_CHART_DATA: "SUCCESS_CHART_DATA",
    SUCCESS_ROWS_DATA: "SUCCESS_ROWS_DATA",
});

export const GET_GOOGLE_ANALYTICS_DATA = createRequestTypes("GET_GOOGLE_ANALYTICS_DATA", {
    SUCCESS_HEADER_DATA: "SUCCESS_HEADER_DATA",
    SUCCESS_CHART_DATA: "SUCCESS_CHART_DATA",
    SUCCESS_ROWS_DATA: "SUCCESS_ROWS_DATA",
});


export const GET_SITEMAP_DATA = createRequestTypes("GET_SITEMAP_DATA", {
    SUCCESS_HEADER_DATA: "SUCCESS_HEADER_DATA",
    SUCCESS_CHART_DATA: "SUCCESS_CHART_DATA",
    SUCCESS_ROWS_DATA: "SUCCESS_ROWS_DATA",
});

export const SET_NOTIFY_ENGINES = createRequestTypes("SET_NOTIFY_ENGINES");


/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Get token actions
 */
export const authGetToken = {
    request: request => ({ type: AUTH_GET_TOKEN.REQUEST, request }),
    success: response => ({ type: AUTH_GET_TOKEN.SUCCESS, response }),
    error:   error => ({ type: AUTH_GET_TOKEN.FAILURE, error })
};


export const setGoogleAuthToken = {
    request: request => ({ type: AUTH_SET_TOKEN.REQUEST, request }),
    success: response => ({ type: AUTH_SET_TOKEN.SUCCESS, response }),
    error:   error => ({ type: AUTH_SET_TOKEN.FAILURE, error })
};

export const setCognitoToken = {
    request: request => ({ type: SET_COGNITO_TOKEN.REQUEST, request }),
    success: response => ({ type: SET_COGNITO_TOKEN.SUCCESS, response }),
    error:   error => ({ type: SET_COGNITO_TOKEN.FAILURE, error })
};

export const getCognitoToken = {
    request: request => ({ type: GET_COGNITO_TOKEN.REQUEST, request }),
    success: response => ({ type: GET_COGNITO_TOKEN.SUCCESS, response }),
    error:   error => ({ type: GET_COGNITO_TOKEN.FAILURE, error })
};

export const setDateRange = {
    request: request => ({ type: SET_DATE_RANGE.REQUEST, request }),
    success: response => ({ type: SET_DATE_RANGE.SUCCESS, response }),
};

export const setRefreshToken = {
    request: request => ({ type: AUTH_SET_REFRESH_TOKEN.REQUEST, request }),
    success: response => ({ type: AUTH_SET_REFRESH_TOKEN.SUCCESS, response }),
    error:   error => ({ type: AUTH_SET_REFRESH_TOKEN.FAILURE, error })
};

export const generateSiteMap = {
    request: request => ({ type: GENERATE_SITE_MAP.REQUEST, request }),
    success: response => ({ type: GENERATE_SITE_MAP.SUCCESS, response }),
    error:   error => ({ type: GENERATE_SITE_MAP.FAILURE, error })
};

export const getBingWebmasterData = {
    request: request => ({ type: GET_BING_WEBMASTER_DATA.REQUEST, request }),
    success: response => ({ type: GET_BING_WEBMASTER_DATA.SUCCESS, response }),
    error:   error => ({ type: GET_BING_WEBMASTER_DATA.FAILURE, error })
};

export const getGoogleWebmasterData = {
    request: request => ({ type: GET_GOOGLE_WEBMASTER_DATA.REQUEST, request }),
    success: response => ({ type: GET_GOOGLE_WEBMASTER_DATA.SUCCESS, response }),
    successHeaderData: response => ({ type: GET_GOOGLE_WEBMASTER_DATA.SUCCESS_HEADER_DATA, response }),
    successChartData: response => ({ type: GET_GOOGLE_WEBMASTER_DATA.SUCCESS_CHART_DATA, response }),
    successRowsData: response => ({ type: GET_GOOGLE_WEBMASTER_DATA.SUCCESS_ROWS_DATA, response }),
    error:   error => ({ type: GET_GOOGLE_WEBMASTER_DATA.FAILURE, error })
};


export const getGoogleAnalyticsData = {
    request: request => ({ type: GET_GOOGLE_ANALYTICS_DATA.REQUEST, request }),
    success: response => ({ type: GET_GOOGLE_ANALYTICS_DATA.SUCCESS, response }),
    successHeaderData: response => ({ type: GET_GOOGLE_ANALYTICS_DATA.SUCCESS_HEADER_DATA, response }),
    successChartData: response => ({ type: GET_GOOGLE_ANALYTICS_DATA.SUCCESS_CHART_DATA, response }),
    successRowsData: response => ({ type: GET_GOOGLE_ANALYTICS_DATA.SUCCESS_ROWS_DATA, response }),
    error:   error => ({ type: GET_GOOGLE_ANALYTICS_DATA.FAILURE, error })
};

export const getSitemapData = {
    request: request => ({ type: GET_SITEMAP_DATA.REQUEST, request }),
    success: response => ({ type: GET_SITEMAP_DATA.SUCCESS, response }),
    successHeaderData: response => ({ type: GET_SITEMAP_DATA.SUCCESS_HEADER_DATA, response }),
    successChartData: response => ({ type: GET_SITEMAP_DATA.SUCCESS_CHART_DATA, response }),
    successRowsData: response => ({ type: GET_SITEMAP_DATA.SUCCESS_ROWS_DATA, response }),
    error:   error => ({ type: GET_SITEMAP_DATA.FAILURE, error })
};

export const setVerifiedFlag = {
    request: request => ({ type: SET_VERIFIED_FLAG.REQUEST, request }),
    success: response => ({ type: SET_VERIFIED_FLAG.SUCCESS, response }),
    error:   error => ({ type: SET_VERIFIED_FLAG.FAILURE, error })
};

export const notifySearchEnginesViaPings = {
    request: request => ({ type: SET_NOTIFY_ENGINES.REQUEST, request }),
    success: response => ({ type: SET_NOTIFY_ENGINES.SUCCESS, response }),
    error:   error => ({ type: SET_NOTIFY_ENGINES.FAILURE, error })
};

export const getPingInfo = {
    request: request => ({ type: GET_PING_INFO.REQUEST, request })
}
