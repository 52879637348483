// @flow
import React from "react";
import { LoaderElement } from "common/components";

export type LoaderPropsType = {
    isLoading: boolean,
    error: {
        message: string
    }
}
import { useTranslation } from "react-i18next";

const Loader = ({ isLoading, error }: LoaderPropsType) => {
    const { t } = useTranslation();
    // Handle the loading state
    if (isLoading) {
        return (
            <LoaderElement />
        );
    } else if (error) {
    // Handle the error state
        return <div>{t("Sorry, there was a problem loading the page.")}</div>;
    } else {
        return null;
    }
};

export default Loader;
