import { connect } from "react-redux";
import Component from "./Component";
import * as actions from "./actions";

const mapStateToProps = state => ({
    userData: state.user.userData,
    activeSite: state.user.activeSite,
    cloudSitemapStatus: state.user.cloudSitemapStatus
});

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Put actions function to component
 * @param {Function} dispatch - The function which dispatch actions
 */
const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
