import { createRequestTypes } from "../../../helpers/constants";

export const ACTIVATE_SITE = createRequestTypes("ACTIVATE_SITE");

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Activate site actions
 */
export const activateSite = {
    request: request => ({ type: ACTIVATE_SITE.REQUEST, request }),
    success: () => ({ type: ACTIVATE_SITE.SUCCESS }),
    error:   error => ({ type: ACTIVATE_SITE.FAILURE, error })
};
