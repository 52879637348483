import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
    data: {},
    google: {}
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Auth reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const auth = (state = initialState, action) => {
    switch (action.type) {

        case actions.AUTH_GET_TOKEN.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error.message,
            });

        case actions.AUTH_GET_TOKEN.SUCCESS:
            return Object.assign({}, state, {
                data: action.response,
                loading: false,
                message: "",
            });

        case actions.AUTH_GET_TOKEN.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });

        case actions.AUTH_SET_TOKEN.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error.message,
            });

        case actions.AUTH_SET_TOKEN.SUCCESS:
            return Object.assign({}, state, {
                google: action.response,
                loading: false,
                message: "",
            });

        case actions.AUTH_SET_TOKEN.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
        });

        default:
            return state;
    }
};

export default auth;
