//@flow

import React from "react";
import { SettingsIcon } from "../../../assets/icons";
import "../../../assets/scss/global.scss";
import styles from "./sectionHeading.module.scss";

import type { SectionHeadingType } from "./SectionHeadingType";

const SectionHeading = (props: SectionHeadingType) => {
    return (
        <div className={` d-flex justify-content-between align-items-center ${styles["section-heading"]}`}>
            <h3 className={` title  ${styles.title}`}>{props.title}</h3>
            {/* <a href="#" className={`${styles["settings-link"]} d-flex align-items-center `}>
                <SettingsIcon />
                <span>Settings</span>
            </a> */}
        </div>
    );
};

export default SectionHeading;
