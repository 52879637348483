// @flow

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ButtonPrimary, Logo, ButtonOutline } from "..";
import Navigation from "./navigation/Navigation";
import Dropdown from "./dropdown/Dropdown";
import "../../../assets/scss/global.scss";
import styles from "./topPanel.module.scss";
import "./topPanel.scss";
import Amplify, { Auth } from "aws-amplify";

import type { TopPanelPropType, TopPanelStateType } from "./TopPanelType.js";
import { call } from "@redux-saga/core/effects";
import http from "../../../api";
import { logWrite } from "../../../logger";
import { backendCloudApiCall, frontendCloudApiCall, cloudApiCall } from "../../../api/apiClient";
import { w3logger } from 'logger'
import { isInsideWpAdmin } from "../../../helpers/installationFlowHelper";
import { withTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
const {
    REACT_APP_AUTOGENERATE_USER_FOR_QUICK_WP_UPGRADE,
    REACT_APP_SIMULATE_LOCAL_WP_ADMIN,
    REACT_APP_LOCAL_WP_ADMIN_URL
} = process.env;

class TopPanel extends Component<TopPanelPropType, TopPanelStateType> {
    state = {
        mobileNav: false,
    }
    logout = () => {
        Auth.signOut( { global: true } )
            .then( data => w3logger( data ) )
            .catch( err => w3logger( err ) );
        this.props.logout();
        if(isInsideWpAdmin()==true){
            let data = {domain:`${window.location.host}`,removeToken:true}
            this.props.getCognitoToken(data);

        }
        localStorage.clear();
    }

    toggleMobileNav = () => this.setState( { mobileNav: !this.state.mobileNav } );

    handleQuickUpgradeClick = (history,id) => {
        const { isAuthenticated, t } = this.props || {};

        if ( REACT_APP_AUTOGENERATE_USER_FOR_QUICK_WP_UPGRADE === "true" ) {
            alert( t("Note: Rect app is running locally, so the test user will be automatically generated now.","Note: Rect app is running locally, so the test user will be automatically generated now.") );
            alert(t("This flow is obsolete!","This flow is obsolete!"));

            //history.push( "/payment-braintree" );
        } else if ( isAuthenticated ) {
            // already signed in
            history.push( "/installation/cs/select-plan" );
            //history.push("/installation/cs/create-site")
            //history.push( "/signup/wp/create-premium" );
        } else {
            // need to create account first
            if(isInsideWpAdmin()){
                history.push( "/installation/cs/select-plan" );
            } else {
                history.push( "/installation/app/select-plan" );
            }
            // history.push( "/installation/cs/select-plan" );
            // history.push("/installation/cs/create-site");
        }
    };

    render() {
        const { user, isAuthenticated, userData, history, activeSite, t, siteCreated, s3SmUrl, loading } = this.props || {};
        let topRightCorner = {};
        let message = <div>&nbsp;</div>;
        const thisComponent = this;
        let propsLoading = (this.props.readyAppLoading || this.props.siteInfoLoaded || this.props.userDetailsLoaded)
        if ( activeSite && isInsideWpAdmin() && !isAuthenticated ) {
            // non-registered (i.e. WP default):
            topRightCorner = <div>
                <ul className={` d-flex list ${styles[ "nav-menu" ]}`}>
                    { ( !loading && siteCreated && typeof s3SmUrl === 'string' && s3SmUrl!=='') &&
                    <li className={styles[ "list-item" ]}>
                        <ButtonOutline buttonText={t("Login","Login")}

                                       buttonSize="medium"
                                       action={() => history.push( "/sign-in" )}
                                       btnCenter=""/>
                    </li>
                    }
                    {
                        ( !loading && siteCreated && (typeof s3SmUrl !== 'string' || s3SmUrl==='')) &&
                        <>
                         <li className={styles[ "list-item" ]}>
                        <ButtonOutline buttonText={t("Login","Login")}

                                       buttonSize="medium"
                                       action={() => history.push( "/sign-in" )}
                                       btnCenter=""/>
                    </li>
                    <li className={`styles[ "list-item" ] mr-2`}>
                        &nbsp;
                    </li>
                    <li className={styles[ "list-item" ]}>
                        <ButtonPrimary buttonText={t("Go Premium","Go Premium")}
                                       buttonSize="medium"
                                       class={'go_premium'}
                                       action={() => history.push( "/sign-in" )}
                                       btnCenter=""/>
                    </li>
                        </>
                    }
                    { ( !loading && !siteCreated) &&
                    <li className={styles[ "list-item" ]}>
                        <ButtonPrimary buttonText={t("Go Premium","Go Premium")}
                                       buttonSize="medium"
                                       class={'go_premium'}
                                       action={() => this.handleQuickUpgradeClick( history,1 )}
                                       btnCenter=""/>
                    </li>}
                </ul>
            </div>;
        } else if ( isAuthenticated ) {
            // it has the account:
            topRightCorner =
                <Dropdown handleLogout={this.props.isAuthenticated ? this.props.handleLogout : () => this.logout()}
                          user={user}/>
        } else {
            // standard navigation (Login and Get Started):
            topRightCorner = <Navigation/>;
        }

        return (
            <div className={window.location.hash == "#/dashboard" ? '' : "fixed"
            }>
                <div className={` bg-white header-shadow  ${styles[ "panel" ]}`}>
                    <div className={` d-flex justify-content-between align-items-center ${styles[ "panel-content" ]}`}>
                        <div className={` d-flex ${styles[ "panel-left" ]}`}>
                            <Logo {...this.props} propsLoading={propsLoading} />
                            <h4 className={` d-flex title ${styles[ "panel-title" ]}`}>
                                {message}
                            </h4>
                        </div>
                        <div className={` d-flex ${styles[ "panel-right" ]}`}>
                            <div className={`${styles[ "toggle-nav" ]} ${styles[ this.state.mobileNav && "active" ]}`}
                                 onClick={this.toggleMobileNav}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className={`${styles[ "nav-wrapper" ]} ${styles[ this.state.mobileNav && "open" ]}`}>
                                {topRightCorner}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter( withTranslation()(TopPanel) );
