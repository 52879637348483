import * as actions from "../actions";

const initialState = {
    loading: false,
    transactionLoading:false,
    addCardLoading:false,
    message: "",
    result:'',
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Settings Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const billingDetail = (state = initialState, action) => {
    switch (action.type) {

        case actions.BILLING_DETAIL.SUCCESS:
        return Object.assign( {}, state, {
            loading: false,
            message: "",
            paymentData: action.data
        } );

        case actions.BILLING_DETAIL.REQUEST:
            return Object.assign( {}, state, {
                loading: true,
                message: "",
            } );

            case actions.BILLING_DETAIL.FAILURE:
                return Object.assign({}, state, {
                    loading: false,
                    message: action.error,
                });

                case actions.UPDATE_CARD.SUCCESS:
                return Object.assign( {}, state, {
                    loading: false,
                    message: "",
                    paymentData: action.data
                } );
        
                case actions.UPDATE_CARD.REQUEST:
                    return Object.assign( {}, state, {
                        loading: true,
                        message: "",
                    } );
        
                    case actions.UPDATE_CARD.FAILURE:
                        return Object.assign({}, state, {
                            loading: false,
                            message: action.error,
                        });

                        case actions.EDIT_CARD.SUCCESS:
                        return Object.assign( {}, state, {
                            editCardLoading: false,
                            message: "",
                            paymentData: action.data
                        } );
                
                        case actions.EDIT_CARD.REQUEST:
                            return Object.assign( {}, state, {
                                editCardLoading: true,
                                message: "",
                            } );
                
                        case actions.EDIT_CARD.FAILURE:
                            return Object.assign({}, state, {
                                editCardLoading: false,
                                message: action.error,
                            });

                        case actions.ADD_CARD.SUCCESS:
                            return Object.assign( {}, state, {
                                addCardLoading: false,
                                message: "",
                                paymentData: action.data
                            } );
                    
                            case actions.ADD_CARD.REQUEST:
                                return Object.assign( {}, state, {
                                    addCardLoading: true,
                                    message: "",
                                } );
                    
                            case actions.ADD_CARD.FAILURE:
                                return Object.assign({}, state, {
                                    addCardLoading: false,
                                    message: action.error,
                                });

                                case actions.TRANSACTION.SUCCESS:
                                    return Object.assign( {}, state, {
                                        transactionLoading: false,
                                        message: action.data.message,
                                        paymentData: action.data.customer,
                                        result:action.data.res
                                    } );
                            
                                    case actions.TRANSACTION.REQUEST:
                                        return Object.assign( {}, state, {
                                            transactionLoading: true,
                                            message: "",
                                        } );
                            
                                    case actions.TRANSACTION.FAILURE:
                                        return Object.assign({}, state, {
                                            transactionLoading: false,
                                            message: action.error,
                                        });
        


        default:
            return state;
    }
};

export default billingDetail;
