import { put, take, fork, call } from "redux-saga/effects";
import { Auth, API } from "aws-amplify";
import * as actions from "./actions";
import { GoogleApiHelper } from "helpers/index";
import * as clientSiteActions from "components/installation/actions/clientSite/actions";
import * as startupActions from "actions/startup";
import * as userActions from "actions/user";
import { w3logger } from 'logger';
import dotenv from "dotenv";

import { logWrite } from "../../../logger";
import http from "../../../api";
import { getCurrentUrlProtocol } from "helpers/installationFlowHelper";
import { cloudApiCall, wpApiCall } from "api/apiClient";
import { STARTUP_LOAD_USER_AND_DOMAINS_DATA } from "actions/startup";

dotenv.load();
const {
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_SITES_API_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE
} = process.env;

/***
 * Executed in wp-admin
 * @param request
 * @returns {Generator<null|<"CALL", CallEffectDescriptor>, null, null>}
 */
function* createDomainOwnerCode( request ) {
    logWrite( ">>> saga createDomainOwnerCode started..." );
    const { activeSite } = request;
    const { domain } = activeSite;

    logWrite( "request provided:" );
    logWrite( request );

    if ( !domain ) {
        logWrite( ">>> saga createDomainOwnerCode  - empty field - domain" );
        yield null;
        return null;
    }

    let jsonData = null;
    try {
        if ( process.env.REACT_APP_MOCK === 'true' ) {
            jsonData = { data: {} };
        } else {
            // real call:

            let protocol = getCurrentUrlProtocol();
            if ( [ "http", "https" ].indexOf( protocol ) < 0 ) {
                throw new Error( "Invalid protocol provided in csCreateAndConfirmSite saga" );
            }
            let reqBody = { domain, protocol };

            const callRequest = {
                relativeUrl: "sites/createDomainOwnerCode",
                apiStack: "auctollo-sites-api",
                data: reqBody,
                method: "POST"
            };

            jsonData = yield call( cloudApiCall, callRequest );
            jsonData = jsonData.data;

            w3logger( "json data:" );
            w3logger( jsonData );

            logWrite( "yielding owner code:" );
            logWrite( jsonData.ownerCode );
            return jsonData;
        }
    } catch (e) {
        logWrite( "Error in createDomainOwnerCode::" );
        logWrite( e );
        throw e;
    }
}

/***
 * Executed in Wordpress
 * Store owner code to WP DB
 * @param request
 * @param ownerCode
 * @returns {IterableIterator<*>}
 */
function* storeDomainOwnerCode( request, ownerCode ) {
    logWrite( ">>>storeDomainOwnerCode saga started..." );
    const { activeSite } = request;
    const { domain } = activeSite;

    logWrite( "request:" );
    logWrite( request );


    if ( !domain ) {
        logWrite( ">>>storeDomainOwnerCode  - empty field - domain" );
        throw new Error( "Empty domain - csCreateAndConfirmSite - storeDomainOwnerCode" );
    }

    if ( !ownerCode ) {
        logWrite( ">>>storeDomainOwnerCode  - empty field - ownerCode" );
        throw new Error( "Empty owner code - csCreateAndConfirmSite - storeDomainOwnerCode" );
    }

    logWrite( ">>>storeDomainOwnerCode  taken!" );
    const url = `/?rest_route=/sitemap_premium/v1/domainOwnership`;
    try {
        logWrite( "calling url " + url );
        let jsonData = {};
        if ( process.env.REACT_APP_MOCK === 'true' ) {
            let response = {
                "ownerCode": "12345",
                "domain": "http://localhost:8071"
            };
            yield put( actions.setDomainOwnerCode.success( response ) );

        } else {

            const callRequest = {
                relativeUrl: "domainOwnership",
                method: "POST",
                data: { domain: domain, ownerCode }
            };

            // now save token payload...
            const jsonData = yield call( wpApiCall, callRequest );

            if ( jsonData ) {
                return jsonData;
            } else {
                throw new Error( "Something went wrong in >>>storeDomainOwnerCode , the response:." );
            }
        }
    } catch (e) {
        logWrite( "Error in >>>storeDomainOwnerCode , the response:" );
        logWrite( e );

        throw e;
    }
}

/***
 * Executed in Wordpress
 * @param request
 * @param installationHashCode
 * @param ownerCode
 * @returns {Generator<{ownerCode: string, domain: string}|<"CALL", CallEffectDescriptor>, {protocol: *, ownerCode: string, success: boolean, domain: *, installationHashCode: string}, null>}
 */
function* confirmAndCreateSite( request, installationHashCode = "", ownerCode = "" ) {
    logWrite( ">>>confirmAndCreateSite started..." );
    const { activeSite, planData, protocol } = request;
    const { domain } = activeSite;

    const { planName = "" } = planData;

    if ( !domain ) {
        logWrite( ">>>confirmAndCreateSite ... - empty field - domain" );
        return;
    }

    let jsonData = null;
    try {
        if ( process.env.REACT_APP_MOCK === 'true' ) {
            yield {
                ownerCode: "12345",
                domain: "http://localhost:8071"
            };
        } else {
            // real call:

            let reqBody = { domain, protocol, planName, installationHashCode, ownerCode };
            const callRequest = {
                relativeUrl: "sites/confirmAndSetupDomain",
                apiStack: "auctollo-sites-api",
                data: reqBody,
                method: "POST"
            };

            jsonData = yield call( cloudApiCall, callRequest );
            jsonData = jsonData.data;

            return {
                ownerCode,
                domain: jsonData.domain,
                protocol: jsonData.protocol,
                installationHashCode,
                success: true
            };

        }
    } catch (e) {
        logWrite( "Error in watchDomainOwnershipVerified::" );
        logWrite( e );

        throw e;
    }
}

/**
 * Transfer wp settings to cloud upon site creation...
 * Executed in Wordpress
 *
 * @param request
 * @param installationHashCode
 * @param ownerCode
 * @returns {IterableIterator<{ownerCode: string, domain: string}|<"CALL", CallEffectDescriptor>|{protocol: *, ownerCode: *, success: boolean, domain: *, installationHashCode: *}>}
 */
function* saveSettingsToCloud( request, installationHashCode = "", ownerCode = "" ) {
    logWrite( ">>>saveSettingsToCloud started..." );
    const { activeSite, planData, protocol } = request;
    const { domain } = activeSite;

    const { planName = "" } = planData;

    if ( !domain ) {
        logWrite( ">>>saveSettingsToCloud ... - empty field - domain" );
        return;
    }

    let jsonData = null;
    try {
        if ( process.env.REACT_APP_MOCK === 'true' ) {
            yield {
                ownerCode: "12345",
                domain: "http://localhost:8071"
            };
        } else {
            // real call:
            let reqBody = {
                domain: activeSite.domain,
                protocol: activeSite.protocol,
                ownerCode,
                installationHashCode,
                settingsType: "standard"
            };

            const callRequest = {
                relativeUrl: "sites/pullSiteSettingsFromWp",
                apiStack: "auctollo-sites-api",
                data: reqBody,
                method: "POST"
            };

            jsonData = yield call( cloudApiCall, callRequest );

            if ( !jsonData ) {
                throw new Error( "pullSiteSettingsFromWp empty response" );
            }

            jsonData = jsonData.data;

            logWrite( jsonData, "Response from transfer settings endpoint" );
            logWrite( jsonData, ">>>saveSettingsToCloud - data returned" );
        }
        return true;
    } catch (e) {
        logWrite( "Error in saveSettingsToCloud::" );
        logWrite( e );

        throw e;
    }
}

/***
 * Executed in Wordpress
 * Main function which orchestrates the process of creation of a new site entry
 * @returns {Generator<IterableIterator<*>|Generator<null, null, null>|<"PUT", PutEffectDescriptor<{request: *, type: string}>>|<"PUT", PutEffectDescriptor<{data: *, type: string}>>|<"PUT", PutEffectDescriptor<{type: string}>>|Generator<{ownerCode: string, domain: string}, {protocol: *, ownerCode: string, success: boolean, domain: *, installationHashCode: string}, null>|IterableIterator<{ownerCode: string, domain: string}|{protocol: *, ownerCode: *, success: boolean, domain: *, installationHashCode: *}>|<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<{type: string, error: *}>>, void, *>}
 */
function* watchCsCreateAndConfirmSite() {
    while (true) {
        logWrite( ">>>watchCsCreateAndConfirmSite - started" );
        const { request } = yield take( actions.CS_CONFIRM_AND_CREATE_SITE_PROCESS.REQUEST );
        const { activeSite, protocol, history, planData } = request;
        const { planName } = planData;

        const { domain } = activeSite;

        logWrite( ">>>watchCsCreateAndConfirmSite request:" );
        logWrite( activeSite );

        if ( !activeSite || !domain ) {
            logWrite( ">>>watchCsCreateAndConfirmSite cannot proceed. Empty domain name or active site" );
            continue;
        }

        logWrite( ">>>watchCsCreateAndConfirmSite - taken" );
        try {
            const ownerCodeObj = yield* createDomainOwnerCode( request );
            logWrite( ">>>generated owner code:" );
            logWrite( ownerCodeObj.ownerCode );
            const { installationHashCode, ownerCode } = ownerCodeObj;
            // yield* storeDomainOwnerCode( request, ownerCode );
            // yield* confirmAndCreateSite( request, installationHashCode, ownerCode );
            // yield* saveSettingsToCloud( request, installationHashCode, ownerCode );

            yield call( storeDomainOwnerCode, request, ownerCode );
            yield call( confirmAndCreateSite, request, installationHashCode, ownerCode );
            yield call ( saveSettingsToCloud,  request, installationHashCode, ownerCode );

            // reload user and domain data:
            logWrite( "about to dispatch the request for user info reload" );
            yield put( startupActions.loadUserAndDomainsData.request( null ) );
            logWrite( "dispatched the request for user info reload" );
            const reloadRequestResult = yield take( [ startupActions.STARTUP_LOAD_USER_AND_DOMAINS_DATA.SUCCESS, startupActions.STARTUP_LOAD_USER_AND_DOMAINS_DATA.FAILURE ] );
            logWrite( reloadRequestResult, "reloadRequestResult" );
            if ( reloadRequestResult.type == startupActions.STARTUP_LOAD_USER_AND_DOMAINS_DATA.SUCCESS ) {
                logWrite( "reloadRequest succeeded" );
            } else {
                logWrite( "reloadRequest failed..." );
            }

            yield put( actions.csCreateAndConfirmSiteProcess.success() );
            // redirect after success:
            // alert("done, now the user gets redirected to premium page!");
            //history.push("/payment-braintree");


            if ( planName === "premium" ) {
                if(activeSite.type=="premium" && activeSite.braintreeSubscriptionId!==undefined){
                    history.push( "/dashboard" ); 
                }else{
                // go to payment:
                // first set the domain for payment step:
                yield put( clientSiteActions.planChosen( { planName } ) );
                yield put( clientSiteActions.enteredDomainUrl( { protocol, domain, targetUrl: "" } ) );

                history.push( "/installation/cs/payment" );
                }
            } else if ( planName === "community" ) {
                // done, go to dasbhoard:
                history.push( "/dashboard" );
                // history.push( "/installation/app/completed" );
            } else {
                throw new Error( "Cannot redirect - invalid planData object" );
            }

        } catch (e) {
            logWrite( "error in watchCsCreateAndConfirmSite" );
            logWrite( e );
            yield put( actions.csCreateAndConfirmSiteProcess.error( e ) );
            w3logger("domain",domain)
            // redirect after success:
            // alert( "error, now the user gets redirected to home page!" );
            // alert(`This site is already associated with another account!`)
            // history.push( "/dashboard" );
            yield Auth.signOut({global: false});
            localStorage.clear();
            GoogleApiHelper.onLogout();

            // yield put( userActions.logout() );
            // yield localStorage.clear();
            history.push("/sign-in");
        }
    }
}

function* watchCheckDomainCreate() {
    while (true) {
        const { request : {domain='', userId='' ,action=""} } = yield take(actions.CHECK_DOMAIN_CREATE.REQUEST);
        w3logger("CHECK_DOMAIN_CREATESAGA",domain)
            try{
                
                let reqBody ={};
                w3logger("prod connect!!!!");
                    const apiCallConfig = {
                        body: { domain:domain, userId:userId }
                    };
                    const config = {
                            response: true,
                        };
                    // returns response object, with property body as string
                    const response = yield API.post("auctollo", "/site/check-domain-creation",apiCallConfig);
                    w3logger("resulting jsonData CHECK_DOMAIN_CREATE:jsondataRES",response);
                    // let responseObject = JSON.parse(response.body)
                    // jsonData = responseObject.data.additionalData.subscriptionData
                    // w3logger("resulting jsonData getcognito:jsondata",responseObject);
                    // w3logger("resulting jsonData subscription:setting",jsonData);

            yield put(actions.checkOnDomainCreate.success(response.data));
            // if(response.data.domainExist!==undefined && response.data.domainExist==false){
            //     yield call( actionSuccess );
            // }
            if(response.data.domainExist!==undefined && response.data.domainExist==true){
                yield call( action );
            }
            } catch(e){
                w3logger("check domain exitst ERROR>>>>", e)
                yield put(actions.checkOnDomainCreate.error(e))
            }
    }
}

export default [
    fork( watchCsCreateAndConfirmSite ),
    fork( watchCheckDomainCreate ),
];
