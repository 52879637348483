export const googleWebMaserChartConfig = [
    {
        color: "#32aaa5",
        name: "Clicks",
    },
    {
        color: "#ff6e9c",
        name: "Impressions",
    },
    {
        color: "#4494ff",
        name: "CTR",
    },
    {
        color: "#ff5221",
        name: "Position",
    }
];

export const bingWebMaserChartConfig = [
    {
        color: "#32aaa5",
        name: "CrawledPages",
    },
     {
        color: "#4494ff",
        name: "Clicks",
    },
];
export const googleAnalyticsChartConfig = [
    {
        color: "#32aaa5",
        name: "uniquePageviews",
    },
    {
        color: "#ff6e9c",
        name: "visitors",
    }
];
