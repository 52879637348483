import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
    confirmationError: "",
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Confirmation Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const confirmation = (state = initialState, action) => {
    switch (action.type) {
        case actions.CONFIRM_USER.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                confirmationError: action.error,
            });

        case actions.CONFIRM_USER.SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                message: "",
                confirmationError: "",
            });

        case actions.CONFIRM_USER.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
                confirmationError:''
            });

        default:
            return state;
    }
};

export default {confirmation};
