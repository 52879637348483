// @flow
import React from "react";
import { useSelector } from 'react-redux'
import Form from "./Form";
import {reduxForm} from 'redux-form'
import {  LoaderElement } from "common/components";

import "assets/css/Innerglobal.css";
import Skeleton from 'react-loading-skeleton';
import styles from '../../../common/components/loaderElement/loaderElement.module.scss'
function CodeVerifyComponet(props){
    const loading = useSelector(state=>state.confirmation.loading)

        return (
            <>
                    {
                        loading ?
                        <div className={`${styles["loading-wrapper"]}  wp-loader d-flex flex-column align-items-center `}>
                            <LoaderElement />
                        </div>
                        :
                        <Form {...props} />
                    }
                        </>
        );
    
}
export default reduxForm({
    // a unique name for the form
    form: 'confirmation',
    enableReinitialize: true,
    destroyOnUnmount: false
  })(CodeVerifyComponet)

