// @flow
import React from "react";
import { GoogleIcon, GitHubIcon, MicrosoftIcon } from "assets/icons";
import { googleURL } from "helpers/constants";


export const buttonsConfig = [
    {
        url: googleURL,
        text: "Sign in with Google",
        icon: <GoogleIcon />,
        color: "blue",
        type:'google',
        onClickBtn: Function
    },
    {
        url:"microsoft",
        text:"Sign in with Microsoft",
        icon:<MicrosoftIcon />,
        color:"red",
        type:'microsoft',
        onClickBtn: Function
    },
    {
        url:"github",
        text:"Sign in with Github",
        icon:<GitHubIcon />,
        color:"black",
        type:'github',
        onClickBtn: Function
    }
];

