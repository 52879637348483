import { createRequestTypes } from "../../helpers/constants";

export const PAYMENT_BRAINTREE = createRequestTypes("PAYMENT_BRAINTREE");
export const GENERATE_TOKEN = createRequestTypes("GENERATE_TOKEN");
export const CUSTOMER_ID = createRequestTypes("CUSTOMER_ID");
export const FETCH_PLANS = createRequestTypes("FETCH_PLANS");

export const fetchPlans = {
    request: request => ({ type: FETCH_PLANS.REQUEST, request }),
    success: data => ({ type: FETCH_PLANS.SUCCESS, data }),
    error:   error => ({ type: FETCH_PLANS.FAILURE, error })
};

export const getCustomerId = {
    request: request => ({ type: CUSTOMER_ID.REQUEST, request }),
    success: data => ({ type: CUSTOMER_ID.SUCCESS, data }),
    error:   error => ({ type: CUSTOMER_ID.FAILURE, error })
};


export const generateToken = {
    request: request => ({ type: GENERATE_TOKEN.REQUEST, request }),
    success: data => ({ type: GENERATE_TOKEN.SUCCESS, data }),
    error:   error => ({ type: GENERATE_TOKEN.FAILURE, error })
};


export const paymentBraintree = {
    request: request => ({ type: PAYMENT_BRAINTREE.REQUEST, request }),
    success: () => ({ type: PAYMENT_BRAINTREE.SUCCESS }),
    error:   error => ({ type: PAYMENT_BRAINTREE.FAILURE, error })
};

export const ACTIVATE_SITE_UPON_PAYMENT = createRequestTypes("ACTIVATE_SITE_UPON_PAYMENT");

export const activateSiteUponPayment = {
    request: request => ({ type: ACTIVATE_SITE_UPON_PAYMENT.REQUEST, request }),
    success: () => ({ type: ACTIVATE_SITE_UPON_PAYMENT.SUCCESS }),
    error:   error => ({ type: ACTIVATE_SITE_UPON_PAYMENT.FAILURE, error })
};

