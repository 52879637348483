import React from "react";

const SettingsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 34" width="32" height="34">
            <path
                fill="#1C6E9D"
                d="M28.723 19.406l2.872 1.686a.83.83 0 01.368.959 17.044 17.044 0 01-3.686 6.484.806.806 0 01-1 .154l-2.869-1.686a12.861 12.861 0 01-4.095 2.408v3.372a.832.832 0 01-.177.514.807.807 0 01-.455.289 16.57 16.57 0 01-7.36 0 .821.821 0 01-.634-.803V29.41a12.862 12.862 0 01-4.095-2.408l-2.87 1.686a.798.798 0 01-1-.154A17.044 17.044 0 01.037 22.05a.83.83 0 01.368-.959l2.872-1.686a13.486 13.486 0 010-4.812L.405 12.908a.83.83 0 01-.368-.959 17.044 17.044 0 013.685-6.484.798.798 0 011-.155l2.87 1.687a12.86 12.86 0 014.095-2.408V1.217c0-.187.062-.368.177-.514a.807.807 0 01.455-.289 16.57 16.57 0 017.36 0c.37.084.634.418.634.803V4.59a12.86 12.86 0 014.095 2.408l2.87-1.686a.798.798 0 011 .154 17.045 17.045 0 013.685 6.484.83.83 0 01-.368.959l-2.872 1.686a13.49 13.49 0 010 4.812zM21.392 17c0-3.024-2.42-5.484-5.392-5.484-2.973 0-5.392 2.46-5.392 5.484s2.42 5.484 5.392 5.484c2.973 0 5.392-2.46 5.392-5.484z"
            />
        </svg>
    );
};

export default SettingsIcon;
