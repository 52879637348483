import React from "react";
import { API } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { w3logger } from 'logger';

/* eslint-disable */
const TestComponent = () => {
    const { t } = useTranslation();

    const onClickHandler = async () => {
        const reqBody = {
            body: { token: 123 },
        };
        try {
            const test = await API.post("auctollo", "/sso/testToken", reqBody);
            eslint
            w3logger("test: ", test);
        } catch (e) {
            w3logger(e, "testError");
        }

    };
    return (
        <div onClick={onClickHandler}>{t("Click Me")}</div>
    );
};

export default TestComponent;
/* eslint-enable */
