import { put, take, fork, call, all, takeEvery } from "redux-saga/effects";
import { Auth, API } from "aws-amplify";
import * as actions from "../../actions";
import * as userActions from "actions/user";
import dotenv from "dotenv";
import * as signUpActions from '../../../signup/redux/actions'
import { GoogleApiHelper } from "helpers/index";
import * as installationActions from '../../../installation/actions'
import { stopSubmit } from "redux-form";
import {logWrite,} from "logger";
import { frontendCloudApiCall, wpApiCall } from "api/apiClient";
import { isInsideWpAdmin } from '../../../../helpers/installationFlowHelper'
import { w3logger, logError } from "../../../../logger";
dotenv.load();
export const storeRefreshToken = async () => {

    if(isInsideWpAdmin() === false){
        // callable only from inside wp
        return false;
    }

    // get the refresh token and id token payload
    const refreshToken = (await Auth.currentSession()).getRefreshToken().token;
    const { payload = null } = (await Auth.currentSession()).getIdToken();
    const idToken = (await Auth.currentSession()).getIdToken().jwtToken;
    w3logger('refreshToken',refreshToken)
    // store refresh token, if inside wp:
    const forceRefreshTokenSaveOnLogin = true;
    if (forceRefreshTokenSaveOnLogin === true && window.localStorage.getItem('getToken')!==true) {
        logWrite(">>>storeRefreshToken - Updating refresh token...")
        const request = {
            relativeUrl: "rfTkn",
            method: "POST",
            data: {
                refresh_token: refreshToken,
                id_token : idToken
            }
        };
        const refreshCallResult = await wpApiCall(request);

        // now save token payload...
        const requestSavePayload = {
            relativeUrl: "saveTokenPayload",
            method: "POST",
            data: {
                payload
            }
        };

        const saveTokenPayloadResult = await wpApiCall(requestSavePayload);
        w3logger('saveTokenPayloadResult',saveTokenPayloadResult)
        window.localStorage.setItem('getToken',true)
    }
};

function* watchSignIn() {
    while (true) {
        const { request: { email, password, action, planData = null, activeSite=null, domainData },request } = yield take(actions.SIGN_IN.REQUEST);
        const {planName = ""} = planData || {};
        w3logger(domainData,'signin domain data')
        logWrite(planData, "plan data inside watchSignIn");

        try {
            let AuthData = {}

            AuthData = yield Auth.signIn(email, password);
            const { username, userDataKey, attributes, Session } = AuthData;
            yield put(userActions.setUserData.success({ username, userDataKey, attributes, Session }));
            yield put(actions.signIn.success());
            // yield put ( startupActions.loadUserAndDomainsData.request( AuthData ) )
            
            if(isInsideWpAdmin()){
                // const {planName=""} = planData;
                const planName='community'
                let isInsideWP = true
                const request = {
                    relativeUrl: "sites/createSite",
                    apiStack: "auctollo-sites-api",
                    data: {...domainData, planName,isInsideWP},
                    method: "POST"
                };
                
                const result = yield call (frontendCloudApiCall, request);
                const { status, message, data } = result;
                w3logger('inside wp admin',status,message,'damta',data)
                if(status!==0) {
                    yield put(userActions.setActiveDomain(data.siteCreateResult.siteInfoExistingResult.Item))
                    window.localStorage.setItem('domainData',JSON.stringify(data.siteCreateResult.siteInfoExistingResult.Item))
                } else throw new Error(data?.Error)
                
            } else {
                //yield put(actions.getSite())
                
                let response = [];
                
                response = yield API.get("auctollo", "/site/get-sites");
                if(response) {
                    w3logger('response',response)
                    //yield put(userActions.setDomainsData(response.data));
                    let domainsData = [];
                        if(Array.isArray(response.data)){
                            domainsData = response.data;
                        } else {
                            domainsData = [];
                        }
                        const existingDomains = domainsData;
                        let siteSelected = activeSite;
                        let rest = null;
                        ([siteSelected, rest] = domainsData);
                        yield put(userActions.setDomainsData(existingDomains));
                        yield put(userActions.setActiveDomain(siteSelected));
                        window.localStorage.setItem('domainData',JSON.stringify(siteSelected))
                    }
                }
                try {
                    const config = {
                        response: true,
                    };
                    const res = yield API.get("auctollo", "/site/tokens",config);
                    let tokens = JSON.parse(res.data.body)
                    if(tokens.hasOwnProperty('data') && tokens.data.hasOwnProperty('googletoken')) {
                        yield GoogleApiHelper.storeToken(tokens.data.googletoken);
                    } else {
                        //yield call(action, "/dashboard");
                    }
                    if(isInsideWpAdmin() === true){
                        // initiate store refresh token for an existing wp site:
                        yield call(storeRefreshToken);
                    }
                } catch(e){
                    w3logger("get-google-token ERROR>>>>", e);
                    //yield call(action, "/dashboard");
                }
                yield put(userActions.setIsauthenticated(true));
                yield put(actions.signIn.success());
            

            let location = window.location.href.replace(`${window.location.hash}`,'#/dashboard')
            window.location.href = `${location}`
     
        } catch (e) {
            yield put(actions.signIn.error((e && e.message) ? e.message : e));
            yield put(stopSubmit("signInComponent", e.message));
            logError(">>>watchSignIn after error!!",e);
        }
    }
}

function* watchCreateSite(){
    while (true) {
        const { request: { domainData, history, handleLogout,settings },request } = yield take(actions.CREATE_SITE.REQUEST);
        w3logger(domainData,'watchCreateSite',request)
        try {
                const planName='community'
                let isInsideWP = true
                const request = {
                    relativeUrl: "sites/createSite",
                    apiStack: "auctollo-sites-api",
                    data: {...domainData, planName,isInsideWP,settings},
                    method: "POST"
                };
                
                const result = yield call (frontendCloudApiCall, request);
                const { status, message, data } = result;
                w3logger('inside wp admin',status,message,'damta',data)
                if(status!==0) {
                    yield put(userActions.setActiveDomain(data.siteCreateResult.siteInfoExistingResult.Item))
                    window.localStorage.setItem('domainData',JSON.stringify(data.siteCreateResult.siteInfoExistingResult.Item))
                    window.localStorage.removeItem('createSiteError')
                    window.localStorage.setItem('siteCreated',true)
                    yield storeRefreshToken()
                    const config = {
                        response: true,
                    };
                    const response = yield API.get("auctollo", "/site/tokens",config);
                    let tokens = JSON.parse(response.data.body)
                    if(tokens.hasOwnProperty('data') && tokens.data.hasOwnProperty('googletoken')) {
                        yield GoogleApiHelper.storeToken(tokens.data.googletoken);
                    } 
                } else throw new Error(data?.Error)

     
        } catch (e) {
            w3logger('error;',e)
            w3logger('error;',e)
            if(window.localStorage.getItem('targetPlan')!==null){
                yield put(signUpActions.signUp.error((e && e.message) ? e.message : e));
                yield put(stopSubmit("signUp", e.message));
                yield window.localStorage.clear()
                yield window.localStorage.setItem('createSiteError',(e && e.message) ? e.message : e)

                    let location = window.location.href.replace(`${window.location.hash}`,'#/sign-up')
                    window.location.href = `${location}`
            } else {
                yield put(actions.signIn.error((e && e.message) ? e.message : e));
                yield put(stopSubmit("signInComponent", e.message));
                logError(">>>watchSignIn after error!!",e);
                yield window.localStorage.clear()

                yield window.localStorage.setItem('createSiteError',(e && e.message) ? e.message : e)
   
                    let location = window.location.href.replace(`${window.location.hash}`,'#/sign-in')
                    window.location.href = `${location}`
            }
            yield Auth.signOut({global:true});
            // yield put(userActions.logout())
            yield handleLogout()
            //put signun error here
        }
    }
}

// function* watchGetSites(){
//     while(true){
//         const { request:{activeSite} } = yield take(actions.GET_SITES);

//         let response = [];
                
//         response = yield API.get("auctollo", "/site/get-sites");
//         if(response) {
//             w3logger('response',response)
//             //yield put(userActions.setDomainsData(response.data));
//             let domainsData = [];
//                 if(Array.isArray(response.data)){
//                     domainsData = response.data;
//                 } else {
//                     domainsData = [];
//                 }
//                 const existingDomains = domainsData;
//                 let siteSelected = activeSite;
//                 let rest = null;
//                 ([siteSelected, rest] = domainsData);
//                 yield put(userActions.setDomainsData(existingDomains));
//                 yield put(userActions.setActiveDomain(siteSelected));
//                 window.localStorage.setItem('domainData',JSON.stringify(siteSelected))
//         }
//     }
// }
export default [
    fork(watchSignIn),
    fork(watchCreateSite),
];
