import { createRequestTypes } from "helpers/constants";

// UPGRADE DATA:
export const INSTALLATION_GET_UPGRADE_DATA = createRequestTypes("INSTALLATION_GET_UPGRADE_DATA");

// read upgrade data from WP:
export const getUpgradeData = {
    request: request => ({ type: INSTALLATION_GET_UPGRADE_DATA.REQUEST, request }),
    success: response => ({ type: INSTALLATION_GET_UPGRADE_DATA.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_GET_UPGRADE_DATA.FAILURE, error })
};


// UPGRADE PROCESS:
export const UPGRADE_OLD_PLUGIN_COMPLETED = createRequestTypes("UPGRADE_OLD_PLUGIN_COMPLETED");

export const upgradeFromOldPluginCompleted = {
    request: request => ({ type: UPGRADE_OLD_PLUGIN_COMPLETED.REQUEST, request }),
    success: data => ({ type: UPGRADE_OLD_PLUGIN_COMPLETED.SUCCESS, data }),
    error:   error => ({ type: UPGRADE_OLD_PLUGIN_COMPLETED.FAILURE, error })
};

// CREATE DOMAIN OWNER CODE (ON APP SIDE):

export const INSTALLATION_CREATED_DOMAIN_OWNER_CODE = createRequestTypes("INSTALLATION_CREATED_DOMAIN_OWNER_CODE");

export const createdDomainOwnerCode = {
    request: request => ({ type: INSTALLATION_CREATED_DOMAIN_OWNER_CODE.REQUEST, request }),
    success: response => ({ type: INSTALLATION_CREATED_DOMAIN_OWNER_CODE.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_CREATED_DOMAIN_OWNER_CODE.FAILURE, error })
};

// SET DOMAIN OWNER CODE (ON WP SIDE):

export const INSTALLATION_SET_DOMAIN_OWNER_CODE = createRequestTypes("INSTALLATION_SET_DOMAIN_OWNER_CODE");

export const setDomainOwnerCode = {
    request: request => ({ type: INSTALLATION_SET_DOMAIN_OWNER_CODE.REQUEST, request }),
    success: response => ({ type: INSTALLATION_SET_DOMAIN_OWNER_CODE.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_SET_DOMAIN_OWNER_CODE.FAILURE, error })
};


// CONFIRM DOMAIN OWNERSHIP (ON WP SIDE):

export const INSTALLATION_DOMAIN_OWNERSHIP_CONFIRMED = createRequestTypes("INSTALLATION_DOMAIN_OWNERSHIP_CONFIRMED");

export const domainOwnershipConfirmed = {
    request: request => ({ type: INSTALLATION_DOMAIN_OWNERSHIP_CONFIRMED.REQUEST, request }),
    success: response => ({ type: INSTALLATION_DOMAIN_OWNERSHIP_CONFIRMED.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_DOMAIN_OWNERSHIP_CONFIRMED.FAILURE, error })
};

// TRANSFER SITE SETTINGS TO CLOUD:

export const INSTALLATION_TRANSFER_SITE_SETTINGS_TO_CLOUD = createRequestTypes("INSTALLATION_TRANSFER_SITE_SETTINGS_TO_CLOUD");

export const transferSiteSettingsToCloud = {
    request: request => ({ type: INSTALLATION_TRANSFER_SITE_SETTINGS_TO_CLOUD.REQUEST, request }),
    success: response => ({ type: INSTALLATION_TRANSFER_SITE_SETTINGS_TO_CLOUD.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_TRANSFER_SITE_SETTINGS_TO_CLOUD.FAILURE, error })
};

// REFRESH TOKEN OBTAINED (ON WP SIDE):

export const INSTALLATION_REFRESH_TOKEN_OBTAINED = createRequestTypes("INSTALLATION_REFRESH_TOKEN_OBTAINED");

export const installationRefreshTokenObtained = {
    request: request => ({ type: INSTALLATION_REFRESH_TOKEN_OBTAINED.REQUEST, request }),
    success: response => ({ type: INSTALLATION_REFRESH_TOKEN_OBTAINED.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_REFRESH_TOKEN_OBTAINED.FAILURE, error })
};


// INSTALLATION FLOW:
export const SET_INSTALLATION_FLOW = "SET_INSTALLATION_FLOW";
export const SET_INSTALLATION_FLOW_DATA = "SET_INSTALLATION_FLOW_DATA";
export const SET_INSTALLATION_ORIGIN = "SET_INSTALLATION_ORIGIN";
export const SET_INSTALLATION_STATUS = "SET_INSTALLATION_STATUS";

export const setInstallationFlow = data => ({ type: SET_INSTALLATION_FLOW, data });
export const setInstallationFlowData = data => ({ type: SET_INSTALLATION_FLOW_DATA, data });
export const setInstallationOrigin = data => ({ type: SET_INSTALLATION_ORIGIN, data });
export const setInstallationStatus = data => ({ type: SET_INSTALLATION_STATUS, data });

// CHECK INSTALLATION PROCESS - STATE:
export const SET_INSTALLATION_CHECK_STATE = "SET_INSTALLATION_CHECK_STATE";
export const setInstallationCheckState = data => ({ type: SET_INSTALLATION_CHECK_STATE, data });

// INIT DEFAULT SITE:
export const INSTALLATION_CREATED_DEFAULT_SITE = createRequestTypes("INSTALLATION_CREATED_DEFAULT_SITE");
export const createdDefaultSite= {
    request: request => ({ type: INSTALLATION_CREATED_DEFAULT_SITE.REQUEST, request }),
    success: response => ({ type: INSTALLATION_CREATED_DEFAULT_SITE.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_CREATED_DEFAULT_SITE.FAILURE, error })
};

