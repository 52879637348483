import React from "react";

const OpenedLockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 16 14" width="16" height="14">
            <path
                fill="#fff"
                d="M11.764 0C9.43.008 7.556 1.9 7.556 4.197v1.928H1.333C.597 6.125 0 6.713 0 7.438v5.25C0 13.412.597 14 1.333 14h9.778c.736 0 1.333-.588 1.333-1.313v-5.25c0-.724-.597-1.312-1.333-1.312H9.778V4.181c0-1.083.88-1.983 1.98-1.993 1.111-.011 2.02.877 2.02 1.968v2.188a.66.66 0 00.666.656h.89A.66.66 0 0016 6.344V4.156C16 1.86 14.097-.008 11.764 0z"
            />
        </svg>
    );
};

export default OpenedLockIcon;
