import * as actions from "../actions";

const initialState = {
    loading: false,
    message:'',
    isError:false,
    signUpError:""
};


const signup = (state = initialState, action) => {
    switch (action.type) {
        case actions.SIGN_UP.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message:'',
                isError:false,
                signUpError:''
            });
        
        case actions.SIGN_UP.SUCCESS:
            return Object.assign({},state,{
                loading:false,
                message:'Sigup success',
                isError:false,
                signUpError:''
            })

        
        case actions.SIGN_UP.FAILURE:
            return Object.assign({},state,{
                loading:false,
                isError:true,
                message:'',
                signUpError:action.error
            })
        default:
            return state;
    }
};

export default {signup};
