import * as actions from "../actions";
import { w3logger } from 'logger';

const initialState = {
    loading: false,
    message: "",
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Settings Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const payment = (state = initialState, action) => {
    switch (action.type) {
            case actions.GET_PAYMENT_STATUS.REQUEST:
                return Object.assign({}, state, {
                    loading: true,
                    message: "",
                });

            case actions.GET_PAYMENT_STATUS.FAILURE:
                return Object.assign({}, state, {
                    loading: true,
                    message: action.error,
                });
                case actions.GET_PAYMENT_STATUS.SUCCESS:
                    return Object.assign({}, state, {
                        loading: true,
                        message: action.data,
                    });
            case actions.PAYMENT_UPDATE_INFO.FAILURE:
                return Object.assign({}, state, {
                    loading: false,
                    message: action.error,
                });

            case actions.PAYMENT_UPDATE_INFO.SUCCESS:
                w3logger("PAYMENT SUCCESS DETECTED!!");
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    paymentCompleted: true
                });

            case actions.PAYMENT_UPDATE_INFO.REQUEST:
                return Object.assign({}, state, {
                    loading: true,
                    message: "",
                });


            case actions.DISPLAY_PAYMENT_INFO.SUCCESS:
            return Object.assign( {}, state, {
                loading: false,
                message: "",
                paymentData: action.data
            } );

        case actions.DISPLAY_PAYMENT_INFO.REQUEST:
            return Object.assign( {}, state, {
                loading: true,
                message: "",
            } );

            case actions.DISPLAY_PAYMENT_INFO.FAILURE:
                return Object.assign({}, state, {
                    loading: false,
                    message: action.error,
                });


        default:
            return state;
    }
};

export default payment;
