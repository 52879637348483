import { put, take, fork, call } from "redux-saga/effects";
import { API, Auth } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import { logWrite } from "../../logger";
import dotenv from "dotenv";
import * as startupActions from "../../actions/startup";

import { premiumResponse } from "../../config/offlineData";
import { stringify } from "query-string";
import { isInsideWpAdmin } from "../../helpers/installationFlowHelper";
import { isLiveSessionAvailable, backendCloudApiCall, cloudApiCall } from "../../api/apiClient";
import { frontendCloudApiCall } from "api/apiClient";
import { w3logger } from 'logger';
import * as settingsActions from "../settings/actions";
import * as cloudSitemapStatusActions from "../../components/cloudSitemapStatus/actions";
dotenv.load();

const {
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_API_PAYMENT_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE,
    REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL
} = process.env;

/***
 * Check if live amplifyjs user is available and make the call with either API.post or backendCloudApiCall().
 * Returns jsonData object
 * @returns {Promise<null>}
 * @throws {Error}
 * @private
 */
const _checkLiveSessionAndMakeCloudApiCall = async ( relativeUrl, apiStack, reqBody, method ) => {
    const isLiveSession = await isLiveSessionAvailable();
    let response = null;
    let jsonData = null;
    if ( isLiveSession === true ) {
        if ( method.toLowerCase() === "post" ) {
            response = await API.post( apiStack, "/" + relativeUrl, {
                body: { ...reqBody }
            } );
        } else if ( method.toLocaleString() === "get" ) {
            response = await API.get( apiStack, "/" + relativeUrl, {} );
        } else {
            throw new Error( "Invalid method in _checkLiveSessionAndMakeCloudApiCall " );
        }
        jsonData = JSON.parse( response.body );
    } else {
        if ( isInsideWpAdmin() === true ) {
            // ok, go with backend api call:
            const callRequest = {
                relativeUrl,
                apiStack,
                data: reqBody,
                method
            };
            response = await backendCloudApiCall( callRequest );
            jsonData = response;
            logWrite( response, "Got response for " + relativeUrl );
        } else {
            throw new Error( "ajax request for " + relativeUrl + " - error, no user data available" );
        }
    }

    return jsonData;
};

/***
 * Activate site upon payment
 * @returns {IterableIterator<<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<*|{type: *}>>|Promise<Response>|Promise<any>|*|<"PUT", PutEffectDescriptor<*|{type: *, error: *}>>|<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>>}
 */
function* watchActivateSiteUponPayment() {
    while (true) {
        const { request: { domain, action } } = yield take( actions.ACTIVATE_SITE_UPON_PAYMENT.REQUEST );
        try {
            const reqBody = {
                domain
            };

            let response = {};
            let responseObject = {};
            let jsonData = null;

            if ( process.env.REACT_APP_MOCK === 'true' ) {
                // response  = {data:yield premiumResponse};
                jsonData = { data: {} };
            } else {
                // real call:
                const userDetails = yield Auth.currentAuthenticatedUser()
                if(userDetails!==null){
                    if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
                        // localhost:
                        w3logger( "localhost connect" );
                        response = yield fetch( `${REACT_APP_API_GATEWAY_URL}/site/premium`, {
                            method: "POST",
                            body: JSON.stringify( reqBody )
                        } );
                        responseObject = yield response.json();
                        w3logger( "got response:" );
                        w3logger( responseObject );

                        if ( typeof responseObject == "string" ) {
                            responseObject = JSON.parse( responseObject );
                        }

                        jsonData = responseObject.data;
                    } else {
                        // staging / prod:
                        w3logger( "OK!! prod connect!!!!", reqBody);
                        const apiCallConfig = {
                            body: { ...reqBody }
                        };
                        // returns response object, with property body as string
                        //responseObject = yield API.post( "auctollo", "/site/premium", apiCallConfig );
                        jsonData = yield call( _checkLiveSessionAndMakeCloudApiCall, "site/premium", "auctollo", reqBody, "post" );
                    }
                }
            }

            logWrite( "Response data for activateSite:" );
            logWrite( jsonData );
            //alert("OK!");
            logWrite( "Call action:" );
            logWrite( action );

            //const { data } = response;
            yield put( actions.activateSiteUponPayment.success() );

            logWrite( "Updating active domain..." );
            // yield put(userActions.updateActiveDomain(jsonData));
            logWrite( "Setting user data..." );
            // yield put(userActions.setUserData({username: "Test User", attributes: {name: "Mr.Tester", email: "test@test123456.com"}}));

            // reload user data:
            yield put( startupActions.loadUserAndDomainsData.request( null ) );

            logWrite( "Calling action.." );
            yield call( action );
        } catch (e) {
            logWrite( "Error in watchActivateSiteUponPayment:" );
            logWrite( e );
            yield put( actions.activateSiteUponPayment.error( e.message ) );
        }
    }
}

function* watchPayment() {
    while (true) {
        const { request: { nonce, domain, formData, settingsData,planId, action, actionFailure='',userId,generatetype } } = yield take( actions.PAYMENT_BRAINTREE.REQUEST );
        const generateAt = new Date(); let statsreqBody = { 
            body: { 
                domain:domain, 
                userId:userId, 
                generatetype:generatetype, 
                generateAt:generateAt 
            } 
        }
        try {
            // let response = {};
            // let responseObject = {};
            let jsonData = null;

            logWrite( domain );
            let settings = settingsData
            let reqBody = { "paymentMethodNonce": nonce, domain, formData, planId };
           

            //     // returns response object, with property body as string
            const apiCallConfig = {
                body: { ...reqBody }
            };
            //const response = yield API.post( "auctollo-payment-api", "/payment/subscribe", apiCallConfig );
            jsonData = yield call( _checkLiveSessionAndMakeCloudApiCall, "payment/subscribe", "auctollo-payment-api", reqBody, "post" );

            w3logger( "resulting json data:" );
            w3logger('payment response', jsonData );
            const { data } = jsonData;

            yield put( actions.paymentBraintree.success() );
            yield window.localStorage.removeItem('targetPlan')
            yield call( action );
            logWrite( "Payment marked as success" );
            if(jsonData.message == 'Success!'){
                settings = {
                    ...settings,
                    sm_license_params : {
                        type : "PREMIUM",
                        purchaseDate : data.resultSubscribe.subscription.billingPeriodStartDate,
                        expirationDate : data.resultSubscribe.subscription.billingPeriodEndDate,
                    }
                }

                yield put(settingsActions.saveSettings.request({
                    domain: domain,
                    subscriptionType: 'PREMIUM',
                    settings: settings,
                }))
                window.localStorage.removeItem('subscriptionCancelled')
            }
            if(isInsideWpAdmin()){
                // const {planName=""} = planData;
                let targetPlan = window.localStorage.getItem('targetPlan')
                let domainData = JSON.parse(window.localStorage.getItem('domainData'))
                const planName= 'premium'
                domainData.type = planName
                let isInsideWP = true
                const request = {
                    relativeUrl: "sites/createSite",
                    apiStack: "auctollo-sites-api",
                    data: {...domainData, planName,isInsideWP},
                    method: "POST"
                };
                const result = yield call (frontendCloudApiCall, request);
                const { status, message, data } = result;
                w3logger('inside wp admin',status,message,data)
                if(targetPlan === 'premium'){
                    yield put(userActions.setActiveDomain(domainData))
                }
                    yield put(userActions.setActiveDomain(data.siteCreateResult.siteInfoExistingResult.Item))
                    window.localStorage.setItem('domainData',JSON.stringify(data.siteCreateResult.siteInfoExistingResult.Item))
            } else {

                let targetPlan = window.localStorage.getItem('targetPlan')
                let domainData = JSON.parse(window.localStorage.getItem('domainData'))
                const planName= targetPlan ? targetPlan : 'community'
                domainData.type = planName
                let isInsideWP = false
                const request = {
                    relativeUrl: "sites/createSite",
                    apiStack: "auctollo-sites-api",
                    data: {...domainData, planName,isInsideWP},
                    method: "POST"
                };
                const result = yield call (frontendCloudApiCall, request);
                const { status, message, data } = result;
                w3logger('inside wp admin',status,message,data)
                if(targetPlan === 'premium'){
                    yield put(userActions.setActiveDomain(domainData))
                }
                    yield put(userActions.setActiveDomain(data.siteCreateResult.siteInfoExistingResult.Item))
                    window.localStorage.setItem('domainData',JSON.stringify(data.siteCreateResult.siteInfoExistingResult.Item))
            }
           
        } catch (e) {
            w3logger( "dd error:" );
            w3logger( e );
            yield put( actions.paymentBraintree.error( e.message ) );
            yield call ( actionFailure );
        } finally{
            const request = {
                body :{ 
                  domain:domain,
                  sitemapIndex :  `https://` + domain + `/sitemap.xml`
                  }
              };
              let responseObject = yield API.post( "auctollo-sitemap-build-api", "/build/buildSitemapTypes", request );
                    let responsedata = yield API.post("auctollo-sitemap-build-api", "/build/sitemapstatistic", statsreqBody);
                
                yield put(cloudSitemapStatusActions.loadCloudSitemapStatus.request({domain:domain}));



        }
    }
}

function* watchGenerateToken() {
    while (true) {
        const { request: { customerID,domain } } = yield take( actions.GENERATE_TOKEN.REQUEST );
        w3logger( "Got display request paymentinfo", customerID );
        try {

            let response = {};
            let responseObject = {};
            let jsonData = null;

            w3logger( "About to call the lambda backend for subscription info" );

            if ( process.env.REACT_APP_MOCK === 'true' ) {
                jsonData = { data: {} };
            } else {
                // real call:

                let reqBody = { customerID, domain };
                w3logger( "" )
                w3logger( "REACT_APP_API_GATEWAY_URL", REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE )

                const apiCallConfig = {
                    body: { ...reqBody }
                };

                //response = yield API.post( "auctollo-payment-api", "/payment/generate-token", apiCallConfig );
                jsonData = yield call( _checkLiveSessionAndMakeCloudApiCall, "payment/generate-token", "auctollo-payment-api", reqBody, "post" );

                // const responseObject = yield response.json();

                w3logger( ">>>generatetoken", jsonData );
                w3logger( "resulting jsonData:asdf", jsonData.data );
                w3logger( jsonData );

                // yield put(actions.generateToken.success(jsonData.data));

            }

            yield put( actions.generateToken.success( jsonData.data ) );
        } catch (e) {
            w3logger( "error in watchGenerateToken" );
            w3logger( e );
            yield put( actions.generateToken.error( e.message ) );
        }
    }
}

function* watchCustomerId() {
    while (true) {
        const { request } = yield take( actions.CUSTOMER_ID.REQUEST );
        try {

            let response = {};
            // let responseObject = {};
            let jsonData = null;

            w3logger( "About to call the lambda backend for subscription info" );

            w3logger( "REACT_APP_API_GATEWAY_URL", REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE )

            // response = yield fetch( `${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/get-customer-id?domain=${request.domain}`, { method: "GET" } );
            response = yield API.get("auctollo-payment-api", `/payment/get-customer-id?domain=${request.domain}`);

            const responseObject = yield response.json();
            // jsonData = JSON.parse(responseObject.body);
            let res = responseObject.data.braintreeCustomerId == undefined ? '' : responseObject.data.braintreeCustomerId
            w3logger( ">>>>>>>>>>>>>>>>>>>>>>>>>", res );

            yield put( actions.getCustomerId.success( res ) );
        } catch (e) {
            w3logger( "error in watchcustomerId", e );
            w3logger( e );
            yield put( actions.getCustomerId.error( e.message ) );
        }
    }
}

function* watchFetchPlans() {
    while (true) {
        const { request } = yield take( actions.FETCH_PLANS.REQUEST );
        w3logger( "Got plans request" );
        try {

            let response = {};
            let responseObject = {};
            let jsonData = null;

            w3logger( "About to call the lambda backend for plan info" );

            if ( process.env.REACT_APP_MOCK === 'true' ) {
                jsonData = { data: {} };
            } else {
                // reall call:
                const user = yield Auth.currentAuthenticatedUser()
                if(user!==null){
                // let reqBody ={domain};
                    w3logger( "REACT_APP_API_GATEWAY_URL", REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE )
                    if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
                        // localhost:
                        w3logger( "localhost connect" );
                        response = yield fetch( `${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/get-plans`, { method: "GET" } );
                        const responseObject = yield response.json();
                        jsonData = JSON.parse( responseObject.body );

                    } else {
                        // staging / prod:
                        w3logger( "prod connect!!!!" );
                        // const apiCallConfig = {
                        //     body: { ...reqBody }
                        // };
                        const config = {
                            response: true,
                        };

                        //const response = yield API.get( "auctollo-payment-api", "/payment/get-plans" );
                        jsonData = yield call( _checkLiveSessionAndMakeCloudApiCall, "payment/get-plans", "auctollo-payment-api", null, "get" );
                    }
                }
            }
            w3logger( "resulting jsonData get plans", jsonData.data.plans );
            w3logger( jsonData );

            yield put( actions.fetchPlans.success( jsonData.data ) );
        } catch (e) {
            w3logger( "error in getplan:Error", e );
            w3logger( e );
            yield put( actions.fetchPlans.error( e.message ) );
        }
    }
}

export default [
    fork( watchPayment ),
    fork( watchActivateSiteUponPayment ),
    fork( watchGenerateToken ),
    fork( watchCustomerId ),
    fork( watchFetchPlans ),
];
