import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "assets/css/index.css";
import { store } from "./store";
import * as serviceWorker from "common/serviceWorker/serviceWorker";
import API from "@aws-amplify/api";
import Amplify, { Auth, Hub } from "aws-amplify";
import dotenv from "dotenv";
import AWS from "aws-sdk";
import { updateServiceworker } from "actions/serviceWorker";
import App from "./App";
import "./assets/scss/common.scss";
import { put } from "@redux-saga/core/effects";
import { isInsideWpAdmin } from "./helpers/installationFlowHelper";
import { w3logger } from 'logger';
dotenv.config();

const onUpdate = () => {
    store.dispatch(updateServiceworker());
};

const getToken = async () => {
    try {
        return (await Auth.currentSession()).idToken.jwtToken;
    } catch (e){
        w3logger("token>>>>>e",e)
        return null;

    }
};

const setCustomHeaders = async () => {
    const token = await getToken();
    let headers = {};
    if (token) {
        headers = {
            Authorization: token
        };
    }
    return headers;
};

const {
    REACT_APP_REGION,
    REACT_APP_S3_BUCKET,
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_API_PAYMENT_GATEWAY_URL,
    REACT_APP_SITES_API_GATEWAY_URL,
    REACT_APP_SITEMAPS_API_GATEWAY_URL,
    REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL,
    REACT_APP_COGNITO_USER_POOL_ID,
    REACT_APP_COGNITO_APP_CLIENT_ID,
    REACT_APP_COGNITO_IDENTITY_POOL_ID,
    REACT_APP_ACCESS_KEY_ID,
    REACT_APP_API_USERS_API_GATEWAY_URL,
    REACT_APP_SECRET_ACCESS_KEY,
    REACT_APP_COGNITO_DOMAIN,
    REACT_APP_OAUTH_REDIRECT_URL,
    REACT_APP_OAUTH_REDIRECT_URL_FROM_CS
} = process.env;

const isHostedInsideWp = isInsideWpAdmin();

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Global config for AWS Amplify for work with Amazon Services
 */
let signoutRedirectUrl = `${window.location.origin}${window.location.pathname}${window.location.search}#/sign-in`;
w3logger("signoutRedirectUrl>>>",signoutRedirectUrl);
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: REACT_APP_REGION,
        userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
        identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
        oauth: {
            domain: REACT_APP_COGNITO_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: REACT_APP_OAUTH_REDIRECT_URL,
            redirectSignOut: REACT_APP_OAUTH_REDIRECT_URL,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    Storage: {
        region: REACT_APP_REGION,
        bucket: REACT_APP_S3_BUCKET,
        identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
    },
});

// window.LOG_LEVEL = 'DEBUG';
API.configure(
    {
        endpoints: [
            {
                name: "auctollo",
                endpoint: REACT_APP_API_GATEWAY_URL,
                region: REACT_APP_REGION,
                custom_header: async () => setCustomHeaders()
            },{
                name: "auctollo-payment-api",
                endpoint: REACT_APP_API_PAYMENT_GATEWAY_URL,
                region: REACT_APP_REGION,
                custom_header: async () => setCustomHeaders()
            },
            {
                name: "auctollo-sites-api",
                endpoint: REACT_APP_SITES_API_GATEWAY_URL,
                region: REACT_APP_REGION,
                custom_header: async () => setCustomHeaders()
            },
            {
                name: "auctollo-sitemaps-api",
                endpoint: REACT_APP_SITEMAPS_API_GATEWAY_URL,
                region: REACT_APP_REGION,
                custom_header: async () => setCustomHeaders()
            },
            {
                name: "auctollo-sitemap-build-api",
                endpoint: REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL,
                region: REACT_APP_REGION,
                custom_header: async () => setCustomHeaders()
            },
            {
                name: "auctollo-users-api",
                endpoint: REACT_APP_API_USERS_API_GATEWAY_URL,
                region: REACT_APP_REGION,
                custom_header: async () => setCustomHeaders()
            },
            {
                name: "cognito",
                endpoint: 'https://' + REACT_APP_COGNITO_DOMAIN,
            },
        ],
    }
);

AWS.config.update({
    accessKeyId: REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
    region: REACT_APP_REGION,
});

ReactDOM.render(
    <Provider store={store}>
        <div className="container app-container">
            <App />
        </div>
    </Provider>,
    document.getElementById("root"),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({ onUpdate });
