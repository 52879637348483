//@flow

import React from "react";
import rollingImage from "assets/img/rolling.png";
import "../../../assets/scss/global.scss";
import styles from "./dataIsLoading.module.scss";
import { useTranslation } from "react-i18next";

const DataIsLoading = () => {
    const { t } = useTranslation();
    return (
        <div className={`${styles["loading-wrapper"]} ${styles["empty-data-loading"]}  d-flex  flex-column align-items-center `}>
            <div className={styles.rotating}>
                <img src={rollingImage} alt="rolling" />
            </div>
            <h3 className={` title  ${styles.title}`}>{t("Your data is currently loading")}</h3>
            <p>{t("It might take from up to 24 hours for the graphs to show.")} </p>
        </div>
    );
};

export default DataIsLoading;
