import React from "react";
import styles from "./input.module.scss";

const FormInput = ({ input, meta: { touched, error }, placeholder, ...rest }) => {
    let errorBlock = null;
    let borderColor = "";

    if (touched && error) {
        errorBlock = (
            <div className={styles["error-block"]}>
                <span>{ error }</span>
            </div>
        );
        borderColor = styles["input-error"];
    }

    return (
        <>
            <input {...rest} {...input}
                placeholder={placeholder && placeholder}
                className={`${styles.input} ${borderColor} style-input`}
            />
            { errorBlock }
        </>
    );
};

export default FormInput;
