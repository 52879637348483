// @flow
import React from "react";
import { RadioButton } from "../../components";
import "../../../assets/scss/global.scss";
type RegisterPremiumFormType = {
    setPaymentMethod: Function
}
import { useTranslation } from "react-i18next";

const RegisterPremiumForm = ({ setPaymentMethod }: RegisterPremiumFormType) => {
    const { t } = useTranslation();
    return (
        <form>
            <div className="form-row">
                <RadioButton isChecked={false} placeholder={t("Pay with Paypal")} onClick={setPaymentMethod("paypal")} />
            </div>
            <div className="form-row">
                <RadioButton isChecked={false} placeholder={t("Pay with Credit Card")} onClick={setPaymentMethod("creditCard")} />
            </div>
        </form>
    );
};

export default RegisterPremiumForm;
