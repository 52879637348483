import React from "react";

const ExclamationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#A97C21" viewBox="0 0 4 12" width="4" height="11">
            <path
                fill="#A97C21"
                d="M2.142 8.016a1.855 1.855 0 100 3.71 1.855 1.855 0 000-3.71zM.381 1.349l.3 5.484a.484.484 0 00.482.457h1.958c.257 0 .469-.2.483-.457l.299-5.484a.484.484 0 00-.483-.51H.864a.484.484 0 00-.483.51z"
            />
        </svg>
    );
};

export default ExclamationIcon;
