// React app startup tasks

import { createRequestTypes } from "helpers/constants";

export const STARTUP_LOAD_USER_AND_DOMAINS_DATA = createRequestTypes("STARTUP_LOAD_USER_AND_DOMAINS_DATA");

export const loadUserAndDomainsData= {
    request: request => ({ type: STARTUP_LOAD_USER_AND_DOMAINS_DATA.REQUEST, request }),
    success: () => ({ type: STARTUP_LOAD_USER_AND_DOMAINS_DATA.SUCCESS }),
    error:   error => ({ type: STARTUP_LOAD_USER_AND_DOMAINS_DATA.FAILURE, error })
};
