import { all , put} from "redux-saga/effects";
import * as startupActions from "actions/startup";

import AuthSagas from "../components/dashboard/sagas";
// import SignUpSagasDEPRECATED from "../components/signUp_DERPECATED/sagas";
import CloudSitemapStatusSagas from "../components/cloudSitemapStatus/sagas";
import StartupSagas from "./startupSagas";
import CloudSitemapSagas from "./cloudSitemapSagas";
// import SignUpSagas from "components/signupOld/sagas";
import SignUp from "../components/signup/redux/sagas/saga"
import SaveSettingsSagas from "../components/settings/sagas";
import SaveAccountSettingsSagas from "../components/accounts/sagas";
import SignInSagas from "../components/signIn/redux/sagas/saga";
import PaymentSagas from "../components/settings/payment/sagas";
import PaymentBraintreeSagas from "../components/paymentBraintree/sagas";
import PlansSagas from "../components/pricingPlans/sagas";
// import SubscriptionInfoSagas from "../components/accounts/paymentStatus/sagas";
import CsCreateAndConfirmSiteSagas from "../components/installation/csCreateAndConfirmSite/sagas";
import Installation from "../components/installation/sagas";
import CentralSiteInstallDomainSagas from "../components/installation/sagas/centralSite/installDomainSagas";
import ClientSiteInstallDomainSagas from "../components/installation/sagas/clientSite/installDomainSagas";
import PaymentUpdateMethodSagas from "../components/paymentUpdateMethod/sagas";
import GoogleAuthForSiteSagas from "./googleAuthForSiteSagas";

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Root saga which include all sagas in project
 */
export default function* rootSaga() {

    const sagas = [
        ...StartupSagas,
        ...GoogleAuthForSiteSagas,
        ...CloudSitemapSagas,
        ...AuthSagas,
        // ...SignUpSagas,
        ...CsCreateAndConfirmSiteSagas,
        // ...SignUpSagasDEPRECATED,
        ...SaveSettingsSagas,
        ...SaveAccountSettingsSagas,
        ...SignInSagas,
        ...PaymentSagas,
        ...PaymentBraintreeSagas,
        ...PaymentUpdateMethodSagas,
        ...PlansSagas,
        ...SignUp,
        // ...SubscriptionInfoSagas,
        ...Installation,
        ...CentralSiteInstallDomainSagas,
        ...ClientSiteInstallDomainSagas,
        ...CloudSitemapStatusSagas
    ];

    yield all( sagas );

}
