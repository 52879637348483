import * as actions from "actions/user";

const initialState = {
    wpToken: "",
    user: null,
    userData: null,
    sites: [],
    activeSite: null,
    isAuthenticated: false,
    cloudSitemapStatus: { status: "unknown" },
    editDomainLoader: false,
    message:'',
    loading:false,
    isError:false,
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Sign Up Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const user = (state = initialState, action) => {
    switch (action.type) {
    
        case actions.SET_USER:
            return Object.assign({}, state, {
                user: action.data,
                userData: action.data,
            });

        case actions.SET_ISAUTHENTICATED:
            return Object.assign({}, state, {
                isAuthenticated: action.data,
            });

        case actions.SET_IS_WP_AUTHENTICATED:
            return Object.assign({}, state, {
                isWpAuthenticated: action.data,
            });

        case actions.SET_USER_DATA.REQUEST:
            return Object.assign({}, state, {
                userData: {},
                message:'',
                loading:true,
                isError:false
            });
        case actions.SET_USER_DATA.SUCCESS:
            return Object.assign({}, state, {
                userData: action.success,
                message:'User signin success',
                loading:false,
                isError:false
            });
        case actions.SET_USER_DATA.FAILURE:
            return Object.assign({},state,{
                userData:{},
                message:'Error while signin:'+action.data,
                loading:false,
                isError:true,
            })
        case actions.SET_TOKEN:
            return Object.assign({}, state, {
                wpToken: action.data,
            });

        case actions.SET_DOMAINS_DATA:
            return Object.assign({}, state, {
                sites: action.data,
                //activeSite: action.data[0],
            });

        case actions.SET_ACTIVE_DOMAIN:
            return Object.assign({}, state, {
                activeSite: action.data,
            });

        case actions.SET_USER_DOMAINS:
            return Object.assign({}, state, {
                sites: action.data,
            });

            case actions.DELETE_DOMAIN_DATA.REQUEST:
                return Object.assign({}, state, {
                    editDomainLoader :true
                    // sites: action.data,
                });

                case actions.DELETE_DOMAIN_DATA.SUCCESS:
                    return Object.assign({}, state, {
                        editDomainLoader :false,
                        deletedDomain: action.response,
                    });

                    case actions.DELETE_DOMAIN_DATA.FAILURE:
                        return Object.assign({}, state, {
                            editDomainLoader :false
                            // sites: action.data,
                        });

        case actions.EDIT_DOMAIN_DATA.REQUEST:
            return Object.assign({}, state, {
                editDomainLoader :true
            });

        case actions.EDIT_DOMAIN_DATA.SUCCESS:
            return Object.assign({}, state, {
                editDomainLoader :false,
            });

        case actions.EDIT_DOMAIN_DATA.FAILURE:
            return Object.assign({}, state, {
                editDomainLoader :false
            });

        case actions.UPDATE_ACTIVE_DOMAIN:
            return Object.assign({}, state, {
                activeSite: action.data,
                sites: state.sites.map(siteItem => siteItem.domain === action.data.domain ? action.data : siteItem)
            });

        case actions.UPDATE_CLOUD_SITEMAP_STATUS:
            return Object.assign({}, state, {
                cloudSitemapStatus: action.data
            });

        case actions.LOGOUT:
            return Object.assign({}, state, {
                activeSite: "",
                sites: [],
                userData: null,
                wpToken: "",
                user: null,
            });
        default:
            return state;
    }
};

export default user;
