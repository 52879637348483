import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
};

const fetchPlans = ( state = initialState, action) => {
    switch (action.type) {

        case actions.FETCH_PLANS.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error,
            });

        case actions.FETCH_PLANS.SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                message: "",
                plansData: action.data
            });

        case actions.FETCH_PLANS.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });

        default:
            return state;
    }
};

export default fetchPlans;
