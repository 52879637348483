import * as actions from "../actions";
import { googleWebmasterHeaderConfig } from "../mockedData";

const initialState = {
    isLoading:true,
    loading: false,
    headerLoading: false,
    rowsLoading: false,
    chartLoading: false,
    message: null,
    headerMessage: null,
    rowsMessage: null,
    chartMessage: null,
    rowsData: [],
    headerData: googleWebmasterHeaderConfig,
    chartData: [],
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Auth reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const googleWebmasterSection = (state = initialState, action) => {
    switch (action.type) {

        case actions.GET_GOOGLE_WEBMASTER_DATA.FAILURE:
            return Object.assign({}, state, {
                isLoading:false,
                loading: false,
                message: action.error.message,
            });

        case actions.GET_GOOGLE_WEBMASTER_DATA.SUCCESS:
            return Object.assign({}, state, {
                isLoading:false,
                rowsData: action.response.rowsData,
                headerData: action.response.headerData,
                chartData: action.response.chartData,
                loading: false,
                message: null,
            });

        case actions.GET_GOOGLE_WEBMASTER_DATA.SUCCESS_HEADER_DATA:
            return Object.assign({}, state, {
                isLoading:false,
                headerData: action.response.headerData,
                headerLoading: false,
                headerMessage: null,
            });

        case actions.GET_GOOGLE_WEBMASTER_DATA.SUCCESS_CHART_DATA:
            return Object.assign({}, state, {
                isLoading:false,
                chartData: action.response.chartData,
                chartLoading: false,
                chartMessage: null,
            });

        case actions.GET_GOOGLE_WEBMASTER_DATA.SUCCESS_ROWS_DATA:
            return Object.assign({}, state, {
                isLoading:false,
                rowsData: action.response.rowsData,
                rowsLoading: false,
                rowsMessage: null,
            });

        case actions.GET_GOOGLE_WEBMASTER_DATA.REQUEST:
            return Object.assign({}, state, {
                isLoading:true,
                loading: true,
                headerLoading: true,
                rowsLoading: true,
                chartLoading: true,
                message: null,
                headerMessage: null,
                rowsMessage: null,
                chartMessage: null,
                rowsData: [],
                headerData: googleWebmasterHeaderConfig,
                chartData: [],
            });

        default:
            return state;
    }
};

export default googleWebmasterSection;
