//@flow
import React from "react";
import styles from "./checkbox.module.scss";

const Checkbox = () => {
    return (
        <label className={styles.container}>
            <input type="checkbox" />
            <span className={styles.checkmark}></span>
        </label>
    );
};

export default Checkbox;
