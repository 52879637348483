// @flow
const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOOGLE_CLIENT_SECRET, REACT_APP_OAUTH_REDIRECT_URL } = process.env;
import { GOOGLE_TOKENS } from "constants/index";
import { Storage } from "helpers/index";
import { google } from "googleapis";
import { w3logger } from 'logger'



/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Component for work with Google API
 */
class GoogleApiHelper {

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Google webmaster instance
     */
    static googleWebMaster = null;
    static analyticsReporting = null;
    static googleAnalitics = null;
    

    static oAuthClient = new google.auth.OAuth2(
        REACT_APP_GOOGLE_CLIENT_ID,
        REACT_APP_GOOGLE_CLIENT_SECRET,
        REACT_APP_OAUTH_REDIRECT_URL,
    );

    static scopes = [
        "https://www.googleapis.com/auth/webmasters",
        "https://www.googleapis.com/auth/webmasters.readonly",
        "https://www.googleapis.com/auth/indexing",
        "https://www.googleapis.com/auth/analytics.readonly",
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/analytics"
    ];

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns URL for authorization
     * @return { String } - Auth URL
     */
    static getAuthUrl = () => this.oAuthClient.generateAuthUrl({
        access_type: "offline",
        scope: this.scopes.join(" "),
        state: window.location.href
    });

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function for setting token to oauth client
     */
    static setTokens = async (code: string, type:string) => {
        const { tokens } =  await this.oAuthClient.getToken(code);
        // let tokens = {
        //     access_token: 'ya29.a0AfH6SMDyUlwIav0CyEI_uQIlvUjfvvBe3N5bFnVaYhu5cRNua0maDpCd4ro71b_JPU-RC-_-eznhJfJlns6FhR7quyBkwwkL1SJOUPa2wFw8ogP2fxROKuorjc_zePVY6fU90PImXEQc2Y4FS0oA9xfu0hShVwYiHKI',
        //     refresh_token: '1//0gvNrYn2evzAHCgYIARAAGBASNwF-L9IrdSBBtM0vlfPs0pX2gXmPYlrypqzbEe8zDIAsxrLRahSxTrRTcrfVJblUapGNT_BbeGc',
        //     scope: 'https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/indexing https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/webmasters',
        //     token_type: 'Bearer',
        //     id_token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImJjNDk1MzBlMWZmOTA4M2RkNWVlYWEwNmJlMmNlNDM3ZjQ5YzkwNWUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIzMDk1ODM3OTc2MS1oMmgwZ3EwZWk5a3Z2NmdiOHAzcTlsbW81NmN1YjI2NC5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsImF1ZCI6IjMwOTU4Mzc5NzYxLWgyaDBncTBlaTlrdnY2Z2I4cDNxOWxtbzU2Y3ViMjY0LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA2MjUyNTYzNjQ1ODU2MTQzNzAyIiwiZW1haWwiOiJ2aWtyYW50LmFkZHdlYkBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IkwzYVkwT19nYWlLZ0JPd05OQUNLM0EiLCJpYXQiOjE1OTk0Nzg4OTMsImV4cCI6MTU5OTQ4MjQ5M30.epcu6F_y1TJ4U-uW1XbY6_Cvd6wJP2rfYNRAgn8BVsJYNSx7fPyZVGgESPYn-IR1Fb2mwI4J95aBjStMLLXLjmKwx-DQOm_A7thnOmLpXuHRQipY6UcbVjFxSi5qrEPDkp_vaGZJOrnqihaNsfmpm1qe6Xrt-iZlRATNLUCDk2m-6MfHZqW3oQpQIQTD7jR-sZMPG0BtZneM6BHyfWad5mfBV17z0c3z9w2qfSH7oqzCkuWZH5ixAVrYBa4Ao4xoQQx9L8qUmk-Z5C0ZfejbkM7VEzpwaiB6T7f5vUJ3T-YB-aA7fohyvjKrn8XAe_baLVX_9rvj3PiloFSkU54Rzg',
        //     expiry_date: 1599482492325,
        //     iat: 1599478893
        // }
        if(tokens) {
            Storage.setItem(GOOGLE_TOKENS, tokens);
            this.oAuthClient.setCredentials(tokens);
            this.setAllInstance()
                    
            return tokens
        }
    };


    static setAllInstance = async () => {
        this.googleWebMaster = await google.webmasters({
            version: "v3",
            auth: this.oAuthClient,
        });

        this.analyticsReporting = await google.analyticsreporting({
            version: "v4",
            auth: this.oAuthClient,
        });
        
        this.googleAnalitics = await google.analytics({
            version: 'v3',
            auth: this.oAuthClient,
        });
    }

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns webmaster instance
     */
    static getAnalyticsReport = async () => {
        const tokens = Storage.getItem(GOOGLE_TOKENS);
       this.oAuthClient.setCredentials(tokens);
        if(this.analyticsReporting === null) {
            if(await this.isAuthorized) {
                this.analyticsReporting = google.analyticsreporting({
                    version: "v4",
                    auth: this.oAuthClient,
                });
            } else {
                return null;
            }
        }
        return this.analyticsReporting;
    };

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns webmaster instance
     */
    static onLogout = () => {
       localStorage.removeItem(GOOGLE_TOKENS);
    };

    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns webmaster instance
     */
    static getGoogleAnalytics = async () => {
       const tokens = Storage.getItem(GOOGLE_TOKENS);
       this.oAuthClient.setCredentials(tokens);
        if(this.analyticsReporting === null) {
            if(await this.isAuthorized) {
                this.googleAnalitics = await google.analytics({
                    version: 'v3',
                    auth: this.oAuthClient,
                });
            } else {
                return null;
            }
        }
        return this.googleAnalitics;
    };


     /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns webmaster instance
     */
    static getWebmaster = async () => {
        const tokens = Storage.getItem(GOOGLE_TOKENS);
       this.oAuthClient.setCredentials(tokens);
        if(this.googleWebMaster === null) {
            if(await this.isAuthorized) {
                this.googleWebMaster = google.webmasters({
                    version: "v3",
                    auth: this.oAuthClient,
                });
            } else {
                return null;
            }
        }
        return this.googleWebMaster;
    };


    /**
     * @author Roman Yephimov <ry@piogroup.net>
     * @desc Function which returns webmaster instance
     */
    static isAuthorized = () => {
         return google.oauth2('v2').tokeninfo({
                auth: this.oAuthClient
            }).then(response => {
                if(response.data.verified_email) {
                    return true
                } else {
                    return false
                }
            }).catch(err=>{
                return false
            })
    };

   static getUserInfo = () => {
        return google.oauth2('v2').userinfo.get({
            auth: this.oAuthClient,
        }).then(response => {
            return response
        }).catch(err=>{
            w3logger('err', err)
            return err
        })
    };


    static storeToken = (tokens: Object) => {
        if(tokens) {
            Storage.setItem(GOOGLE_TOKENS, tokens);
            this.oAuthClient.setCredentials(tokens);
            this.setAllInstance();
        }
    };

    static getUserSitesList = async () => {
        if (! await this.isAuthorized) return;
        const sites = await this.getWebmaster();
        return sites && sites.sites && await sites.sites.list();
    };

    static getIndexingData = async (site: string) => {
        if (! await this.isAuthorized) return;
        const { access_token } = Storage.getItem(GOOGLE_TOKENS) || {};
        return await fetch(`https://indexing.googleapis.com/v3/urlNotifications/metadata?url=${encodeURI(site)}&access_token=${access_token}`);
    };
}

export default GoogleApiHelper;
