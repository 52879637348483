// React app startup tasks

import { createRequestTypes } from "helpers/constants";

export const GOOGLE_AUTH_FOR_SITE = createRequestTypes("GOOGLE_AUTH_FOR_SITE");

export const getGoogleAccessTokenForSite = {
    request: request => ({ type: GOOGLE_AUTH_FOR_SITE.REQUEST, request }),
    success: () => ({ type: GOOGLE_AUTH_FOR_SITE.SUCCESS }),
    error:   error => ({ type: GOOGLE_AUTH_FOR_SITE.FAILURE, error })
};
