// @flow

import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { googleWebMaserChartConfig, bingWebMaserChartConfig, googleAnalyticsChartConfig } from "../../../config/chartsConfigs";
import { w3logger } from 'logger'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import type { LineChartPropTypes } from "./LineChartTypes";

const chartDataOffline = [
  {
    date: "Jan 2019",
    name: "15 Nov 2019",
    Clicks: 10,
    Impressions: 12,
    CTR: "70.00",
    Position: "0.63"
  },
  {
    date: "Feb 2019",
    name: "16 Nov 2019",
    Clicks: 130,
    Impressions: 123,
    CTR: "60.00",
    Position: "7.63"
  },
  {
    date: "Mar 2019",
    name: "23 Nov 2019",
    Clicks: 320,
    Impressions: 14,
    CTR: "22.00",
    Position: "4.00"
  },
  {
    date: "Apr 2019",
    name: "24 Nov 2019",
    Clicks: 320,
    Impressions: 100,
    CTR: "92.00",
    Position: "5.63"
  },

  {
    date: "May 2019",
    name: "01 Dec 2019",
    Clicks: 430,
    Impressions: 60,
    CTR: "25.00",
    Position: "0.00"
  }
];

const LineChartComponent = (props: LineChartPropTypes) => {
  const { chartData } = props;
  if (props.name === "googleWebMaster") {
    let dataKey;
    let maxPeak = 0;

    if (props.selected === "Total Clicks") {
      dataKey = "Clicks";
      if(chartData.length > 0){
        maxPeak = chartData.reduce((p, c) => parseInt(p.Clicks) > parseInt(c.Clicks) ? p : c);
        maxPeak = maxPeak.Clicks;
      }
    } else if (props.selected === "Average Views") {
      dataKey = "Impressions";
      if(chartData.length > 0){
        maxPeak = chartData.reduce((p, c) => parseInt(p.Impressions) > parseInt(c.Impressions) ? p : c);
        maxPeak = maxPeak.Impressions;
      }
    } else if (props.selected === "Average CTR") {
      dataKey = "CTR";
      if(chartData.length > 0){
        maxPeak = chartData.reduce((p, c) => parseInt(p.CTR) > parseInt(c.CTR) ? p : c);
        maxPeak = maxPeak.CTR;
      }
    } else if (props.selected === "Average Position") {
      dataKey = "Position";
      if(chartData.length > 0){
        maxPeak = chartData.reduce((p, c) => parseInt(p.Position) > parseInt(c.Position) ? p : c);
        maxPeak = maxPeak.Position;
      }
    }

    return (
      <ResponsiveContainer aspect={3}>
        <LineChart data={chartData} margin={{ top: 30, right: 30, left: 30, bottom: 30 }}>
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis domain={[0, parseInt(maxPeak)]}  allowDecimals={false}/>
          <CartesianGrid strokeDasharray="5 8" vertical={false} />
          <Tooltip />
          <Legend />
          {googleWebMaserChartConfig.map((item, i) => {
            w3logger("crawlState:",item.name);
            if (item.name === dataKey) {
              return <Line key={i} type="linear" dataKey={item.name} dot={{ fill: item.color, stroke: "none" }} stroke={item.color} fillOpacity={1} fill={`url(#${item.name})`} />;
            }
          })}
          );
        </LineChart>
      </ResponsiveContainer>
    );
  } else if(props.name === "bingWebMaster"){
    let dataKey;

    if (props.selected === "Pages Crawled") {
      dataKey = "CrawledPages";
    } 

    if (props.selected === "Clicks") {
      dataKey = "Clicks";
    } 
    return (
      <ResponsiveContainer aspect={3}>
        <LineChart data={chartData} margin={{ top: 30, right: 30, left: 30, bottom: 30 }}>
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <CartesianGrid strokeDasharray="5 8" vertical={false} />
          <Tooltip />
          <Legend />
          {bingWebMaserChartConfig.map((item, i) => {
            w3logger("crawlState:",item.name);
            if (item.name === dataKey) {
              return <Line key={i} type="linear" dataKey={item.name} dot={{ fill: item.color, stroke: "none" }} stroke={item.color} fillOpacity={1} fill={`url(#${item.name})`} />;
            }
          })}
          );
        </LineChart>
      </ResponsiveContainer>
    );
  } else if (props.name === "googleAnalytics") {
    let dataKey;

    dataKey = "uniquePageviews";
    let maxPeak = 0;
    if(chartData.length > 0){
      maxPeak = chartData.reduce((p, c) => parseInt(p.uniquePageviews) > parseInt(c.uniquePageviews) ? p : c);
      maxPeak = maxPeak.uniquePageviews;
    }

    return (
      <ResponsiveContainer aspect={3}>
        <LineChart data={chartData} margin={{ top: 30, right: 30, left: 30, bottom: 30 }}>
          <XAxis   dataKey="date" padding={{ left: 30, right: 30 }} />
          <YAxis domain={[0, parseInt(maxPeak)]}  allowDecimals={false} />
          <CartesianGrid strokeDasharray="5 8" vertical={false} />
          <Tooltip />
          <Legend />
          {googleAnalyticsChartConfig.map((item, i) => {
            if (item.name === dataKey) {
              return <Line key={i} type="linear" dataKey={item.name} dot={{ fill: item.color, stroke: "none" }} stroke={item.color} fillOpacity={1} fill={`url(#${item.name})`} />;
            }
          })}
          );
        </LineChart>
      </ResponsiveContainer>
    );
  } else {
    return null;
  }
};

export default LineChartComponent;
