import { connect } from "react-redux";
import type { ElementType } from "react";
import React from "react";
import { Route } from "react-router-dom";
import SocialButtons from "./social-buttons";
import { Heading } from "common/components";
import "./../../assets/scss/global.scss";
import { Auth } from "aws-amplify";
import { buttonsConfig } from "./buttonsConfigData";
import "../../assets/css/Innerglobal.css";

export type SignUpRoutePropsType = {
    component: ElementType,
    layout: ElementType,
    activeSite: any
};

import { useTranslation } from "react-i18next";

const mapStateToProps = state => {
    return {
        activeSite: state.user.activeSite
    };
};

const onClickSocialBtn = (type: string) => {

    if (type === 'google') {
        // Auth.signOut({ global: true })
        // .then(data => console.log(data))
        // .catch(err => console.log(err));

        window.location.href = `${process.env.REACT_APP_OAUTH_REDIRECT_URL}/?doLogin=google&redirect_url=${window.location.href.replace("#/sign-up", "")}`;


        // Auth.federatedSignIn({provider: 'Google', customState: '?site_domain='+window.location.href})
    }
}

const SignUpRoute = (
    { component: Component, layout: Layout, activeSite: activeSite, ...rest }: SignUpRoutePropsType
    ) => {
        const { t } = useTranslation();

        let actSite = activeSite;

    return (
        <Route
            {...rest} render={props => {
                if(actSite && actSite.domainOwnershipConfirmed) {
                    // People should not visit this screen because they are logged in!
                    // We will redirect users to either dashboard or premium payment page 
        
                    if(actSite.type == "premium") {
                        props.history.push("/dashboard", "");
                    } else if(actSite.type == "community") {
                        props.history.push("/payment-braintree", "");
                    }
                }

                let link = window.location.search == "" ? `${window.location.origin}${window.location.pathname}#/sign-in` : `${window.location.origin}${window.location.pathname}${window.location.search}#/sign-in`;
                let URL = window.location.href.split('/');
                let loginLink = URL[URL.length - 1] == "sign-up" ? true : URL[URL.length - 2] == "sign-up" && URL[URL.length - 1] == "" ? true : false;

                const { activeSite = null } = props;
                let { targetPlan = null } = props.match && props.match.params ? props.match.params : {};

                let targetPlanLabel = "";

                if (targetPlan && ["community", "premium", "enterprise"].indexOf(targetPlan) >= 0) {
                    targetPlanLabel = targetPlan;
                } else {
                    targetPlan = "";
                }

                let title1 = "";
                let subtitle = "";

                if (targetPlan === "community") {
                    title1 = t("Get started absolutely free.");
                    subtitle = t("Free forever. No credit card needed.");
                } else if (targetPlan === "premium") {
                    title1 = t("Get started with the Premium plan.");
                    subtitle = `$${localStorage.planPrice}/year. ` + t("No contracts, cancel anytime.");
                } else {
                    title1 = "";
                    subtitle = "";
                }

                // if(targetPlan){
                //     link += "/target-plan/" + targetPlan;
                // }

                return (
                    <Layout>
                        <section className={`section-fixed-top section ${loginLink == true ? 'signup-section' : ''}`}>
                            <Heading
                                title={title1}
                                subtitle={subtitle}
                                align="center"
                                className={`top-space`}
                            />
                            <div className="section-body">
                                <div className="bg-white with-shadow sign-up-form">
                                    {(/sign-up/.test(window.location.href) && !(/code-verify/.test(window.location.href))) && (
                                        <SocialButtons targetPlan={targetPlan} onClickBtn={type => onClickSocialBtn(type)}
                                            buttonsConfig={buttonsConfig} history={props.history} />
                                    )}
                                    <Component {...props} />
                                </div>
                            </div>
                        </section>
                        {(/sign-up/.test(window.location.href) && !(/code-verify/.test(window.location.href))) && (
                            <div className="text-middle">
                                <span>{t("Already have an account?")}</span>
                                <a href={link}>{t("Login here.")}</a>
                            </div>
                        )}
                    </Layout>
                )
            }
            }
        />
    );
};

export default connect(mapStateToProps)(SignUpRoute);
