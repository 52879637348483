import { createRequestTypes } from "helpers/constants";
// ON WP SIDE:

export const INSTALLATION_CLIENT_READ_INSTALLATION_COOKIE = createRequestTypes("INSTALLATION_CLIENT_READ_INSTALLATION_COOKIE");

export const installationCookieParsed = {
    request: request => ({ type: INSTALLATION_CLIENT_READ_INSTALLATION_COOKIE.REQUEST, request }),
    success: response => ({ type: INSTALLATION_CLIENT_READ_INSTALLATION_COOKIE.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_CLIENT_READ_INSTALLATION_COOKIE.FAILURE, error })
};


// **** START OF QUICK UPGRADE FLOW:

// SET TARGET DOMAIN:
export const INSTALLATION_CLIENT_ENTERED_DOMAIN_URL = "INSTALLATION_CLIENT_ENTERED_DOMAIN_URL";
export const enteredDomainUrl = targetDomainData => {return {type: INSTALLATION_CLIENT_ENTERED_DOMAIN_URL, data: targetDomainData }};


// SET CHOSEN PRICING PLAN:
export const INSTALLATION_CLIENT_PLAN_CHOSEN = "INSTALLATION_CLIENT_PLAN_CHOSEN";
export const planChosen = planData => {return {type: INSTALLATION_CLIENT_PLAN_CHOSEN, data: planData }};

// SET PAYMENT INFO:
export const INSTALLATION_CLIENT_PAYMENT_COMPLETED = createRequestTypes("INSTALLATION_CLIENT_PAYMENT_COMPLETED");

export const paymentCompleted = {
    request: request => ({ type: INSTALLATION_CLIENT_PAYMENT_COMPLETED.REQUEST, request }),
    success: response => ({ type: INSTALLATION_CLIENT_PAYMENT_COMPLETED.SUCCESS, response }),
    error:   error => ({ type: INSTALLATION_CLIENT_PAYMENT_COMPLETED.FAILURE, error })
};

// **** END OF QUICK UPGRADE FLOW
