import dotenv from "dotenv";
import { put, take, fork, call } from "redux-saga/effects";
import { API,Auth } from "aws-amplify";
import * as cloudSitemapActions from "actions/cloudSitemap";
import * as userActions from "actions/user";
import { getCurrentUrlProtocol, isInsideWpAdmin } from "../helpers/installationFlowHelper";
import { logWrite } from "../logger";
import { CLOUD_SITEMAP_BUILD } from "../actions/cloudSitemap";
import { w3logger } from 'logger'

dotenv.load();

const {
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE,
    REACT_APP_MOCK,
    REACT_APP_SITEMAPS_API_GATEWAY_URL,
    REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL
} = process.env;

/***
 * Start sitemap regeneration in cloud
 * @returns {IterableIterator<<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>>}
 */
function* watchBuildCloudXmlSitemap() {
    while (true) {
        logWrite( ">>>watchBuildCloudXmlSitemap - running" );

        const { request } = yield take( cloudSitemapActions.CLOUD_SITEMAP_BUILD.REQUEST );

        logWrite( ">>>watchBuildCloudXmlSitemap - got request:" );
        logWrite( request );
        const { domain, sitemapIndex,userId, generatetype="Build Sitemap Button Click"} = request;
        const generateAt = new Date();
        
        try {
            if ( !domain ) {
                throw new Error( "watchBuildCloudXmlSitemap - Invalid domain provided." );
            }
            
            
            let jsonData = null;

            if (REACT_APP_MOCK === 'true') {
                jsonData = { data: {} };
            } else {
                // reall call:

                let reqBody = { domain, sitemapIndex };
                if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
                    // localhost:
                    logWrite( "localhost connect" );
                    let response = yield fetch( `${REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL}/build/buildSitemapTypes`, {
                        method: "POST",
                        body: JSON.stringify( reqBody )
                    } );
                    // let responsedata = yield fetch(`${REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL}/build/sitemapstatistic`, {
                    //     method: "POST",
                    //     body: JSON.stringify(statsreqBody)
                    // });
                    // let responsedataObject = yield responsedata.json();
                    w3logger("responsedataObject:");
                    // w3logger(responsedataObject);

                    let responseObject = yield response.json();

                    if (typeof responseObject === "string") {
                        responseObject = JSON.parse(responseObject);
                    }

                    w3logger( "responseObject:" );
                    w3logger( responseObject );

                    jsonData = JSON.parse( responseObject.body ).data;
                    w3logger( "the json data:" );
                    w3logger( jsonData );


                } else {
                    // staging / prod:
                    logWrite( "prod connect!!!!" );
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    let user;
                    if(userId) {
                        user = userId
                    } else {
                        const userDetails = yield Auth.currentAuthenticatedUser()
                        const user = userDetails.attributes.name
                    }

                    // returns response object, with property body as string
                    let statsreqBody = { body: { domain, userId : user, generatetype, generateAt } }
                    
                    let responseObject = yield API.post("auctollo-sitemap-build-api", "/build/buildSitemapTypes", apiCallConfig);
                    let responsedata = yield API.post("auctollo-sitemap-build-api", "/build/sitemapstatistic", statsreqBody);
                    jsonData = JSON.parse(responseObject.body).data;   
                    //    jsonData = JSON.parse(responsedata.body).data;
                    // w3logger("jsonData:", jsonData);
                }
                
            }
            
            logWrite( "Got the output from regenerateSitemap endpoint:" );
            logWrite( jsonData );
            
            yield put( cloudSitemapActions.buildCloudSitemap.success( jsonData ) );

            logWrite( ">>>watchBuildCloudXmlSitemap successfully completed" );
        } catch (e) {
            w3logger( "error in watchBuildCloudXmlSitemap" );
            w3logger( e );
            yield put( cloudSitemapActions.buildCloudSitemap.error( e.message ) );
        }
    }
}

/***
 * Build Sitemap Index in Cloud
 * @returns {IterableIterator<<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>>}
 */
function* watchBuildCloudXmlSitemapIndex() {
    while (true) {
        logWrite( ">>>watchBuildCloudXmlSitemapIndex - running" );

        const { request } = yield take( cloudSitemapActions.CLOUD_SITEMAP_INDEX_BUILD.REQUEST );

        logWrite( ">>>watchBuildCloudXmlSitemapIndex - got request:" );
        logWrite( request );

        const { domain, protocol, permalinksUsed } = request;

        try {
            if ( !domain ) {
                throw new Error( "watchBuildCloudXmlSitemapIndex - Invalid domain provided." );
            }

            let response = {};
            let responseObject = {};
            let jsonData = null;

            if ( REACT_APP_MOCK === 'true' ) {
                jsonData = { data: {} };
            } else {
                // reall call:

                let reqBody = { domain };
                if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
                    // localhost:
                    logWrite( "localhost connect" );
                    let response = yield fetch( `${REACT_APP_API_SITEMAP_BUILD_API_GATEWAY_URL}/build/buildSitemapTypes`, {
                        method: "POST",
                        body: JSON.stringify( reqBody )
                    } );

                    let responseObject = yield response.json();

                    if ( typeof responseObject === "string" ) {
                        responseObject = JSON.parse( responseObject );
                    }

                    w3logger( "responseObject:" );
                    w3logger( responseObject );

                    jsonData = JSON.parse( responseObject.body ).data;
                    w3logger( "the json data:" );
                    w3logger( jsonData );


                } else {
                    // staging / prod:
                    logWrite( "prod connect!!!!" );
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    // returns response object, with property body as string
                    let responseObject = yield API.post( "auctollo-sitemap-build-api", "/build/buildSitemapTypes", apiCallConfig );
                    jsonData = JSON.parse( responseObject.body ).data;
                }
            }

            logWrite( "Got the output from regenerateSitemap endpoint:" );
            logWrite( jsonData );

            yield put( cloudSitemapActions.buildCloudSitemapIndex.success( jsonData ) );

            logWrite( ">>>watchBuildCloudXmlSitemapIndex successfully completed" );
        } catch (e) {
            w3logger( "error in watchBuildCloudXmlSitemapIndex" );
            w3logger( e );
            yield put( cloudSitemapActions.buildCloudSitemapIndex.error( e.message ) );
        }
    }
}

function start() {
    logWrite( ">>>cloudSitemapSagas starting.." );
    return [
        fork( watchBuildCloudXmlSitemap ),
        fork( watchBuildCloudXmlSitemapIndex )
    ];
}

export default start();
