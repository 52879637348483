import { logWrite } from "logger";
import { call, fork, put, take } from "@redux-saga/core/effects";
import {cloudApiCall } from "api/apiClient";
import * as actions from "actions/googleAuthForSite";
import { w3logger } from 'logger'

/***
 * Get google access token for this site (if any)
 * Returned object should have this form: {lastAccessToken, expireTs}
 * @param request
 * @returns {IterableIterator<<"CALL", CallEffectDescriptor>>}
 */
function* getGoogleAccessToken( request ) {
    logWrite( ">>> saga getGoogleAccessToken started..." );
    const { activeSite } = request;
    const { domain } = activeSite;

    logWrite( "request provided:" );
    logWrite( request );

    if ( !domain ) {
        logWrite( ">>> saga getGoogleAccessToken  - empty field - domain" );
        yield null;
        return null;
    }

    let jsonData = null;
    try {
        if ( process.env.REACT_APP_MOCK === 'true' ) {
            jsonData = { data: {} };
        } else {
            // reall call:

            let {protocol} = activeSite;
            if ( [ "http", "https" ].indexOf( protocol ) < 0 ) {
                throw new Error( "Invalid protocol provided in getGoogleAccessToken saga" );
            }
            let reqBody = { domain, protocol };

            const callRequest = {
                relativeUrl: "sites/googleAuthGetAT",
                apiStack: "auctollo-sites-api",
                data: reqBody,
                method: "POST"
            };

            jsonData = yield call(cloudApiCall, callRequest);
            jsonData = jsonData.data;

            w3logger("json data:");
            w3logger(jsonData);

            // {lastAccessToken, expireTs} expected here...

            return jsonData;
        }
    } catch (e) {
        logWrite( "Error in getGoogleAccessToken::" );
        logWrite( e );
        throw e;
    }
}

/***
 * Obtain new Google access token for the current domain, if the site is connected in Settings.
 * @returns {IterableIterator<IterableIterator<*>|<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>>}
 */
function* watchObtainGoogleAccessToken() {
    while (true) {
        logWrite( ">>>watchObtainGoogleAccessToken - started" );
        const { request } = yield take( actions.GOOGLE_AUTH_FOR_SITE.REQUEST );
        const { activeSite } = request;
        const { domain } = activeSite;

        logWrite( ">>>watchObtainGoogleAccessToken request:" );
        logWrite( activeSite );

        if ( !activeSite || !domain ) {
            logWrite( ">>>watchObtainGoogleAccessToken cannot proceed. Empty domain name or active site" );
            continue;
        }

        logWrite( ">>>watchObtainGoogleAccessToken - taken" );
        try {
            const jsonData = yield* getGoogleAccessToken( request );

            logWrite("Got google access token for the active domain:");
            logWrite(jsonData);

            yield put( actions.getGoogleAccessTokenForSite.success( jsonData ) );

        } catch (e) {
            logWrite( "error in watchObtainGoogleAccessToken" );
            logWrite( e );
            yield put( actions.getGoogleAccessTokenForSite.error( e ) );

            // redirect after success:
            alert( "error, now the user gets redirected to home page!" );
            history.push( "/dashboard" );
        }
    }
}


function start() {
    const forks = [
        fork( watchObtainGoogleAccessToken )
    ];

    return forks;
}

export default start();
