//@flow

import React from "react";
import gsx from "assets/img/gxs-logo-icon.svg";

//styles
import "../../../assets/scss/global.scss";
import styles from "./loaderElement.module.scss";
import { useTranslation } from "react-i18next";

const LoaderElement = () => {
    const { t } = useTranslation();
    return (
        <div className={`${styles["loading-wrapper"]}  wp-loader d-flex flex-column align-items-center `}>
            <div>
                <img src={gsx} alt="rolling" />
            </div>
            <h2 className={`${styles["loader-text"]}`}>{t("Loading...")}</h2>
        </div>
    );
};

export default LoaderElement;
