import React from "react";
import { connect } from "react-redux";
import { ButtonSignUp , LoaderElement } from "../../../common/components";
import "../../../assets/scss/global.scss";
import styles from "../SignUp.module.scss";
import { GoogleIcon, GitHubIcon, MicrosoftIcon } from "assets/icons";
import Auth from '@aws-amplify/auth'
import { isInsideWpAdmin, setInstallationOrigin } from "../../../helpers/installationFlowHelper";
import * as actions from "../actions";
import {googleURL} from "helpers/constants";
import { useTranslation } from "react-i18next";

const SocialButtons = props => {
    const { t } = useTranslation();

    const { buttonsConfig, onClickBtn, activeSite, targetPlan, sites, domainExist } = props;


    const checkUserDomain = () =>{
        // if(isInsideWpAdmin()){
        //     startFederatedSignInFromWp();
        // }else{
        //     // props.checkDomainExist({domain:"staging-site-111.auctollo.com"})
        //     startFederatedSignInOutsideOfWp();
        // }
        if(isInsideWpAdmin()){
            if(( /sign-up/.test( window.location.href ))) {
                props.checkDomainExist({domain: activeSite.domain,actionSuccess:()=>startFederatedSignInFromWp(), actionFailure:()=>domainExistFailure()})
            }else{
                startFederatedSignInFromWp();
            }
        }else{
            // alert("asdf")
            startFederatedSignInOutsideOfWp();
        }
    }

    const domainExistFailure = () =>{
        alert(t("Domain is already associated, Please Login!"));
        props.history.push("/sign-in");
    }

    const startFederatedSignInOutsideOfWp = () =>{
        //Auth.federatedSignIn({provider: "google"});
        window.location.href = googleURL;
        // alert('success');
    };

    const startFederatedSignInFromWp = () => {

        if(!activeSite){
            alert(t("No active site! Exiting"));
            return false;
        }

        let action = "login";
        if(targetPlan){
            if(["community", "premium", "enterprise"].indexOf(targetPlan) <0){
                alert(t("Invalid product plan provided."));
                return false;
            } else {
                action = "plan-" + targetPlan;
            }
        }

        action = encodeURIComponent(action);

        // alert("action = " + action);

        const {domain ="", protocol=""} = activeSite || {};

        if(!domain || !protocol){
            alert(t("Some site info is currently not available. Cannot continue with federated sign in"));
            return false;
        }

        //const encodedDomain = encodeURIComponent( "localhost:8071" );
        const encodedDomain = encodeURIComponent( domain );

        // todo, add nonce here:

        const redirectUrl = encodeURIComponent(protocol + "://" + domain +"/wp-admin/admin.php?page=google-sitemap-generator%2Fsitemap.php&gxs_action=federated-login");

        window.location.href = process.env.REACT_APP_CENTRAL_SITE_URL + "/#/installation/app/federated-login-for-cs/" + encodedDomain + "/" + redirectUrl + "/" + action;
    };

    const isHostedInsideWp = isInsideWpAdmin();

    return (
        <div className={styles[ "social-btn-group" ]}>
            
            {/* {
            props.loading==true ? <div> <LoaderElement /> </div> : <div></div>
            } */}

            {
                !isHostedInsideWp && <ButtonSignUp
                    url={"#"}
                    text={( /sign-up/.test( window.location.href )) ? t("Sign up with Google") : t("Sign In with Google")}
                    icon=<GoogleIcon/>
                color={"blue"}
                type={"google"}
                onClickBtn={() => !( /sign-up/.test( window.location.href )) ? startFederatedSignInOutsideOfWp() : checkUserDomain()} />
            }

            {
                isHostedInsideWp && <ButtonSignUp
                    url={"#"}
                    text={( /sign-up/.test( window.location.href )) ? t("Sign up with Google") : t("Sign In with Google")}
                    icon=<GoogleIcon/>
                    color={"blue"}
                    type={"google"}
                    loading={props.loading}
                    onClickBtn={() => !( /sign-up/.test( window.location.href )) ? startFederatedSignInFromWp() : checkUserDomain()} />
            }
        </div>
    );
};


const mapStateToProps = state =>{
   return {
        activeSite: state.user.activeSite,
        domainExist: state.domains,
        // loading: state.domains.checkDomainLoading
    } 
};

const mapDispatchToProps = ( dispatch, ownProps ) => {
    return {
        checkDomainExist: data => {
            dispatch(actions.checkDomainExist.request(data));
        },
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(SocialButtons);
