// React app - actions for building sitemap in cloud
import { createRequestTypes } from "helpers/constants";
import { w3logger, logError  } from 'logger'
// SITEMAP UNITS:
export const CLOUD_SITEMAP_BUILD = createRequestTypes("CLOUD_SITEMAP_BUILD");

w3logger(">>>CLOUD_SITEMAP_BUILD:");
w3logger(CLOUD_SITEMAP_BUILD);

export const buildCloudSitemap = {
    request: request => ({ type: CLOUD_SITEMAP_BUILD.REQUEST, request }),
    success: () => ({ type: CLOUD_SITEMAP_BUILD.SUCCESS }),
    error:   error => ({ type: CLOUD_SITEMAP_BUILD.FAILURE, error })
};

// INDEX BUILDING:
export const CLOUD_SITEMAP_INDEX_BUILD = createRequestTypes("CLOUD_SITEMAP_INDEX_BUILD");

w3logger(">>>CLOUD_SITEMAP_INDEX_BUILD:");
w3logger(CLOUD_SITEMAP_INDEX_BUILD);

export const buildCloudSitemapIndex = {
    request: request => ({ type: CLOUD_SITEMAP_INDEX_BUILD.REQUEST, request }),
    success: () => ({ type: CLOUD_SITEMAP_INDEX_BUILD.SUCCESS }),
    error:   error => ({ type: CLOUD_SITEMAP_INDEX_BUILD.FAILURE, error })
};
