import * as installationActions from "../components/installation/actions";
import { w3logger } from 'logger'

const { REACT_APP_SIMULATE_LOCAL_WP_ADMIN, REACT_APP_CENTRAL_SITE_URL } = process.env;

// global setting: switch ON/OFF:
export function isInstallationFlowEnabled() {
    return true;
}

/***
 * Returns true if the current url is on the app site:
 * @returns {boolean}
 */
export function isOnAppSite() {
    if ( window.location.href.indexOf( REACT_APP_CENTRAL_SITE_URL ) >= 0 ) {
        return true;
    } else {
        return false;
    }
}

/***
 * Returns true if the React app is running inside a wp-admin window:s
 * @returns {boolean}
 */
export function isInsideWpAdmin() {
    // REACT_APP_SIMULATE_LOCAL_WP_ADMIN means that site is running on locahost:3001 but communicating with docker wp on port localhost:8071
    // return true;
    if ( document.body.classList.contains( "wp-admin" ) || REACT_APP_SIMULATE_LOCAL_WP_ADMIN === "true" ) {
        return true;
    } else {
        return false;
    }
}

/***
 * Returns wp url
 * @returns {boolean}
 */
export function getWpUrl() {
    let url = window.location.href;
    return url.substring(0, url.indexOf("/wp-admin"));
}

/***
 * Returns wp url
 * @returns {boolean}
 */
export function getWpRedirectUrl() {
    return ( getParameterByName('state') && getParameterByName('state') != null) ? getParameterByName('state') : window.location.origin;
}
export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Get current domain by examining the current URL loaded in the browser
 * @returns {string}
 */
export function getCurrentDomainFromBrowser() {
    let { host } = window.location;
    return host;
}


/***
 * Returns protocol and host separated
 * @param fullUrl
 * @returns {null|{protocol: *, host: *}}
 */
export function getDomainComponents( fullUrl ) {
    if ( !fullUrl ) {
        return null;
    }
    fullUrl = fullUrl.trim( "/" );

    let protocol = "";
    let host = "";

    if ( fullUrl.indexOf( "https://" ) >= 0 ) {
        protocol = "https";
        host = fullUrl.replace( "https://", "" );
    } else if ( fullUrl.indexOf( "http://" ) >= 0 ) {
        protocol = "http";
        host = fullUrl.replace( "http://", "" );
    } else {
        throw new Error( "Invalid protocol detected in getDomainComponents()" );
    }
    return {
        protocol,
        host
    };
}

export function getCurrentUrlProtocol( ) {
    return window.location.protocol.replace( ":", "" );
}

// origin can be: app, wp
export function setInstallationOrigin( dispatch, currentUrl ) {
    if ( currentUrl.indexOf( "://app." ) ) {
        dispatch( installationActions.setInstallationOrigin( "app" ) );
    } else {
        dispatch( installationActions.setInstallationOrigin( "wp" ) );
    }
}

// flow can be: upgrade, new
export function setInstallationFlow( dispatch, upgradeData ) {
    if ( upgradeData && upgradeData.completed === false ) {
        // upgrade from the old plugin
        dispatch( installationActions.setInstallationFlow( "upgrade" ) );
    } else {
        // maybe further types of new will be needed here:
        dispatch( installationActions.setInstallationFlow( "new" ) );
    }
}

export function setInstallationFlowData( dispatch, data ) {
    dispatch( installationActions.setInstallationFlow( "" ) );
}


// IFRAME RELATED MESSAGES:
export const sendMessageToTopIframe = msgObj => {
    if ( !document.getElementById( "top-iframe" ) ) {
        return false;
    }

    w3logger( "Sending iframe message to origin=" + getAppSiteDomain() );
    let msgObjStr = JSON.stringify( msgObj );
    const iframeWin = document.getElementById( "top-iframe" ).contentWindow;
    iframeWin.postMessage( msgObjStr, getAppSiteDomain() );

    return true;
};

export const getAppSiteDomain = () => {
    return REACT_APP_CENTRAL_SITE_URL;
};

export const getTopIframeUrl = () => {
    return getAppSiteDomain() + "/iframe-pages/topIframe.html";
};

// COMMON:
export function getWpAdminInstallUrl( wpSiteUrl ) {
    return wpSiteUrl + "/wp-admin/plugin-install.php?s=Google+XML+Sitemaps&tab=search&type=term";
}

/***
 * Reserve redirect for app side...
 * @param action
 */
export const reserveRedirectForFederatedSigninApp = action => {
    window.localStorage.setItem( "federatedSignForApp", true );
    window.localStorage.setItem( "federatedSignForAppAction", action );
};

export const reserveRedirectForFederatedSigninClientSide = (domain, redirectUrl, action) => {
    window.localStorage.setItem( "federatedSignForCs", true );
    window.localStorage.setItem( "federatedSignForCsDomain", domain );
    window.localStorage.setItem( "federatedSignForCsRedirectUrl", redirectUrl );
    window.localStorage.setItem( "federatedSignForCsAction", action );
};

/***
 * Check if there was already an reservation for redirect (from App.js)
 * @returns {boolean}
 */
export const isRedirectUponSignInReserved = () => {
    w3logger("lets test the localstorage variables", window.localStorage.getItem( "federatedSignForApp" ));   
    if ( window.localStorage.getItem( "federatedSignForCs" ) ) {
        return true;
    } else if ( window.localStorage.getItem( "federatedSignForApp" ) ) {
        return true;
    } else {
        return false;
    }
};

/***
 * Check only for client sites
 * @returns {boolean}
 */
export const isFederatedSigninForCsInProgress = () => {
    if ( window.localStorage.getItem( "federatedSignForCs" ) ) {
        return true;
    } else {
        return false;
    }
};

/***
 * Check only for app site
 * @returns {boolean}
 */
export const isFederatedSigninForAppInProgress = () => {
    if ( window.localStorage.getItem( "federatedSignForApp" ) ) {
        return true;
    } else {
        return false;
    }
};

export const getFederatedSignInForAppData = () => {
    w3logger("Inside getFederatedSignInForAppData");
    if ( !isFederatedSigninForAppInProgress() ) {
        w3logger("No federated login in process");
        return null;
    }
    w3logger("Federated login");
    const action = window.localStorage.getItem( "federatedSignForAppAction" );
    return { action };
}


/***
 * Return the data for federated sign in for a client site
 * @returns {{redirectUrl: string, domain: string, action: string}}
 */
export const getFederatedSignInForCsData = () => {

    if ( !isFederatedSigninForCsInProgress() ) {
        return null;
    }
    w3logger("installFlow>>>getfedratedData", window.localStorage.getItem( "federatedSignForCsRedirectUrl" ))
    const domain = window.localStorage.getItem( "federatedSignForCsDomain" );
    const redirectUrl = window.localStorage.getItem( "federatedSignForCsRedirectUrl" );
    const action = window.localStorage.getItem( "federatedSignForCsAction" );
    return { action, domain, redirectUrl };
}


/***
 * Remove any redirect url for federated signins:
 */
export const removeReservedRedirect = () => {
    // remove client site:
    window.localStorage.removeItem( "federatedSignForCs" );
    window.localStorage.removeItem( "federatedSignForCsDomain" );
    window.localStorage.removeItem( "federatedSignForCsRedirectUrl" );
    window.localStorage.removeItem( "federatedSignForCsAction" );


    // for app site:
    window.localStorage.removeItem( "federatedSignForApp" );
    window.localStorage.removeItem( "federatedSignForAppAction" );
};

/**
 * Submit POST request to client site callback after federated signin
 * @param targetUrl
 * @param {string} name - user name
 * @param refreshToken
 */
export const submitPostRequestToCsAfterFederatedSignin = ( targetUrl, name, refreshToken, data='' ) => {

    if ( !targetUrl || !refreshToken ) {
        throw new Error( "Cannot redirect to the site. Missing request data." );
    }

    const form = document.createElement( "form" );
    form.style = "display: none";
    document.body.appendChild( form );
    form.method = "POST";
    form.action = data=='' ? targetUrl : data =='site exists.' ? targetUrl+`&errorCode=403` : targetUrl+`&${data}` ;
    const elementRefreshToken = document.createElement( "INPUT" );
    elementRefreshToken.name = "refresh_token"
    elementRefreshToken.value = refreshToken;
    elementRefreshToken.type = 'hidden';

    const elementName = document.createElement( "INPUT" );
    elementName.name = "name"
    elementName.value = name;
    elementName.type = 'hidden';

    form.appendChild( elementRefreshToken );
    form.appendChild( elementName );
    form.submit();

};

