import { call, fork, put, take } from "@redux-saga/core/effects";
import { isInsideWpAdmin } from "helpers/installationFlowHelper";
import * as actions from "components/installation/actions/clientSite/actions";
import {
    getCurrentDomainFromBrowser,
    isInstallationFlowEnabled,
    isOnAppSite,
    sendMessageToTopIframe
} from "helpers/installationFlowHelper";
import {logWrite } from "logger";
import { cloudApiCall } from "api/apiClient";
import { w3logger } from 'logger';

/***
 * Send message to iframe, asking for cookie state.
 * @returns {Promise<boolean>}
 * @private
 */
const _readInstallationStatus = async () => {
    if ( isInstallationFlowEnabled() === false ) {
        w3logger( "_readInstallationStatus canceled. Flow is disabled." );
        return false;
    }

    if ( isOnAppSite() === true ) {
        w3logger( "_readInstallationStatus canceled. App site location detected." );
        return false;
    }

    if ( process.env.REACT_APP_MOCK === 'true' ) {
        // Skip the topIframe.html and post a read-cookie message directly to parent window:
        let jsonData = {
            "code": "read-cookie",
            "message": "Installation data mock",
            "siteDomain": "http://localhost:8071",
            "data": {
                "ownerCode": "12345",
                "domain": "http://localhost:8071"
            }
        };
        window.postMessage(JSON.stringify(jsonData));
    } else {
        // CHECK COOKIE STATE:
        w3logger( "_readInstallationStatus() called..." );
        sendMessageToTopIframe( {
            "code": "read-cookie",
            "siteDomain": getCurrentDomainFromBrowser()
        } );
        // END OF CHECK COOKIE STATE
    }

    return true;
}

/***
 * Once initiated, starts looking for installation cookie
 * @returns {IterableIterator<<"CALL", CallEffectDescriptor>|<"TAKE", TakeEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>>}
 */
function* watchClientSiteReadInstallationCookie() {
    //while (true) {
        const { request } = yield take(actions.INSTALLATION_CLIENT_READ_INSTALLATION_COOKIE.REQUEST);
        try {

            let requestStarted = yield call(_readInstallationStatus);

            yield put(actions.installationCookieParsed.success({success: true}));
        } catch (e) {
            w3logger("dd error:");
            w3logger(e);
            yield put(actions.installationCookieParsed.error(e.message));
        }
    //}
}

// **** START OF QUICK UPGRADE FLOW:

function* watchClientSiteInstallDomainPayment() {
    while (true) {
        const { request: { nonce, domain, action, actionFailure='', userData = {} } } = yield take(actions.INSTALLATION_CLIENT_PAYMENT_COMPLETED.REQUEST);
        try {

            let response = {};
            let responseObject = {};
            let jsonData = null;

            logWrite(">>>watchClientSiteInstallDomainPayment started with domain:");
            logWrite(domain);

            // todo: get this info from request (window props):
            const formData = {
                first_name : userData && userData.attributes ? userData.attributes.name : "Unknown",
                email: userData && userData.attributes ? userData.attributes.email : ""
            };

            let reqBody ={"paymentMethodNonce": nonce, domain, formData};

            const request = {
                relativeUrl: "payment/subscribe",
                apiStack: "auctollo-payment-api",
                data: reqBody,
                method: "POST"
            };

            const result = yield call(cloudApiCall, request);

            w3logger("resulting json data:");
            w3logger(result);
            const { data } = result;

            if(data){
                yield put(actions.paymentCompleted.success());
                logWrite("Payment marked as success");
                if(action){
                    yield call(action);
                }
            } else {
                throw new Error("Payment failed!");
            }

        } catch (e) {
            w3logger("dd error:",actionFailure);
            w3logger(e);
            yield put(actions.paymentCompleted.error(e.message));
            yield call (actionFailure);
        }
    }
}

// **** END OF QUICK UPGRADE FLOW

function startSagas() {
    let sagas = [];
    if ( isInsideWpAdmin() ) {
        // client site:
        sagas = [...sagas,
            fork( watchClientSiteReadInstallationCookie ),
            fork( watchClientSiteInstallDomainPayment )
        ];
    } else {
        // central site:
        // none
    }
    return sagas;
}

export default startSagas();
