import { put, take, fork, call } from "redux-saga/effects";
import { API, Auth } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import dotenv from "dotenv";
import { w3logger } from 'logger';

import { premiumResponse } from "../../../config/offlineData";


dotenv.load();

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Makes Sign Up request
 */
function* watchActivateSite() {
    while (true) {
        const { request: { domain, type, action } } = yield take(actions.ACTIVATE_SITE.REQUEST);
        try {
            const userDetails = yield Auth.currentAuthenticatedUser()
            if(userDetails!==null){
                let domainInfo = ""; 

                if(domain && domain.domain) {
                    domainInfo = domain.domain;
                } else {
                    domainInfo = domain;
                }

                const reqBody = {
                    body: { "domain": domainInfo, "type": type },
                };
                w3logger("wathcactivatesite",reqBody)
                let response = {}
                if(process.env.REACT_APP_MOCK === 'true'){
                    response  = {data:yield premiumResponse};
                } else {
                    response = yield API.post("auctollo", "/site/premium", reqBody);
                }
                const { data } = response
                w3logger("data>>>>>>>ac",data)
                if(data!=="" && data.type!==undefined){
                    yield put(actions.activateSite.success());
                    yield put(userActions.updateActiveDomain(data));
                }
                yield call(action);
            }
        } catch (e) {
            w3logger("error",e)
            // yield put(actions.activateSite.error(e.message));
        }
    }
}

export default [
    fork(watchActivateSite),
];
