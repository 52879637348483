import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Settings Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const payment = (state = initialState, action) => {
    switch (action.type) {

            case actions.DISPLAY_SUBSCRIPTION_INFO.SUCCESS:
                return Object.assign( {}, state, {
                    loading: false,
                    message: "",
                    subscriptionData: action.data
                } );
    
            case actions.DISPLAY_SUBSCRIPTION_INFO.REQUEST:
                return Object.assign( {}, state, {
                    loading: true,
                    message: "",
                } );

                case actions.DISPLAY_SUBSCRIPTION_INFO.FAILURE:
                    return Object.assign({}, state, {
                        loading: false,
                        message: action.error,
                    });
    
            case actions.CLEAR_SUBSCRIPTION_INFO.SUCCESS:
                return Object.assign( {}, state, {
                    loading: false,
                    message: "",
                    subscriptionData: {}
                } );

        default:
            return state;
    }
};

export default payment;
