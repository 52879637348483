
import { connect } from "react-redux";
import ServiceWorkerNotifier from "./ServiceWorkerNotifier";

const mapStateToProps = state => {
    return {
        serviceWorkerUpdated: state.serviceWorker.serviceWorkerUpdated,
    };
};

export default connect(mapStateToProps)(ServiceWorkerNotifier);
