// @flow
import React from "react";
import { get } from "lodash";
import auth from "./authHelper";
import { Auth, API } from "aws-amplify";
import { withRouter, History } from "react-router-dom";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { connect } from "react-redux";
import * as startupActions from "actions/startup";
import * as Actions from "actions/user";
import * as DashboardActions from 'components/dashboard/actions';
import { awsAuth } from "../config/offlineData";
import { GoogleApiHelper } from "helpers/index";
import { isWPSite } from "helpers/constants";
import { wpApiCall } from "../api/apiClient";
import {isInsideWpAdmin} from "./installationFlowHelper";
import {logWrite} from "../logger";
import { w3logger, logError  } from 'logger'

export type AuthHelperTpye = *; // TODO add type

export type UserType = *; // TODO add type

export type WithUserStateType = {
    isAuthenticated: boolean,
};

export type WithUserPropsType = {
    activeSite: {
        domain: String,
        isExist: boolean
    },
    setUserData: <UserType>(param: UserType | null) => UserType,
    setIsauthenticated: (setIsauthenticated: boolean) => void,
    setDomainsData: (sites: Array<DomainType>) => void,
    isAuthenticated: boolean,
    history: History,
    logout: () => void,
};

export type DomainType = {
    domain: string,
    key: string,
    userId: string | number
};

/**
 *
 * @author Roman Slyusar <roman.slyusar@piogroup.net>
 * @param Component
 * @returns HOC with user data
 */
export default function withUser<Props: WithUserPropsType>(Component: *): React.Component {

    class WithUser extends React.Component<Props, WithUserStateType> {
        auth: AuthHelperTpye;
        constructor(props) {
            super(props);

            this.auth = auth;
            this.auth.userhandler = {
                onSuccess: function (result) {
                    () => this.setIsAuthenticated(result);
                },
                onFailure: function () {
                    // TODO add errorHandler
                }
            };
        }

        componentDidMount() {
            async () => {
                await this.authFromURL();
                await this.isUserAuthenticated();
            }

            // window.Appcues.identify("1234");
        }
        /**
         * get UserInfo from URL, sync with Cognito and set to localstorage
         *
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
        authFromURL = async () => {
            w3logger("install>>>auth",window.location.href)
            try {
                const curUrl = window.location.href;
                w3logger("targetcurUrl",curUrl)
                // if (stringToReplace.indexOf("access_token") !== -1) {
                    
                // }
                // const re = /#\//;
                // const stringToReplace = curUrl.replace(re, "#");
                // if (stringToReplace.indexOf("access_token") !== -1) {
                //     auth.parseCognitoWebResponse(stringToReplace);
                //     await this.getCurrentUser();
                // }
            } catch (e) {
                // TODO add errorHandler
            }
        }

        /**
         * set isAuthenticated
         * for now let's use this as App property
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
        setIsAuthenticated = isAuthenticated => {
            this.props.setIsauthenticated(isAuthenticated);
        }

        /**
         * is user isAuthenticated in the application NOT in cognito
         * user can be signed in in cognito but not in app e.g. facebook user
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
        isUserAuthenticated = async () => {
            let email;
            try {

                const { attributes } = await this.getCurrentUserAttributes() || {};
                const { isAuthenticated, activeSite } = this.props;

                if (attributes) {
                    // it is registered:
                    w3logger(">>>isUserAuthenticated - has attributes:");
                    w3logger(attributes);
                    email = attributes && attributes.email;
                    // email && this.props.activeSite && this.setIsAuthenticated(true);
                } else {
                    // not registered...
                    w3logger(">>>isUserAuthenticated - no attributes!");
                }

                if (!activeSite) {
                    w3logger(">>>isUserAuthenticated - no active site!");
                    if (attributes) {
                        // registered:
                        // const { sites } = await API.get("auctollo", "/site/get-sites");
                        // sites && sites.length > 0 && this.props.setDomainsData(sites);

                    } else {
                        // not registered:

                        // moved to startupSagas:
                        // w3logger(">>>isUserAuthenticated - calling new site create!");
                        // this.props.initDefaultSite();
                    }
                } else {
                    w3logger(">>>isUserAuthenticated - active site found!");
                    logWrite(activeSite);
                }

            } catch (e) {
                this.setIsAuthenticated(false);
                logError("Settings setIsAuthenticated to false because of error:");
                logError(e);

                // TODO add custom error handler
                return;
            }
        };

        /**
         * is user isAuthenticated in cognito
         * DO NOT use directly in components
         * @author Roman Slyusar <roman.slyusar@piogroup.net>
         */
        isCognitAuthenticated = () => this.auth.isUserSignedIn()

        getCurrentUserAttributes = async () => {
            if(!this.props.userData){
                return null;
            } else {
                return this.props.userData;
            }
        };

        handleLogout = async () => {
            //alert("handle logout");
            const {history, setUserData,setDomainsData} = this.props;
            if(isInsideWpAdmin() === true){
                // inside wp:
                // clear database tokens:
                let res = await wpApiCall({
                    relativeUrl: "removeWpDbUserData",
                    method: "GET"
                });
                w3logger("handleLogout>>>",res)
                // let resJson = JSON.parse(res);

                // clear local storage, amplify, etc:
                // await Auth.signOut({global: false});
                localStorage.clear();
                
    
                GoogleApiHelper.onLogout();
                await this.props.logout();
                let data = {domain:`${window.location.host}`,removeToken:true}
                await this.props.getCognitoToken(data);

            } else {
                // standalone (app site):
                await Auth.signOut({ global: false });
                localStorage.clear();
                GoogleApiHelper.onLogout();
                await this.props.logout();
            }

            setUserData(null);
            setDomainsData([]);
            this.setIsAuthenticated(false);
            history.push("/sign-in");
            // this.props.startLoadUserData()

        };

        render() {
            const { isAuthenticated, activeSite } = this.props;
            return (

                <Component
                    activeSite={activeSite}
                    handleLogout={this.handleLogout}
                    isAuthenticated={isAuthenticated}
                    isWPSite={isWPSite}
                    isCognitAuthenticated={this.isCognitAuthenticated}
                    {...this.props}
                />

            );
        }
    }

    const mapStateToProps = state => ({
        isAuthenticated: state.user.isAuthenticated,
        userData: state.user.userData,
        activeSite: get(state, "user.activeSite", null),
        subscriptionType: get(state, "user.activeSite.type", "community")
    });

    const mapDispatchToProps = dispatch => {
        return {
            setUserData: data => {
                dispatch(Actions.setUserData.success(data));
                return data;
            },
            setIsauthenticated: data => {
                dispatch(Actions.setIsauthenticated(data));
            },
            // initDefaultSite: () => {
            //     w3logger(">>>initDefaultSite dispatching INSTALLATION_CREATED_DEFAULT_SITE_REQUEST");
            //     dispatch({ type: "INSTALLATION_CREATED_DEFAULT_SITE_REQUEST", data: {} });
            // },
            logout: () => {
                dispatch(Actions.logout());
            },
            setDomainsData: domains => {
                dispatch(Actions.setDomainsData(domains));
            },
            getCognitoToken: data => {
                dispatch(DashboardActions.getCognitoToken.request(data));
            },
            startLoadUserData: () => {
                dispatch(startupActions.loadUserAndDomainsData.request(null));
            },
        };
    };

    return withRouter(
        connect(mapStateToProps, mapDispatchToProps)(WithUser)
    );
}
