export const SET_MESSAGE = "SET_MESSAGE";
export const RESET_MESSAGE = "RESET_MESSAGE";

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set error to storage action
 * @param data {Object} - Must contains two fields. { message: String, type: "danger" | "success" }
 */
export const setMessage = data => ({ type: SET_MESSAGE, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Reset error in storage action
 */
export const resetMessage = () => ({ type: RESET_MESSAGE });
