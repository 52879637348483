import { createRequestTypes } from "helpers/constants";
import { w3logger, logError  } from 'logger'

export const SET_USER_DATA = createRequestTypes("SET_USER_DATA");
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_DOMAINS_DATA = "SET_DOMAINS_DATA";
export const SET_USER_DOMAINS = "SET_USER_DOMAINS";
export const SET_ACTIVE_DOMAIN = "SET_ACTIVE_DOMAIN";
export const UPDATE_ACTIVE_DOMAIN = "UPDATE_ACTIVE_DOMAIN";
export const UPDATE_CLOUD_SITEMAP_STATUS = "UPDATE_CLOUD_SITEMAP_STATUS";
export const LOGOUT = "LOGOUT";
export const GET_TOKEN = createRequestTypes("GET_TOKEN");
export const DELETE_DOMAIN_DATA = createRequestTypes("DELETE_DOMAIN_DATA");
export const EDIT_DOMAIN_DATA = createRequestTypes("EDIT_DOMAIN_DATA");


export const SET_ISAUTHENTICATED = "SET_ISAUTHENTICATED";

// is the registration saved to wp db:
export const SET_IS_WP_AUTHENTICATED = "SET_IS_WP_AUTHENTICATED";

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set user to storage action
 */
export const setUserData = {
    request : request => ({ type: SET_USER_DATA.REQUEST, request }),
    success : success => ({ type:SET_USER_DATA.SUCCESS , success}),
    error : error => ({ type : SET_USER_DATA.FAILURE , error})
}

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set user data to storage action
 */
export const setUser = data => ({ type: SET_USER, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set token to storage action
 */
export const setToken = data => ({ type: SET_TOKEN, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set domains data
 */
export const setDomainsData = data => ({ type: SET_DOMAINS_DATA, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set domains data
 */

export const deleteDomainData = {
    request: request => ({ type: DELETE_DOMAIN_DATA.REQUEST, request }),
    success: response => ({ type: DELETE_DOMAIN_DATA.SUCCESS, response }),
    error:   error => ({ type: DELETE_DOMAIN_DATA.FAILURE, error })
};

export const editDomainData = {
    request: request => ({ type: EDIT_DOMAIN_DATA.REQUEST, request }),
    success: response => ({ type: EDIT_DOMAIN_DATA.SUCCESS, response }),
    error:   error => ({ type: EDIT_DOMAIN_DATA.FAILURE, error })
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set domains data
 */
export const setUserDomains = data => ({ type: SET_USER_DOMAINS, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set active domain data
 */
export const setActiveDomain = data => ({ type: SET_ACTIVE_DOMAIN, data });

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Update active domain
 */
export const updateActiveDomain = data => ({ type: UPDATE_ACTIVE_DOMAIN, data });

export const updateCloudSitemapStatus = data => ({type: UPDATE_CLOUD_SITEMAP_STATUS, data});

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Set active domain data
 */
export const logout = () => ({ type: LOGOUT });

export const setIsauthenticated = data => {

    w3logger(">>>setIsauthenticated called with:");
    w3logger(data);
    return { type: SET_ISAUTHENTICATED, data }
};

export const setIsWpAuthenticated = data => ({ type: SET_IS_WP_AUTHENTICATED, data });
