import React from "react";

const EyeCrossedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#36ACA3" viewBox="0 0 34 30" width="34" height="30">
            <path
                fill="#36ACA3"
                d="M33.607 13.572C30.28 7.966 24.072 4.22 17 4.22c-1.68 0-3.312.212-4.867.61L9.018.6A1.423 1.423 0 007.045.254l-.774.538a1.4 1.4 0 00-.348 1.959l2.546 3.421C5.28 7.72 2.375 10.235.394 13.57c0 .001 0 .002-.002.003a2.794 2.794 0 000 2.856c2.273 3.828 5.888 6.789 10.204 8.282l-7.33-10.395a15.967 15.967 0 011.67-2.105l7.047 9.995 2.4 3.401c1.544.208 3.108.23 4.659.069L7.828 9.772a16.425 16.425 0 012.289-1.263L24.982 29.4c.449.636 1.332.791 1.973.346l.774-.538a1.4 1.4 0 00.348-1.959l-2.545-3.42a19.091 19.091 0 008.075-7.401 2.793 2.793 0 000-2.856zM23.022 20.27l-1.125-1.596a6.055 6.055 0 00.52-6.543v.001a2.518 2.518 0 01-2.528 2.51 2.518 2.518 0 01-2.528-2.51 2.518 2.518 0 012.528-2.509h.002a6.189 6.189 0 00-4.694-.45l-1.125-1.596A8.065 8.065 0 0117 7.031c4.437 0 8.028 3.564 8.028 7.969a7.904 7.904 0 01-2.006 5.27z"
            />
        </svg>
    );
};

export default EyeCrossedIcon;
