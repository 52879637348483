//@flow
import React, { Component } from "react";
import styles from "./radioButton.module.scss";

import type { RadioButtonType, RadioButtonStateType } from "./RadioButtonTypes";

class RadioButton extends Component<RadioButtonType, RadioButtonStateType> {
    state = {
        isChecked: this.props.isChecked,
    };
    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }
    render() {
        return (
            <label className={`${styles.container} ${styles[this.props.isChecked ? "active" : null]}`}>
                {this.props.placeholder}
                <input type="radio" value={this.props.value} name="radio" onClick={this.props.onClick} checked={this.props.isChecked} onChange={this.toggleChange} />
                <span className={styles.input}></span>
                <span className={styles.checkmark}></span>
            </label>
        );
    }
}

export default RadioButton;
