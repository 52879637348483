// These sagas should be running only on the central site

import { logWrite } from "logger";
import { call, fork, put, take } from "@redux-saga/core/effects";
import * as actions from "components/installation/actions/centralSite/actions";
import http from "../../../../api";
import { INSTALLATION_CENTRAL_INSTALL_DOMAIN } from "../../actions";
import { API } from "aws-amplify";
import { INSTALLATION_CENTRAL_SITE_CREATED } from "../../actions/centralSite/actions";
import { cloudApiCall, frontendCloudApiCall } from "api/apiClient";
import { isInsideWpAdmin } from "../../../../helpers/installationFlowHelper";
import * as startupActions from "../../../../actions/startup";
import * as userActions from "../../../../actions/user";
import { w3logger } from 'logger';

function* watchCentralSiteInstallDomainPayment() {
    while (true) {
        const { request: { nonce, domain, action, actionFailure='', userData = {} } } = yield take(actions.INSTALLATION_CENTRAL_PAYMENT_COMPLETED.REQUEST);
        try {

            let response = {};
            let responseObject = {};
            let jsonData = null;

            logWrite(">>>watchCentralSiteInstallDomainPayment started with domain:");
            logWrite(domain);

            logWrite(">>>formData:");

            // todo: get this info from request (window props):
            const formData = {
                first_name : userData && userData.attributes ? userData.attributes.name : "Unknown",
                email: userData && userData.attributes ? userData.attributes.email : ""
            };

            let reqBody ={"paymentMethodNonce": nonce, domain, formData};

            const request = {
                relativeUrl: "payment/subscribe",
                apiStack: "auctollo-payment-api",
                data: reqBody,
                method: "POST"
            };

            const result = yield call(frontendCloudApiCall, request);

            w3logger("resulting json data:");
            w3logger(result);
            const { status, data } = result;

            if(status == "1" && data){
                yield put(actions.paymentCompleted.success());
                logWrite("Payment marked as success");
                if(action){
                    yield call(action);
                }
            } else {
                throw new Error("Payment failed!");
            }

        } catch (e) {
            w3logger("dd error:");
            w3logger(e);
            yield put(actions.paymentCompleted.error(e.message));
            yield call(actionFailure)
        }
    }
}

function* watchCentralSiteInstallDomainCreateSite() {
    while (true) {
        const { request: { history, planData, domainData,  actionSuccess = null, actionFailure = null } } = yield take(actions.INSTALLATION_CENTRAL_SITE_CREATED.REQUEST);
        try {

            let response = {};
            let responseObject = {};
            let jsonData = null;

            const {planName=""} = planData;

            const request = {
                relativeUrl: "sites/createSite",
                apiStack: "auctollo-sites-api",
                data: {...domainData, planName},
                method: "POST"
            };

            const result = yield call (frontendCloudApiCall, request);
            const { status, message, data } = result;
            w3logger("qqqsite result",result)
            logWrite("Frotend call in watchCentralSiteInstallDomainCreateSite returned:");
            logWrite(result);

            if(status === 0 ){
                if(actionFailure){
                    let message = data.Error!==undefined ? data.Error :'';
                    actionFailure(history, planData, {success: false,message,domainData});
                }
                throw new Error(data?.Error)
                // throw new Error("Site creation failed.");
            } else {
                logWrite("Site created successfully");

                // set active site:
                // reload user data:
                domainData.message = message
                yield put( startupActions.loadUserAndDomainsData.request( null ) );
                yield put(userActions.setActiveDomain(data.siteCreateResult.siteInfoExistingResult.Item))
                window.localStorage.setItem('domainData',JSON.stringify(data.siteCreateResult.siteInfoExistingResult.Item))
                yield put(actions.siteCreated.success(domainData));

                if(actionSuccess){
                    actionSuccess(history, planData, {success: true});
                    w3logger('action success')
                }
            }

        } catch (e) {
            w3logger("qqqsite Err",e)
            w3logger("dd error:");
            w3logger({domainData,message:e});
            yield put(actions.siteCreated.error(e));
        }
    }
}

function* watchCentralDomainDeleteSite() {
    while (true) {
        const { request } = yield take(userActions.DELETE_DOMAIN_DATA.REQUEST);
        try {
            // let response = {};
            let responseObject = {};
            let jsonData = null;

            // const {planName=""} = planData;

            // const request = {
            //     relativeUrl: "site/delete",
            //     apiStack: "auctollo",
            //     data: {request},
            //     method: "POST"
            // };
            const config = {
                body: { domain:request.domain },
                response: true,
            };
            const response =  yield API.post("auctollo", "/site/delete", config);
            // w3logger("pppppppppppp",response)
            // const result = yield call (frontendCloudApiCall, request);
            // const { status, message, data } = result;

            // logWrite("Frotend call in watchCentralSiteInstallDomainCreateSite returned:");
            // logWrite(result);

            // if(status != "1" || !data){
            //     if(actionFailure){
            //         // actionFailure(history, planData, {success: false});
            //     }
            //     throw new Error("Site creation failed.");
            // } else {
            //     logWrite("Site created successfully");

                // set active site:
                // reload user data:
                yield put( startupActions.loadUserAndDomainsData.request( null ) );
                yield put (userActions.deleteDomainData.success(request.domain));
                // yield put(actions.siteCreated.success());

                // if(actionSuccess){
                //     // actionSuccess(history, planData, {success: true});
                // }
            // }

        } catch (e) {
            w3logger("dd error:");
            w3logger(e);
            yield put (userActions.deleteDomainData.success());
            // yield put(actions.siteCreated.error(e.message));
        }
    }
}

function* watchCentralDomainEditSite() {
    while (true) {
        const { request } = yield take(userActions.EDIT_DOMAIN_DATA.REQUEST);
        w3logger("request:?",request);
        try {
            const { history, planData, domainData,  actionSuccess = null, actionFailure = null } = request;
            const {planName=""} = planData;
            const req = {
                relativeUrl: "sites/editSite",
                apiStack: "auctollo-sites-api",
                data: {...domainData, planName},
                method: "POST"
            };

            const result = yield call (frontendCloudApiCall, req);
            const { status, message, data } = result;
            w3logger(result,'edit site')
            yield put( startupActions.loadUserAndDomainsData.request( null ) );
            if(status!==0){
                yield put (userActions.editDomainData.success());
                if(actionSuccess){
                    actionSuccess(history, planData, {success: true});
                }
            } else {
                yield put (userActions.editDomainData.error())
                if(actionFailure){
                    actionFailure(history, planData , {success:false})
                }
                throw new Error(data?.Error)
            }
            
        } catch (e) {
            w3logger("edit error:");
            w3logger(e);
            yield put (userActions.editDomainData.error());
            yield put(actions.siteCreated.error(e));
        }
    }
}

function startSagas() {
    let sagas = [];
    if ( isInsideWpAdmin() ) {
        // none
    } else {
        // central site:
        sagas = [...sagas,
            fork( watchCentralSiteInstallDomainPayment ),
            fork( watchCentralSiteInstallDomainCreateSite ),
            fork( watchCentralDomainDeleteSite ),
            fork( watchCentralDomainEditSite ),
        ];

    }
    return sagas;
}

export default startSagas();
