//@flow
import React from "react";
import type { ButtonSignUpComponentTypes } from "./buttonSignUpComponentTypes";
import "../../../assets/scss/global.scss";
import styles from "./buttonSignUp.module.scss";
import { Spinner } from "reactstrap";

const ButtonSignUp = (props: ButtonSignUpComponentTypes) => {
    return (
        <div onClick={() => props.onClickBtn(props.type)} className={` d-flex ${styles.button} ${styles[props.color]} `} id="social_login_button" >
            <span className={` d-flex ${styles["icon-wrapper"]}`}>
                {props.icon}
            </span>
            {props.loading == true ?
                <Spinner
                    color="info"
                    size="sm"
                />
                :
                <>
                {props.text}
                </>
            }
        </div>
    );
};

export default ButtonSignUp;
