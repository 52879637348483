import React from "react";
import { googleURL } from "helpers/constants";
import { useTranslation } from "react-i18next";

const GoogleButton = () => {
    const { t } = useTranslation();
    return(
        <a
            href={googleURL}
        >
            {t("Google")}
        </a>
    )
};

export default GoogleButton;
