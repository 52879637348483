// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import userImage from "assets/img/user.png";
import { FaChevronCircleDown, FaSortDown } from 'react-icons/fa';
import { withTranslation } from "react-i18next";

//styles 
import "assets/scss/global.scss";
import styles from "./dropdown.module.scss";

//types
import type { DropdownPropType, DropdownStateType } from "./DropdownType";
import { isInsideWpAdmin } from "../../../../helpers/installationFlowHelper";
const {
    REACT_APP_CENTRAL_SITE_URL
} = process.env;

class TopDropdown extends Component<DropdownPropType, DropdownStateType> {
    state = {
        opened: false,
        wrapperRef: ''
    };

    toggleMenu = value => {
        this.setState({ opened: !this.state.opened })
    }

    closeMenu = () => this.setState({ opened: false });

    blur = e => {
        let btn = e.relatedTarget
        if(btn!==null){
            if(btn.getAttribute('aria-haspopup')){
                this.setState({ opened: false });
            }else{
                e.relatedTarget.click();
                this.setState({ opened: false });

            }
                    }
        // if (e && e.relatedTarget) {
        //     e.relatedTarget.click();
        //     this.setState({ opened: false });

        // }
        if (e && e.relatedTarget == null) {
            this.setState({ opened: false })
        }
    }

    render() {
        const { history, handleLogout, user,t } = this.props;
        const { userData } = user || {};
        const { attributes = {} } = userData || {};
        const { name = "" } = attributes || {};

            const thisDropdown = this;
        return (
            <div className={styles["dropdown-wrapper"]}>

                <button type="button"
                    className={`${styles["toggle-btn"]} d-flex align-items-center `}
                    onClick={this.toggleMenu}
                    onBlur={this.blur}
                    id="auth-pro-dropdown"
                >
                    <span className={styles["logged-as"]}>{t("Logged in as")}</span>
                    <img src={userImage} alt="user" />
                    <span>{name}</span>
                   <span style={{margin:'0px 0px 6px 3px'}}> <FaSortDown /> </span>
                </button>
                <ul className={` list bg-white with-shadow  ${styles["dropdown-menu"]} ${styles[this.state.opened && "opened"]}`}>
                    {this.props.user && this.props.user.userData  &&
                        <>
                            {(user && user.activeSite && user.activeSite.domainOwnershipConfirmed == true &&
                                <li className="d-flex">
                                    <button className={styles["dropdown-link"]} onClick={() => {
                                        thisDropdown.closeMenu();
                                        history.push("/");
                                        }}>{t("Dashboard")}
                                    </button>
                                </li>
                            )}
                            
                            {(user && user.activeSite && user.activeSite.domainOwnershipConfirmed == true &&
                                <li className="d-flex">
                                    <button className={styles["dropdown-link"]} id="site-settings" onClick={() => {
                                        thisDropdown.closeMenu();
                                        history.push("/settings");
                                        }}>{t("Site Settings")}
                                    </button>
                                </li>
                            )}
                            
                            <li className="d-flex">
                                <button className={styles["dropdown-link"]} id="my-account" onClick={() => {
                                    thisDropdown.closeMenu();

                                    if(isInsideWpAdmin() === true){
                                        window.open(REACT_APP_CENTRAL_SITE_URL + "/#/accounts", "_blank") || window.location.replace(REACT_APP_CENTRAL_SITE_URL + "/#/accounts");
                                    } else {
                                        history.push("/accounts");
                                    }

                                    }}>{t("My Account")}
                                </button>
                            </li>
                            {/* <li className="d-flex">
                                <button className={`${styles["dropdown-link"]} ${styles["upgrade-link"]}`} onClick={() => {
                                    history.push("/pricing-plans");
                                }}>Upgrade to Premium <span className={styles.badge}>1</span></button>
                            </li> */}
                            {isInsideWpAdmin() === false && 
                            <li className="d-flex">
                                <button className={styles["dropdown-link"]} id="my-sites" onClick={() => {
                                    thisDropdown.closeMenu();
                                    if(isInsideWpAdmin() === true){
                                        window.location.href = REACT_APP_CENTRAL_SITE_URL + "/#/my-sites";
                                    } else {
                                        history.push("/my-sites")
                                    }

                                        }}>{t("My Sites")}
                                </button>
                            </li>
                            }
                            {/* <li className="d-flex">
                                <button className={styles["dropdown-link"]} onClick={() => {
                                    thisDropdown.closeMenu();
                                    history.push("/payment-braintree")
                                }}>Make Payment
                            </button>
                            </li> */}
                            {/* <li className="d-flex">
                                <button className={styles["dropdown-link"]} onClick={() => {
                                    thisDropdown.closeMenu();
                                    history.push("/subscription-info");
                                }}>Subscription Info
                        </button>
                            </li> */}
                        </>
                    }
                    <li className="d-flex">
                        <button className={styles["dropdown-link"]} id="logout" onClick={() => {
                            thisDropdown.closeMenu();
                            handleLogout();
                        }}>
                            {t("Log Out")}
                        </button>
                    </li>

                </ul>
               
            </div >
        );
    }
}

export default withRouter(withTranslation()(TopDropdown));
