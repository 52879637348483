import * as actions from "../actions";

const initialState = {
    isLoading:true,
    loading: false,
    message: null,
    crawlState: null,
    headerData: [],
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Auth reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const bingWebmasterSection = (state = initialState, action) => {
    switch (action.type) {

        case actions.GET_BING_WEBMASTER_DATA.FAILURE:
            return Object.assign({}, state, {
                isLoading:false,
                loading: false,
                message: action.error.message,
            });

        case actions.GET_BING_WEBMASTER_DATA.SUCCESS:
            return Object.assign({}, state, {
                isLoading:false,
                loading: false,
                message: null,
                crawlState: action.response.crawlState,
                headerData: action.response.headerData
            });

        case actions.GET_BING_WEBMASTER_DATA.REQUEST:
            return Object.assign({}, state, {
                isLoading:true,
                loading: true,
                message: null,
                crawlState: null,
                headerData: [],
            });

        default:
            return state;
    }
};

export default bingWebmasterSection;
