import React from "react";

const MicrosoftIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 26" width="22" height="26">
            <path
                fill="#EB3B00"
                fillRule="evenodd"
                d="M13.566 3.925l-.03 18.17L.273 20.258c.303-.305.689-.382 1.126-.556.378-.15.674-.264 1.046-.412.724-.286 1.474-.645 2.152-.832l.002-12.393 8.967-2.14zM0 20.097s.116.134.23.21c.127.082.163.085.305.145.213.088.436.16.664.24.459.157.846.312 1.308.486l7.857 2.904c3.488 1.223 2.483 1.429 5.387.557l4.166-1.22c.75-.222 1.078-.166 1.158-.52V2.271c-.151-.316-.075-.213-.544-.35l-2.092-.602c-.917-.26-1.833-.533-2.776-.814-1.009-.3-1.828-.739-2.764-.355L0 4.977v15.12z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default MicrosoftIcon;
