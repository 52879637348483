import * as actions from "../actions";
import moment from "moment";

const initialState = {
    loading: false,
    loadingToken:false,
    message: "",
    cognitoToken:"",
    selectedDate: {
        startDate: moment().subtract(90, 'days'),
        endDate: moment(),
    },
    loaderToNotifyEngines: false,
    pingResponse: {
        "response": {}
    }
};

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Auth reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const dashboard = (state = initialState, action) => {
    switch (action.type) {

        case actions.GENERATE_SITE_MAP.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error.message,
            });

        case actions.SET_DATE_RANGE.SUCCESS:
            return Object.assign({}, state, {
                selectedDate: action.response.date,
            });

        case actions.GENERATE_SITE_MAP.SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                message: "Site Map successfully generated!",
            });

        case actions.GENERATE_SITE_MAP.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });

            case actions.SET_COGNITO_TOKEN.REQUEST:
                return Object.assign({}, state, {
                    loadingToken: true,
                    message: "",
            });

            case actions.SET_COGNITO_TOKEN.SUCCESS:
                return Object.assign({}, state, {
                    loadingToken: false,
                    message: "",
            });

            case actions.SET_COGNITO_TOKEN.FAILURE:
                return Object.assign({}, state, {
                    loadingToken: false,
                    message: "",
            });

            case actions.GET_COGNITO_TOKEN.REQUEST:
                return Object.assign({}, state, {
                    loadingToken: true,
                    message: "",
            });

            case actions.GET_COGNITO_TOKEN.SUCCESS:
                return Object.assign({}, state, {
                    loadingToken: false,
                    message: "",
                    cognitoToken:action.response,
            });

            case actions.GET_COGNITO_TOKEN.FAILURE:
                return Object.assign({}, state, {
                    loadingToken: false,
                    message: "",
            });

            case actions.SET_NOTIFY_ENGINES.REQUEST:
                return Object.assign({}, state, {
                    loaderToNotifyEngines: true
            });

            case actions.SET_NOTIFY_ENGINES.SUCCESS:
                return Object.assign({}, state, {
                    loaderToNotifyEngines: false,
                    pingResponse: action.response,
            });

            case actions.SET_NOTIFY_ENGINES.FAILURE:
                return Object.assign({}, state, {
                    loaderToNotifyEngines: false,
                    pingResponse: {"response": action.error.error},
            });

        default:
            return state;
    }
};

export default dashboard;
