//@flow

import React from "react";
import styles from "./select.module.scss";

import type { SelectType } from "./SelectType";

const Select = (props: SelectType) => {
    return (
        <select className={styles.select} value={props.value} onChange={props.onChange}>
            {props.children}
        </select>
    );
};

export default Select;
