// @flow
import React from "react";
import {useDispatch, useSelector} from 'react-redux'
import FormInput from "../../common/components/FormInput";
import {ButtonPrimary} from "common/components";
import { Field } from "redux-form";
import * as actions from '../../redux/actions'
import { email, minLength6, requiredFullName, emailRequired, passwordRequired } from "../../../signupOld/validate";
import {useHistory, useParams} from 'react-router-dom'
// types
import * as userActions from 'actions/user'
// styles
import "assets/scss/global.scss";
import styles from "../../signUpForm.module.scss";
import { useTranslation } from "react-i18next";
import { isInsideWpAdmin } from '../../../../helpers/installationFlowHelper'
import { useEffect } from 'react';
import { Auth } from "aws-amplify";
import { logError } from 'logger';
export default ({ handleSubmit, loading }) => {
    const { t } = useTranslation();
    const history = useHistory()
    const dispatch = useDispatch()
    const {targetPlan} = useParams()
    window.localStorage.setItem('targetPlan',targetPlan)

    const signup = useSelector(state=>state.signup)
    const domainData = useSelector(state=>state.user.activeSite)
    useEffect(()=>{
        async function checkUser(){
            if(isInsideWpAdmin()) {
                await Auth.currentAuthenticatedUser()
                .then(user => {
                    if(window.localStorage.getItem('targetPlan')==='premium') history.push('/payment-braintree')
                }).catch(err => logError('not authenticated',err));
            }
        }
        checkUser()
    },[])
    const onSubmitForm = values =>{
        values.history=history
        values.targetPlan = targetPlan
        domainData.type = targetPlan
        dispatch(userActions.setActiveDomain(domainData))
        dispatch(actions.signUp.request(values))   
    }
    return (
        <form className={styles["form-input-group"]} >
            <div className="form-row">
                <label className={styles.label}>{t("Email Address")}</label>
                <Field
                    name="email"
                    id="email"
                    placeholder={t("Email")}
                    component={FormInput}
                    validate={[emailRequired, email]}
                />
            </div>
  
            <div className="form-row">
                <label className={styles.label}>{t("Name")}</label>
                <Field
                    name="first_name"
                    id="first_name"
                    placeholder={t("Name")}
                    component={FormInput}
                    validate={requiredFullName}
                />
            </div>
            <div className="form-row">
                <label className={styles.label}>{t("Password")}</label>
                <Field
                    name="password"
                    id="password"
                    type="password"
                    placeholder={t("Password")}
                    component={FormInput}
                    validate={[passwordRequired, minLength6]}
                />
            </div>
            <div className={`error-block signup-error ${(signup && signup.signUpError) ? 'mb-2' : ''}`}>{signup && signup.signUpError}</div>

            <div className={`${styles["signup-button"]}`}>
                <ButtonPrimary
                    buttonText={t("Get started")}
                    buttonSize="small"
                    class={"email_login_button"}
                    action={handleSubmit(onSubmitForm)}
                    loading={loading}
                />

            </div>
        </form>
    );
};
