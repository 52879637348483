import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import auth from "components/dashboard/reducers";
// import signUpReducers from "components/signupOld/reducers";
import payment from "components/settings/payment/reducers";
import paymentBraintree from "components/paymentBraintree/reducers";
import plans from "components/pricingPlans/reducers"
// import subscriptionInfo from "components/subscriptionInfo/reducers";
// import subscriptionInfo from "components/accounts/paymentStatus/reducers";
import signInReducers from "components/signIn/redux/reducers/signin";
import signup from "../components/signup/redux/reducers/signup"
import confirmation from "../components/signup/redux/reducers/confirmation"
import settings from "components/settings/reducers";
import profiles from 'components/accounts/reducers';
import messages from "./messages";
import installation from "./installation";
import centralSiteInstallDomain from "components/installation/reducers/centralSite";
import clientSiteInstallDomain from "components/installation/reducers/clientSite";
import topNotifications from "./topNotifications";
import user from "./user";
import serviceWorker from "./serviceWorker";
import appSettings from "./appSettings";
import appState from "./appState";
import cloudSitemap from "./cloudSitemap";

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Main reducer which include all app's reducers
 */

const reducers = Object.assign(
    {
        form: formReducer,
        router: routerReducer,
    },
    auth,
    { user },
    { messages },
    // signUpReducers,
    signInReducers,
    settings,
    confirmation,
    signup,
    profiles,
    topNotifications,
    payment,
    paymentBraintree,
    plans,
    // subscriptionInfo,
    installation,
    centralSiteInstallDomain,
    clientSiteInstallDomain,
    { appSettings },
    { appState },
    { cloudSitemap },
    { serviceWorker }
);

export default combineReducers( reducers );
