//@flow

import React from "react";
import "../../../assets/scss/global.scss";
import styles from "./settingsHeading.module.scss";

import type { SettingsHeadingType } from "./SettingsHeadingType";

import { withTranslation } from "react-i18next";
const SettingsHeading = (props: SettingsHeadingType) => {
    return (
        <div className={`d-flex justify-content-between ${styles['heading']} `}>
            <h5 className={` title ${styles.title}`}>{props.title}</h5>
            {props.learnMoreLink && <a href="#">{props.t("Learn More")}</a>}
        </div>
    );
};

export default withTranslation()(SettingsHeading);
