import Storage from "./storage";
import dotenv from "dotenv";

dotenv.load();

const getCurrentUserId = () => {
    const { username } = Storage.getUserData();
    const { NODE_ENV } = process.env;
    return NODE_ENV === "development" ? "offlineContext_cognitoIdentityId" : (username || "");
};
export default getCurrentUserId;
