export const required = value => value ? undefined : "Required";
export const emailRequired = value => value ? undefined : "Email address is required";
export const passwordRequired = value => value ? undefined : "A password is required";
export const maxLength = max => value =>
    value && value.length > max ? "Must be"+` ${max} `+ "characters or less" : undefined;
export const maxLength15 = maxLength(15);
export const number = value => value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const minValue = min => value =>
    value && value < min ? "Must be at least"+` ${min}` : undefined;
export const minValue18 = minValue(18);
export const webUrl = value => !value || (value && !/https|http:\/\/[^\s$.?#].[^\s]*$/.test(value)) ? "Please enter a valid website address" : undefined;
export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        "Please use a valid email address." : undefined;
export const tooOld = value =>
    value && value > 65 ? "You might be too old for this" : undefined;
export const aol = value =>
    value && /.+@aol\.com/.test(value) ?
        "Really? You still use AOL for your email?" : undefined;
export const minLength = min => value =>
    value && value.length < min ? "Your password must be at least"+` ${min} `+"characters long." : undefined;
export const minLength8 = minLength(8);
export const minLength6 = minLength(6);
export const requiredFullName = value => value ? undefined : "Please enter your full name.";
export const requiredСountry = value => value ? undefined : "Please enter your Country.";
export const requiredAddress = value => value ? undefined : "Please enter your Address.";
export const cvv = value =>
    value && !/^[0-9]{3,4}$/i.test(value) ?
        "Please use a valid CVS / CVV number." : undefined;
export const monthValid = value =>value && !/^[0][1-9]|[1][0-2]$/i.test(value.substring(0,2)) ?
            "Please enter valid month." : value.length < 4 ? "Please enter year.": value.substring(2,4) < new Date().getFullYear()%100 ? "Card expire." : value.substring(2,4) == new Date().getFullYear()%100 && value.substring(0,2) < new Date().getMonth() + 1 ? "Card expire." : undefined   ;
export const zipCode =  value => value && !/^[0-9]{5}(?:-[0-9]{4})?$/i.test(value) ? "Please enter valid zip code." : undefined;
export const siteMapUrl = value => {
    let len = value && value.split('.').length
    return (/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/.test(value) && value.split(' ').length<2 && len>1 && ( value.split('.')[len-1].split('/').length<2 || (value.split('.')[len-1].split('/')[1]==='' )))?undefined:'Please Enter Valid url'
}
