import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
    formType: ""
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @author Alex Malyi <am@piogroup.net>
 * @desc Confirmation Reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const saveProfiles = ( state = initialState, action ) => {
    switch (action.type) {
        case actions.PROFILE_SETTINGS.FAILURE:
            return Object.assign( {}, state, {
                loading: false,
                message: action.error.message,
                formType: ""
            } );

        case actions.PROFILE_SETTINGS.SUCCESS:
            return Object.assign( {}, state, {
                loading: false,
                message: action.response.successMessage,
                formType: action.response.formType,
                acc_det_name: action.response.acc_det_name
            } );

        case actions.PROFILE_SETTINGS.REQUEST:
            return Object.assign( {}, state, {
                loading: true,
                message: "",
                formType: "",
                acc_det_name: action.request.acc_det_name
            } );

        case actions.PROFILE_SETTINGS_INITIALIZE:
            return Object.assign( {}, state, {
                ...action.data
            } );

        default:
            return state;
    }
};

export default saveProfiles;
