import { put, take, fork, call } from "redux-saga/effects";
import { API } from "aws-amplify";
import * as actions from "./actions";
import * as userActions from "actions/user";
import dotenv from "dotenv";
import { w3logger } from 'logger';
import { premiumResponse } from "../../config/offlineData";

dotenv.load();

const {
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_API_PAYMENT_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE
} = process.env;

function* watchPaymentUpdateMethod() {
    while (true) {
        const { request: { nonce, action } } = yield take(actions.PAYMENT_UPDATE_METHOD.REQUEST);
        try {
            w3logger("entered watchPaymentUpdateMethod");
            let response = {};
            let responseObject = {};
            let jsonData = null;

            if(process.env.REACT_APP_MOCK === 'true'){
                // response  = {data:yield premiumResponse};
                jsonData  = {data: {}};
            } else {
                // real call:
                const reqBody = {"paymentMethodNonce": nonce};

                if(REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true"){
                    // localhost:
                    w3logger("localhost connect");
                    response = yield fetch(`${REACT_APP_API_PAYMENT_GATEWAY_URL}/payment/updateMethod`, {method: "POST", body: JSON.stringify(reqBody)});
                    const responseObject = yield response.json();
                    jsonData = JSON.parse(responseObject.body);

                } else {
                    // staging / prod:
                    w3logger("prod connect!!!!");
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    // returns response object, with property body as string
                    responseObject = yield API.post("auctollo-payment-api", "/payment/updateMethod", apiCallConfig);
                    jsonData = JSON.parse(responseObject.body);
                }
            }
            w3logger("resulting json data:");
            w3logger(jsonData);

            const { data } = jsonData;
            yield put(actions.paymentUpdateMethod.success());
            yield call(action);
        } catch (e) {
            w3logger("dd error:");
            w3logger(e);
            yield put(actions.paymentUpdateMethod.error(e.message));
        }
    }
}

export default [
    fork(watchPaymentUpdateMethod),
];
