import dotenv from "dotenv";

dotenv.config();

/**
 * @author Alex Malyi <am@piogroup.net>
 * @desc Function for creating actions with three main cases
 * @param {String} base - The base name of action
 * @param {Array} optional - Array with optional action cases
 */

const {
    REACT_APP_COGNITO_DOMAIN,
    REACT_APP_COGNITO_APP_CLIENT_ID,
    REACT_APP_OAUTH_REDIRECT_URL
} = process.env;

export function createRequestTypes(base, optional) {
    for (let index in optional) {
        optional[index] = `${base}_${index}`;
    }

    return {
        ...optional,
        REQUEST: `${base}_REQUEST`,
        SUCCESS: `${base}_SUCCESS`,
        FAILURE: `${base}_FAILURE`,
    };
}

export const code = {code:''}

export const facebookURL = `${REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${REACT_APP_OAUTH_REDIRECT_URL}&response_type=token&client_id=${REACT_APP_COGNITO_APP_CLIENT_ID}&identity_provider=Facebook&state=STATE&
scope=aws.cognito.signin.user.admin+openid+profile`;

//export const googleURL = `https://${REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${REACT_APP_OAUTH_REDIRECT_URL}&access_type=offline&include_granted_scopes=true&response_type=code&client_id=${REACT_APP_COGNITO_APP_CLIENT_ID}&identity_provider=Google&scope=aws.cognito.signin.user.admin+openid+profile`;
export const googleURL = `https://${REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${REACT_APP_OAUTH_REDIRECT_URL}&access_type=offline&include_granted_scopes=true&response_type=code&client_id=${REACT_APP_COGNITO_APP_CLIENT_ID}&identity_provider=Google&scope=aws.cognito.signin.user.admin+openid+profile&prompt=select_account+consent`;

export const isWPSite = document.getElementById('wpcontent');

export const bingURL = "https://ssl.bing.com/webmaster/api.svc/json";
