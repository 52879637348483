import { createRequestTypes } from "../../helpers/constants";

export const PROFILE_SETTINGS = createRequestTypes("PROFILE_SETTINGS");
export const GET_SETTINGS = createRequestTypes("GET_SETTINGS", { INIT_DEFAULT_VALUE: "INIT_DEFAULT_VALUE" });
export const FLUSH_PERMALINKS = createRequestTypes("FLUSH_PERMALINKS");
export const UPDATE_ROBOTS = createRequestTypes("UPDATE_ROBOTS");

export const PROFILE_SETTINGS_INITIALIZE = "PROFILE_SETTINGS_INITIALIZE";
export const initializeProfileSettings = data => ({ type: PROFILE_SETTINGS_INITIALIZE, data });

export const saveProfiles = {
    request: request => ({ type: PROFILE_SETTINGS.REQUEST, request }),
    success: response => ({ type: PROFILE_SETTINGS.SUCCESS, response }),
    error:   error => ({ type: PROFILE_SETTINGS.FAILURE, error })
};

export const getConfig = {
    request: request => ({ type: GET_SETTINGS.REQUEST, request }),
    success: response => ({ type: GET_SETTINGS.SUCCESS, response }),
    error:   error => ({ type: GET_SETTINGS.FAILURE, error }),
    initDefaultValue:   data => ({ type: GET_SETTINGS.INIT_DEFAULT_VALUE, data })
};

export const updateRobots = {
    request: request => ({ type: UPDATE_ROBOTS.REQUEST, request }),
    success: response => ({ type: UPDATE_ROBOTS.SUCCESS, response }),
    error:   error => ({ type: UPDATE_ROBOTS.FAILURE, error }),
};

export const flushPermalinks = {
    request: request => ({ type: FLUSH_PERMALINKS.REQUEST, request }),
    success: response => ({ type: FLUSH_PERMALINKS.SUCCESS, response }),
    error:   error => ({ type: FLUSH_PERMALINKS.FAILURE, error }),
};
