//flow

import React from "react";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from "recharts";

const BarChartComponent = (props: AreaChartPropTypes) => {
    const { chartData } = props;

    let charts = [];
    chartData.forEach(element => {
        element.contents &&  element.contents.length>0 && element.contents.forEach(item=>{
            charts.push(item)
        })
    });
    return (
        <ResponsiveContainer aspect={3}>
            <BarChart
                width={950}
                height={300}
                data={charts}
                margin={{
                top: 20, right: 30, left: 20, bottom: 5,
                }}
                barCategoryGap="20%"
            >
                <XAxis dataKey="type"  />
                <YAxis type="number" domain={[0, dataMax => {
                    return 40
                }]} />
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <Tooltip />
                <Legend />
                <Bar dataKey="submitted" fill="#BCEDE5" />
                <Bar dataKey="indexed" fill="#36aca3" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartComponent;
