// @flow

import data from "./data.json";

const CountryList = () => {
    let labelMap = {};
    let valueMap = {};

    data.forEach(country => {
        labelMap[country.label.toLowerCase()] = country.value;
        valueMap[country.value.toLowerCase()] = country.label;
    });
    return data;

};

export default CountryList;
