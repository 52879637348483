// @flow

/***
 * Core installation sagas.
 * Specialized cases are inside sagas/centralSite and sagas/clientSite folders
 */

import { call, put, take, fork } from "redux-saga/effects";
import http from "../../api";
import * as actions from "./actions";
import * as userActions from "actions/user";
import { INSTALLATION_CREATED_DOMAIN_OWNER_CODE } from "./actions";
import { API } from "aws-amplify";
import { isInsideWpAdmin } from "../../helpers/installationFlowHelper";
import { logWrite } from "../../logger";
import { wpApiCall, cloudApiCall } from "../../api/apiClient";
import { w3logger, logError } from 'logger';
const {
    REACT_APP_API_GATEWAY_URL,
    REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE,
    REACT_APP_SITES_API_GATEWAY_URL
} = process.env;

// used on app site, for install flow:
// since this is running on app site, it doesn't interact with installationStatus and installationFlowData
function* watchCreatedDomainOwnerCode() {
    while (true) {
        logWrite( "watchCreatedDomainOwnerCode started..." );
        const { request: { domain = "", refreshToken = "", tempToken = "", protocol = "" } } = yield take( actions.INSTALLATION_CREATED_DOMAIN_OWNER_CODE.REQUEST );
        logWrite( "watchCreatedDomainOwnerCode taken!" );

        if ( !domain ) {
            logError( "watchCreatedDomainOwnerCode - empty field - domain" );
            return;
        }

        let jsonData = null;
        try {
            if ( process.env.REACT_APP_MOCK === 'true' ) {
                jsonData = { data: {} };
            } else {
                // real call:

                let reqBody = { domain, refreshToken, tempToken, protocol };
                if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
                    // localhost:
                    logWrite( "localhost connect" );
                    let response = yield fetch( `${REACT_APP_SITES_API_GATEWAY_URL}/sites/createDomainOwnerCode`, {
                        method: "POST",
                        body: JSON.stringify( reqBody )
                    } );
                    let responseObject = yield response.json();
                    jsonData = JSON.parse( responseObject.body );

                } else {
                    // staging / prod:
                    logWrite( "prod connect!!!!" );
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    // returns response object, with property body as string
                    let responseObject = yield API.post( "auctollo-sites-api", "/sites/createDomainOwnerCode", apiCallConfig );
                    jsonData = JSON.parse( responseObject.body ).data;
                }

                logWrite( ">>jsonData:" );
                logWrite( jsonData );

                yield put( actions.createdDomainOwnerCode.success( {
                    ownerCode: jsonData.ownerCode,
                    domain: jsonData.domain,
                    protocol: jsonData.protocol,
                    installationHashCode: jsonData.installationHashCode
                } ) );

            }
        } catch (e) {
            logError( "Error in watchCreatedDomainOwnerCode::" );
            logError( e );

            yield put( actions.createdDomainOwnerCode.error( e.message ) );
        }
    }
}

/***
 * This is running on WP side, it takes the ownerCode and stores is to WP DB
 * @returns {IterableIterator<SimpleEffect<"PUT", PutEffectDescriptor<*|{response: *, type: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<{data: *, type: *}>>|SimpleEffect<"CALL", CallEffectDescriptor>|SimpleEffect<"TAKE", TakeEffectDescriptor>|SimpleEffect<"PUT", PutEffectDescriptor<*|{type: *, error: *}>>>}
 */
function* watchSetDomainOwnerCode() {
    while (true) {
        logWrite( "watchSetDomainOwnerCode started..." );
        const { request } = yield take( actions.INSTALLATION_SET_DOMAIN_OWNER_CODE.REQUEST );

        logWrite( "request:" );
        logWrite( request );

        const { domain, protocol, ownerCode, installationHashCode, name } = request;

        if ( !domain ) {
            logError( "watchSetDomainOwnerCode - empty field - domain" );
            return;
        }

        if ( !ownerCode ) {
            logError( "watchSetDomainOwnerCode - empty field - ownerCode" );
            throw new Error( "ownerCode empty in watchSetDomainOwnerCode " );
        }

        logWrite( "watchSetDomainOwnerCode taken!" );
        // const url = `/?rest_route=/sitemap_premium/v1/domainOwnership`;
        const relativeUrl = `domainOwnership`;
        try {
            let jsonData = {};
            if ( process.env.REACT_APP_MOCK === 'true' ) {
                let response = {
                    "ownerCode": "12345",
                    "domain": "http://localhost:8071",
                    "protocol": "http",
                    "installationHashCode": "44534ggbfgb"
                };
                yield put( actions.setDomainOwnerCode.success( response ) );
                // yield put(actions.setInstallationFlow("upgrade"));
                yield put( actions.setInstallationCheckState( {
                    step: "domain-owner-code-set",
                    stepSucceeded: true
                } ) );
            } else {
                // const response = yield call( http, url, {
                //     method: "POST",
                //     body: JSON.stringify( { domain, protocol, ownerCode, installationHashCode } )
                // } );

                const requestDoC = {
                    relativeUrl: relativeUrl,
                    method: "POST",
                    data: { domain, protocol, ownerCode, installationHashCode }
                };
                let response = yield call (wpApiCall, requestDoC);

                logWrite( "watchSetDomainOwnerCode , the response:" );
                // this should be an object already (JSON):
                logWrite( response );

                if ( response ) {
                    yield put( actions.setDomainOwnerCode.success( response ) );
                    // yield put(actions.setInstallationFlow("upgrade"));
                    yield put( actions.setInstallationCheckState( {
                        step: "domain-owner-code-set",
                        stepSucceeded: true
                    } ) );
                } else {
                    yield put( actions.setDomainOwnerCode.error( response ) );
                    yield put( actions.setInstallationCheckState( {
                        step: "domain-owner-code-set",
                        stepSucceeded: false
                    } ) );
                    throw new Error( "Something went wrong in watchSetDomainOwnerCode." );
                }
            }

        } catch (e) {
            logError( "Error in watchSetDomainOwnerCode::" );
            logError( e );

            yield put( actions.setInstallationCheckState( { step: "domain-owner-code-set", stepSucceeded: false } ) );
            yield put( actions.setDomainOwnerCode.error( e.message ) );
        }
    }
}

/***
 * Executes on WP side.
 * After the ownerCode is stored to WP database, this call tries to verify the code and finish the installation process...
 * @returns {IterableIterator<SimpleEffect<"PUT", PutEffectDescriptor<*|{response: *, type: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<{data: *, type: *}>>|SimpleEffect<"CALL", CallEffectDescriptor>|SimpleEffect<"TAKE", TakeEffectDescriptor>|SimpleEffect<"PUT", PutEffectDescriptor<*|{type: *, error: *}>>>}
 */
function* watchDomainOwnershipVerified() {
    while (true) {
        logWrite( "watchDomainOwnershipVerified started..." );
        const { request } = yield take( actions.INSTALLATION_DOMAIN_OWNERSHIP_CONFIRMED.REQUEST );

        const { user, domain, protocol, ownerCode, installationHashCode, name } = request;

        if ( !domain ) {
            logError( "watchDomainOwnershipVerified - empty field - domain" );
            return;
        }

        if ( !ownerCode ) {
            logError( "watchDomainOwnershipVerified - empty field - ownerCode" );
            return;
        }

        // since this is the flow before user is registered inside WP, temporary token needs to be obtained first..
        let accessTokenHeader = "";
        let roTempToken = yield API.post( "auctollo-sites-api", "/sites/retrieveTempToken", {
            body: { domain, protocol, ownerCode, installationHashCode }
        } );
        let jsonDataTempToken = JSON.parse( roTempToken.body );
        let { tempToken } = jsonDataTempToken.data;
        accessTokenHeader = "Bearer " + tempToken;

        w3logger( "Calculated accessTokenHeader:" );
        w3logger( accessTokenHeader );

        logWrite( "watchDomainOwnershipVerified taken!" );
        let jsonData = null;
        try {
            if ( process.env.REACT_APP_MOCK === 'true' ) {
                yield put( actions.domainOwnershipConfirmed.success( {
                    ownerCode: "12345",
                    domain: "http://localhost:8071"
                } ) );

                yield put( actions.setInstallationCheckState( {
                    step: "domain-owner-code-verified",
                    stepSucceeded: true
                } ) );

            } else {
                // real call:

                let reqBody = { domain, protocol, ownerCode, installationHashCode };
                if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
                    // localhost:
                    logWrite( "localhost connect" );
                    let response = yield fetch( `${REACT_APP_SITES_API_GATEWAY_URL}/sites/confirmAndSetupDomain`, {
                        method: "POST",
                        body: JSON.stringify( reqBody )
                    } );
                    let responseObject = yield response.json();
                    jsonData = JSON.parse( responseObject.body );

                } else {
                    // staging / prod:
                    logWrite( "prod connect!!!!" );
                    const apiCallConfig = {
                        body: { ...reqBody },
                        headers: {
                            Authorization: accessTokenHeader
                        }
                    };

                    // returns response object, with property body as string
                    let responseObject = yield API.post( "auctollo-sites-api", "/sites/confirmAndSetupDomain", apiCallConfig );
                    jsonData = JSON.parse( responseObject.body );
                }

                yield put( actions.domainOwnershipConfirmed.success( {
                    ownerCode: jsonData.ownerCode,
                    domain: jsonData.domain,
                    protocol: jsonData.protocol,
                    installationHashCode: jsonData.installationHashCode
                } ) );

                yield put( actions.setInstallationCheckState( {
                    step: "domain-owner-code-verified",
                    stepSucceeded: true
                } ) );
            }
        } catch (e) {
            logError( "Error in watchDomainOwnershipVerified::" );
            logError( e );

            yield put( actions.setInstallationCheckState( {
                step: "domain-owner-code-verified",
                stepSucceeded: false
            } ) );
            yield put( actions.domainOwnershipConfirmed.error( e.message ) );
        }
    }
}


/***
 * This should be called only after site verification is completed.
 */
function* watchTransferSiteSettingsToCloud() {
    while (true) {
        logWrite( "watchTransferSiteSettingsToCloud started..." );
        const { request } = yield take( actions.INSTALLATION_TRANSFER_SITE_SETTINGS_TO_CLOUD.REQUEST );

        const { user, domain, protocol, ownerCode, installationHashCode, name } = request;

        if ( !domain ) {
            logError( "watchTransferSiteSettingsToCloud - empty field - domain" );
            return;
        }

        if ( !ownerCode ) {
            logError( "watchTransferSiteSettingsToCloud - empty field - ownerCode" );
            return;
        }

        logWrite( "watchTransferSiteSettingsToCloud taken!" );
        let jsonData = null;
        try {
            if ( process.env.REACT_APP_MOCK === 'true' ) {
                yield put( actions.transferSiteSettingsToCloud.success( {} ) );

                yield put( actions.setInstallationCheckState( {
                    step: "site-settings-transferred",
                    stepSucceeded: true
                } ) );

            } else {
                // real call:
                const reqBody = { domain, protocol, ownerCode, installationHashCode, settingsType: "standard" };
                const callRequest = {
                    relativeUrl: "sites/pullSiteSettingsFromWp",
                    apiStack: "auctollo-sites-api",
                    data: reqBody,
                    method: "POST"
                };

                jsonData = yield call(cloudApiCall, callRequest);
                jsonData = jsonData.data;

                logWrite(jsonData, "Response from transfer settings endpoint");

                yield put( actions.transferSiteSettingsToCloud.success( jsonData ) );

                yield put( actions.setInstallationCheckState( {
                    step: "site-settings-transferred",
                    stepSucceeded: true
                } ) );
            }
        } catch (e) {
            logError( "Error in watchTransferSiteSettingsToCloud::" );
            logError( e );

            yield put( actions.setInstallationCheckState( {
                step: "site-settings-transferred",
                stepSucceeded: false
            } ) );
            yield put( actions.transferSiteSettingsToCloud.error( e.message ) );
        }
    }
}


/***
 * Executes on WP side.
 * After the domain ownership is verified, refresh token needs to be obtained...
 * @returns {IterableIterator<SimpleEffect<"PUT", PutEffectDescriptor<*|{response: *, type: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<{data: *, type: *}>>|SimpleEffect<"CALL", CallEffectDescriptor>|SimpleEffect<"TAKE", TakeEffectDescriptor>|SimpleEffect<"PUT", PutEffectDescriptor<*|{type: *, error: *}>>>}
 */
function* watchInstallationRefreshTokenObtained() {
    while (true) {
        logWrite( "watchInstallationRefreshTokenObtained started..." );
        const { request } = yield take( actions.INSTALLATION_REFRESH_TOKEN_OBTAINED.REQUEST );

        const { domain, protocol, ownerCode, installationHashCode, name } = request;

        if ( !domain ) {
            logError( "watchInstallationRefreshTokenObtained - empty field - domain" );
            return;
        }

        if ( !ownerCode ) {
            logError( "watchInstallationRefreshTokenObtained - empty field - ownerCode" );
            return;
        }

        logWrite( "watchInstallationRefreshTokenObtained taken!" );
        let jsonData = null;
        try {
            if ( process.env.REACT_APP_MOCK === 'true' ) {
                yield put( actions.domainOwnershipConfirmed.success( {
                    ownerCode: "12345",
                    domain: "localhost:8071",
                    protocol: "http",
                    installationHashCode: "gedgre"
                } ) );

                yield put( actions.setInstallationCheckState( {
                    step: "installation-refresh-token-obtained",
                    stepSucceeded: true
                } ) );

            } else {
                // real call:

                let reqBody = { domain, protocol, ownerCode, installationHashCode, name };
                if ( REACT_APP_CONNECT_TO_SERVERLESS_OFFLINE === "true" ) {
                    // localhost:
                    logWrite( "localhost connect" );
                    let response = yield fetch( `${REACT_APP_SITES_API_GATEWAY_URL}/sites/retrieveRefreshToken`, {
                        method: "POST",
                        body: JSON.stringify( reqBody )
                    } );
                    let responseObject = yield response.json();
                    jsonData = JSON.parse( responseObject.body );

                } else {
                    // staging / prod:
                    logWrite( "prod connect!!!!" );
                    const apiCallConfig = {
                        body: { ...reqBody }
                    };
                    // returns response object, with property body as string
                    let responseObject = yield API.post( "auctollo-sites-api", "/sites/retrieveRefreshToken", apiCallConfig );
                    jsonData = JSON.parse( responseObject.body ).data;
                }

                // save refresh token to db:
                const requestSaveRefreshToken = {
                    relativeUrl: "rfTkn",
                    method: "POST",
                    data: {
                        refresh_token: jsonData[ "refreshToken" ],
                        id_token : jsonData["idToken"]
                    }
                };

                const saveRefreshTokenResult = yield call( wpApiCall, requestSaveRefreshToken )
                w3logger( ">>>saveRefreshTokenResult:" );
                w3logger( saveRefreshTokenResult );
                // now save token payload (user attributes)...

                const requestSavePayload = {
                    relativeUrl: "saveTokenPayload",
                    method: "POST",
                    data: {
                        payload: {
                            name
                        }
                    }
                };
                const saveTokenPayloadResult = yield call( wpApiCall, requestSavePayload );
                w3logger( ">>>saveTokenPayloadResult:" );
                w3logger( saveTokenPayloadResult );


                yield put( actions.installationRefreshTokenObtained.success( {
                    ownerCode: jsonData.ownerCode,
                    domain: jsonData.domain,
                    protocol: jsonData.protocol,
                    installationHashCode: jsonData.installationHashCode
                } ) );
                yield put( actions.setInstallationCheckState( {
                    step: "installation-refresh-token-obtained",
                    stepSucceeded: true
                } ) );
            }
        } catch (e) {
            logError( "Error in watchInstallationRefreshTokenObtained:" );
            logError( e );

            yield put( actions.setInstallationCheckState( {
                step: "installation-refresh-token-obtained",
                stepSucceeded: false
            } ) );
            yield put( actions.installationRefreshTokenObtained.error( e.message ) );
        }
    }
}

/***
 * Create default site:
 * @returns {IterableIterator<SimpleEffect<"PUT", PutEffectDescriptor<*|{response: *, type: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<*|void>>|SimpleEffect<"TAKE", TakeEffectDescriptor>|SimpleEffect<"PUT", PutEffectDescriptor<*>>|SimpleEffect<"PUT", PutEffectDescriptor<void|*>>>}
 */
function* watchCreateDefaultActiveSite() {
    while (true) {
        logWrite( ">>>watchCreateDefaultActiveSite - started" );
        const { request } = yield take( actions.INSTALLATION_CREATED_DEFAULT_SITE.REQUEST );
        logWrite( ">>>watchCreateDefaultActiveSite - taken request:" );
        logWrite( request );

        if ( !document.getElementById( "sgp-site-url" ) ) {
            logError( "Cannot load default site - no site url element!" );
        } else {
            let siteUrl = document.getElementById( "sgp-site-url" ).getAttribute( "data-value" );
            if ( !siteUrl ) {
                throw new Error( ">>>watchCreateDefaultActiveSite - No siteUrl value" );
            }

            logWrite( ">>>>>>watchCreateDefaultActiveSite - siteUrl:" );
            logWrite( siteUrl );

            let protocol = "";

            if ( siteUrl.indexOf( "http://" ) >= 0 ) {
                protocol = "http";
            } else if ( siteUrl.indexOf( "https://" ) >= 0 ) {
                protocol = "https";
            } else {
                // default:
                protocol = "https";
            }

            const siteDomain = siteUrl.replace( "https://", "" ).replace( "http://", "" );
            logWrite( ">>>watchCreateDefaultActiveSite - siteDomain:" );
            logWrite( siteDomain );

            const domains = [
                {
                    domain: siteDomain,
                    protocol,
                    key: siteDomain,
                    type: "non-registered"
                }
            ];

            // add export / import settings here:
            yield put( userActions.setDomainsData( domains ) );
            yield put( userActions.setActiveDomain( domains[ 0 ] ) );

            logWrite( ">>>watchCreateDefaultActiveSite - going to mark createdDefaultSite as success!" );
            yield put( actions.createdDefaultSite.success( { completed: true } ) );
        }
    }
}

/***
 * Starter function
 * @returns {[*, *, *]}
 */
function startSagas() {
    let sagas = [];
    if ( isInsideWpAdmin() ) {
        sagas = [ ...sagas,
            fork( watchSetDomainOwnerCode ),
            fork( watchDomainOwnershipVerified ),
            fork( watchTransferSiteSettingsToCloud ),
            fork( watchInstallationRefreshTokenObtained )
        ];

        sagas = [ ...sagas, fork( watchCreateDefaultActiveSite ) ];
    } else {
        sagas = [ ...sagas,
            fork( watchCreatedDomainOwnerCode ),
        ];
        logWrite( ">>>startSagas - watchCreateDefaultActiveSite wont run inside wpAdmin!" );
    }
    return sagas;
}

export default startSagas();
