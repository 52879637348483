import * as actions from "../actions";

const initialState = {
    loading: false,
    message: "",
};

/**
 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Auth reducer
 * @param {Object} state - The current state
 * @param {Object} action - The action data
 */
const upgradeSite = (state = initialState, action) => {
    switch (action.type) {

        case actions.ACTIVATE_SITE.FAILURE:
            return Object.assign({}, state, {
                loading: false,
                message: action.error,
            });

        case actions.ACTIVATE_SITE.SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                message: "",
            });

        case actions.ACTIVATE_SITE.REQUEST:
            return Object.assign({}, state, {
                loading: true,
                message: "",
            });

        default:
            return state;
    }
};

export default upgradeSite;
