// @flow
import React, { Component } from "react";
import "../../../assets/scss/global.scss";
import styles from "./tabs.module.scss";
import type { TabsPropsType, TabsStateType } from "./TabsType";
import { withTranslation } from "react-i18next";

class Tabs extends Component<TabsPropsType, TabsStateType> {
    state = {
        selected: 0
    }

    handleChange(index: number) {
        this.setState({ selected: index });
    }
    componentWillMount(){
        this.props.children.map((ele,i)=>{
            if(localStorage.getItem('activeSubscription')=="true" && ele.props.title == "Subscription" ){
                this.setState({selected:i})
            }
        })
    }
    componentDidMount(){
        setTimeout(() => {
            localStorage.setItem('activeSubscription',false)
        }, 3000);
        
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <ul className={`${styles["tabs"]} d-flex list bg-white with-shadow `}>
                    {this.props.children.map((elem, index) => {
                        if (elem.props.title == t("Payment") || elem.props.title == t("Subscription") || elem.props.title == t("Billing") || elem.props.title == t("Password")) {
                           
                            if (elem.props.isAuthenticated !== undefined && elem.props.isAuthenticated == true || elem.props.isGoogleLogin !==undefined && elem.props.isGoogleLogin==false) {
                                return <li
                                    className={`${styles[index === this.state.selected ? "selected" : null]}`}
                                    key={index}
                                    id={elem.props.id}
                                    onClick={this.handleChange.bind(this, index)}>{elem.props.title}</li>;
                            }
                        } else {
                            return <li
                                className={`${styles[index === this.state.selected ? "selected" : null]}`}
                                key={index}
                                id={elem.props.id}
                                onClick={this.handleChange.bind(this, index)}>{elem.props.title}</li>;
                        }

                    })}
                </ul>
                <div className={`${styles["tab-content"]}`}>{this.props.children[this.state.selected]}</div>
            </div>
        );
    }
}

export default withTranslation()(Tabs);
