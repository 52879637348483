// @flow
import React from "react";
import "../../../assets/scss/global.scss";
import styles from "./buttonPrimary.module.scss";
import { Spinner } from "reactstrap";
import { FaCheckCircle } from "react-icons/fa";


const ButtonPrimary = props => {
    const { hasActivatedSite, loading, buttonSize, btnCenter, buttonText, planType='', disabled, discount = 0, withIcon = false, action = () => { } } = props;
    return (
        <button
            className={`${props.class} d-flex with-shadow ${styles.button} ${styles[buttonSize]} ${styles[btnCenter]} ${styles[withIcon ? "with-icon" : null]}`}
            type="button"
            id={`${props.class}`}
            // disabled={disabled}
            disabled={loading === true || disabled === true || hasActivatedSite==false ? true : false}
            onClick={action}
        >
            {loading == true ?
                <Spinner
                    color="info"
                    size="sm"
                // style={{ width: '2rem', height: '2rem' }}
                />
                :
                <>
                    {buttonText=="Saved!" && 
                      <span> <FaCheckCircle style={{marginLeft:"-40px"}} /> </span>
                    }
                    {withIcon}
                    <span>{(discount!==0 && planType === 'premium') ? props.children : buttonText}</span>
                </>

            }


        </button>
    );
};

ButtonPrimary.defaultProps = { btnCenter: "" };
export default ButtonPrimary;
