import React from "react";
import { HashRouter, Switch, Route, BrowserRouter } from "react-router-dom";
import asyncComponent from "helpers/asyncComponent";
const Settings = asyncComponent(() => import("components/settings"));
const BingAuthComponent = asyncComponent(() => import("components/bing"));
const Accounts = asyncComponent(() => import("components/accounts"));
const SitemapPage1 = asyncComponent(() => import("components/sitemapPage1"));
const SitemapPage2 = asyncComponent(() => import("components/sitemapPage2"));
const Dashboard = asyncComponent(() => import("components/dashboard"));
const Auth = asyncComponent(() => import("components/auth"))
const ForgotPass = asyncComponent(() => import("components/forgotPassword"));
import Signup from "../components/signupOld/routes";
// const reactSignup = asyncComponent (() => import ("components/signup/reactSignup"))
import reactSignup from '../../src/components/signup/reactSignup'
import CodeVerify from 'components/signup/code-verify'
const SignIn = asyncComponent(() => import("components/signIn/signInModule"));
const WPSignIn = asyncComponent(() => import("components/wpSignIn"));
const WPSignUp = asyncComponent(() => import("components/wpSignUp"));

const WPReactSignInComponent = asyncComponent(() => import("components/WPReactSignInComponent"));

const PaymentBraintree = asyncComponent(() => import("components/paymentBraintree"));
const PaymentUpdateMethod = asyncComponent(() => import("components/paymentUpdateMethod"));
const MySites = asyncComponent(() => import("components/mySites"));
const PaymentResult = asyncComponent(() => import("components/paymentResult"));
const PricingPlans = asyncComponent(() => import("components/pricingPlans"));
const AppInstallDomainEnterUrl = asyncComponent(() => import("components/installation/appInstallDomainEnterUrl"));
const AppInstallDomainChoosePlan = asyncComponent(() => import("components/installation/appInstallDomainChoosePlan"));
const AppInstallDomainPayment = asyncComponent(() => import("components/installation/appInstallDomainPayment"));
const AppInstallDomainSiteCreation = asyncComponent(() => import("components/installation/appInstallDomainSiteCreation"));
const AppInstallDomainCompleted = asyncComponent(() => import("components/installation/appInstallDomainCompleted"));
const AppInstallWelcome = asyncComponent(() => import("components/installation/appInstallWelcome"));
const AppUpgradeWelcome = asyncComponent(() => import("components/installation/appUpgradeWelcome"));
const AppOnFederatedLoginForCs = asyncComponent(() => import("components/installation/appOnFederatedLoginForCs"));
const InstallationStatusMonitor = asyncComponent(() => import("components/installation/installationStatusMonitor"));
const CsCreateAndConfirmSite = asyncComponent(() => import("components/installation/csCreateAndConfirmSite"));

const CsInstallDomainChoosePlan = asyncComponent(() => import("components/installation/csInstallDomainChoosePlan"));
const CsInstallDomainPayment = asyncComponent(() => import("components/installation/csInstallDomainPayment"));

import TestComponent from "components/testsso";
import AddDomains from "components/addDomains/routes";
import ProtectedRoute from "./protectedRoute";
import NotFoundRoute from "./notFoundRoute";
import { Layout, dashboardLayout, NoLayout } from "common/components";
import AppRoute from "./AppRoute";
import AuthRoute from "./AuthRoute";
import { isInsideWpAdmin } from "helpers/installationFlowHelper";
// import { createBrowserHistory } from 'history';
/**import { reactSignup } from 'components/signUp/reducers';
import { reactSignup } from 'components/signUp/reducers';

 * @author Roman Yephimov <ry@piogroup.net>
 * @desc Hash router with all routers
 *
 * @param {Object} children - Children components
 * @param {Object} childProps - Children props
 */
// const history = createBrowserHistory();
// Example of an protected route:
// <ProtectedRoute exact path="/settings" layout={Layout} component={Settings} />

const Router = () => {
    return (
        <HashRouter>
            <Switch>
                {/* {Signup} */}
                {AddDomains}
                {isInsideWpAdmin() ?
                    <AppRoute exact path="/dashboard" layout={dashboardLayout} component={Dashboard} />
                    :
                    <ProtectedRoute exact path="/dashboard" layout={dashboardLayout} component={Dashboard} />
                }

                <AppRoute exact path="/forgot-password" layout={Layout} component={ForgotPass} />
                {isInsideWpAdmin()
                    ? <AuthRoute exact path="/sign-up" layout={Layout} component={WPSignUp} />
                    : <AuthRoute exact path="/sign-up" layout={Layout} component={reactSignup} />
                }
                {isInsideWpAdmin()
                    ? <AuthRoute exact path="/sign-up/target-plan/:targetPlan" layout={Layout} component={WPSignUp} />
                    : <AuthRoute exact path="/sign-up/target-plan/:targetPlan" layout={Layout} component={reactSignup} />
                }
                <AuthRoute exact path="/verify-code" layout={Layout} component={CodeVerify} />
                {isInsideWpAdmin()
                    ? <AppRoute exact path="/sign-in" layout={Layout} component={WPSignIn} />
                    : <AppRoute exact path="/sign-in" layout={Layout} component={SignIn} />
                }
                <Route exact path="/wp-socialSignIn" component={WPReactSignInComponent} />
                <AppRoute exact path="/sign-in/target-plan/:targetPlan" layout={Layout} component={SignIn} />
                <AppRoute exact path="/pricing-plans" layout={Layout} component={PricingPlans} />
                <AppRoute exact path="/bing" layout={Layout} component={BingAuthComponent} />
                <ProtectedRoute exact path="/settings" layout={Layout} component={Settings} />
                <ProtectedRoute exact path="/accounts" layout={Layout} component={Accounts} />
                <AppRoute exact path="/payment-braintree" layout={Layout} component={PaymentBraintree} />
                <AppRoute exact path="/payment-update-method" layout={Layout} component={PaymentUpdateMethod} />
                <ProtectedRoute exact path="/my-sites" layout={Layout} component={MySites} />
                <AppRoute exact path="/installation-status-monitor" layout={Layout} component={InstallationStatusMonitor} />
                <ProtectedRoute exact path="/payment-result" layout={Layout} component={PaymentResult} />
                <ProtectedRoute exact path="/add-domains" layout={Layout} component={AddDomains} />
                <AppRoute exact path="/installation/app/domain" layout={Layout} component={AppInstallDomainEnterUrl} key="app-install-domain-enter-url" />
                <AppRoute exact path="/installation/app/domain/:plan" layout={Layout} component={AppInstallDomainEnterUrl} key="app-install-domain-enter-url-with-plan" />
                <AppRoute exact path="/installation/app/select-plan" layout={Layout} component={AppInstallDomainChoosePlan} key="app-install-domain-choose-plan" />
                <AppRoute exact path="/installation/app/site-creation" layout={Layout} component={AppInstallDomainSiteCreation} key="app-install-site-creation" />
                <AppRoute exact path="/installation/app/payment" layout={Layout} component={AppInstallDomainPayment} key="app-install-domain-payment" />
                <AppRoute exact path="/installation/app/completed" layout={Layout} component={AppInstallDomainCompleted} key="app-install-domain-completed" />
                <AppRoute exact path="/installation/app/welcome" layout={Layout} component={AppInstallWelcome} key="app-install-welcome" />
                <AppRoute exact path="/installation/app/upgrade-welcome" layout={Layout} component={AppUpgradeWelcome} key="app-upgrade-welcome" />
                <AppRoute exact path="/installation/app/federated-login-for-cs/:domain/:redirectUrl/:action" layout={Layout} component={AppOnFederatedLoginForCs} key="app-federated-login-for-cs" />
                <Route exact path="/sso" component={TestComponent} />
                <Route exact path="/sitemapPage1" component={SitemapPage1} />
                <Route exact path="/sitemapPage2" component={SitemapPage2} />
                <Route exact path="/" component={Auth} />
                <AppRoute exact path="/signup/wp/create-premium" layout={Layout} component={CsCreateAndConfirmSite} key="signup-wp-create-premium" />

                <AppRoute exact path="/installation/cs/select-plan" layout={Layout} component={CsInstallDomainChoosePlan} key="cs-install-domain-choose-plan" />
                <AppRoute exact path="/installation/cs/payment" layout={Layout} component={CsInstallDomainPayment} key="cs-install-domain-payment" />
                <AppRoute exact path="/installation/cs/create-site" layout={Layout} component={CsCreateAndConfirmSite} key="cs-install-create-site" />
                <AppRoute exact path="/installation/cs/create-site/:plan" layout={Layout} component={CsCreateAndConfirmSite} key="cs-install-create-site-with-plan" />

                <NotFoundRoute layout={Layout} component={() => <section className="section-fixed-top">not found</section>} />

            </Switch>
        </HashRouter>
    );
};

export default Router;

