import { createRequestTypes } from "../../helpers/constants";

export const SAVE_SETTINGS = createRequestTypes("SAVE_SETTINGS");
export const GET_SETTINGS = createRequestTypes("GET_SETTINGS", { INIT_DEFAULT_VALUE: "INIT_DEFAULT_VALUE" });
export const FLUSH_PERMALINKS = createRequestTypes("FLUSH_PERMALINKS");
export const GET_PREMIUM_SETTINGS = createRequestTypes("GET_PREMIUM_SETTINGS");
export const CONNECT_GOOGLE_TO_SITE = createRequestTypes("CONNECT_GOOGLE_TO_SITE");
export const DISCONNECT_GOOGLE_FROM_SITE = createRequestTypes("DISCONNECT_GOOGLE_FROM_SITE");
export const UPDATE_ROBOTS = createRequestTypes("UPDATE_ROBOTS");
export const DISPLAY_SUBSCRIPTION_INFO = createRequestTypes("DISPLAY_SUBSCRIPTION_INFO");
export const CLEAR_SUBSCRIPTION_INFO = createRequestTypes("CLEAR_SUBSCRIPTION_INFO");
export const PAYMENT_UPDATE_INFO = createRequestTypes("PAYMENT_UPDATE_INFO");
export const DISPLAY_PAYMENT_INFO = createRequestTypes("DISPLAY_PAYMENT_INFO");
export const BILLING_DETAIL = createRequestTypes("BILLING_DETAIL");
export const UPDATE_CARD = createRequestTypes("UPDATE_CARD");
export const EDIT_CARD = createRequestTypes("EDIT_CARD");
export const ADD_CARD = createRequestTypes("ADD_CARD");
export const TRANSACTION = createRequestTypes("TRANSACTION");
export const GET_PAYMENT_STATUS = createRequestTypes("GET_PAYMENT_STATUS");
export const GET_ALL_TRANSACTION = createRequestTypes("GET_ALL_TRANSACTION");
export const GENERATE_RECIEPT = createRequestTypes("GENERATE_RECIEPT");


export const getAllTransaction = {
    request: request => ({ type: GET_ALL_TRANSACTION.REQUEST, request }),
    success: data => ({ type: GET_ALL_TRANSACTION.SUCCESS, data }),
    error:   error => ({ type: GET_ALL_TRANSACTION.FAILURE, error })
}

export const generateReceipt = {
    request: request => ({ type: GENERATE_RECIEPT.REQUEST, request }),
    success: data => ({ type: GENERATE_RECIEPT.SUCCESS, data }),
    error:   error => ({ type: GENERATE_RECIEPT.FAILURE, error })
}

export const SET_LICENSE_PARAMS = createRequestTypes("SET_LICENSE_PARAMS");

export const setLicenceParams = {
    request: request =>({type : SET_LICENSE_PARAMS.REQUEST, request})
}

export const transaction = {
    request: request => ({ type: TRANSACTION.REQUEST, request }),
    success: data => ({ type: TRANSACTION.SUCCESS, data }),
    error:   error => ({ type: TRANSACTION.FAILURE, error })
};

export const getPaymentStatus = {
    request: request => ({ type: GET_PAYMENT_STATUS.REQUEST, request }),
    success: data => ({ type: GET_PAYMENT_STATUS.SUCCESS, data }),
    error:   error => ({ type: GET_PAYMENT_STATUS.FAILURE, error })
};

export const addCard = {
    request: request => ({ type: ADD_CARD.REQUEST, request }),
    success: data => ({ type: ADD_CARD.SUCCESS, data }),
    error:   error => ({ type: ADD_CARD.FAILURE, error })
};

export const editCard = {
    request: request => ({ type: EDIT_CARD.REQUEST, request }),
    success: data => ({ type: EDIT_CARD.SUCCESS, data }),
    error:   error => ({ type: EDIT_CARD.FAILURE, error })
};

export const updateCard = {
    request: request => ({ type: UPDATE_CARD.REQUEST, request }),
    success: data => ({ type: UPDATE_CARD.SUCCESS, data }),
    error:   error => ({ type: UPDATE_CARD.FAILURE, error })
};

export const billingDetail = {
    request: request => ({ type: BILLING_DETAIL.REQUEST, request }),
    success: data => ({ type: BILLING_DETAIL.SUCCESS, data }),
    error:   error => ({ type: BILLING_DETAIL.FAILURE, error })
};

export const displayPaymentInfo = {
    request: request => ({ type: DISPLAY_PAYMENT_INFO.REQUEST, request }),
    success: data => ({ type: DISPLAY_PAYMENT_INFO.SUCCESS, data }),
    error:   error => ({ type: DISPLAY_PAYMENT_INFO.FAILURE, error })
};

export const paymentUpdateInfo = {
    request: request => ({ type: PAYMENT_UPDATE_INFO.REQUEST, request }),
    success: () => ({ type: PAYMENT_UPDATE_INFO.SUCCESS }),
    error:   error => ({ type: PAYMENT_UPDATE_INFO.FAILURE, error })
};

export const displaySubscriptionInfo = {
    request: request => ({ type: DISPLAY_SUBSCRIPTION_INFO.REQUEST, request }),
    success: data => ({ type: DISPLAY_SUBSCRIPTION_INFO.SUCCESS, data }),
    error:   error => ({ type: DISPLAY_SUBSCRIPTION_INFO.FAILURE, error })
};

export const clearSubscriptionInfo = {
    success: () => ({ type: CLEAR_SUBSCRIPTION_INFO.SUCCESS }),
};


export const saveSettings = {
    request: request => ({ type: SAVE_SETTINGS.REQUEST, request }),
    success: response => ({ type: SAVE_SETTINGS.SUCCESS, response }),
    error:   error => ({ type: SAVE_SETTINGS.FAILURE, error })
};

export const getConfig = {
    request: request => ({ type: GET_SETTINGS.REQUEST, request }),
    success: response => ({ type: GET_SETTINGS.SUCCESS, response }),
    error:   error => ({ type: GET_SETTINGS.FAILURE, error }),
    initDefaultValue:   data => ({ type: GET_SETTINGS.INIT_DEFAULT_VALUE, data })
};

export const getPremiumSettings = {
    request: request => ({ type: GET_PREMIUM_SETTINGS.REQUEST, request }),
    success: response => ({ type: GET_PREMIUM_SETTINGS.SUCCESS, response }),
    error:   error => ({ type: GET_PREMIUM_SETTINGS.FAILURE, error }),
    initDefaultValue:   data => ({ type: GET_PREMIUM_SETTINGS.INIT_DEFAULT_VALUE, data })
};

export const connectGoogleToSite = {
    request: request => ({ type: CONNECT_GOOGLE_TO_SITE.REQUEST, request }),
    success: response => ({ type: CONNECT_GOOGLE_TO_SITE.SUCCESS, response }),
    error:   error => ({ type: CONNECT_GOOGLE_TO_SITE.FAILURE, error })
};

export const disconnectGoogleFromSite = {
    request: request => ({ type: DISCONNECT_GOOGLE_FROM_SITE.REQUEST, request }),
    success: response => ({ type: DISCONNECT_GOOGLE_FROM_SITE.SUCCESS, response }),
    error:   error => ({ type: DISCONNECT_GOOGLE_FROM_SITE.FAILURE, error })
};

export const updateRobots = {
    request: request => ({ type: UPDATE_ROBOTS.REQUEST, request }),
    success: response => ({ type: UPDATE_ROBOTS.SUCCESS, response }),
    error:   error => ({ type: UPDATE_ROBOTS.FAILURE, error }),
};

export const flushPermalinks = {
    request: request => ({ type: FLUSH_PERMALINKS.REQUEST, request }),
    success: response => ({ type: FLUSH_PERMALINKS.SUCCESS, response }),
    error:   error => ({ type: FLUSH_PERMALINKS.FAILURE, error }),
};
